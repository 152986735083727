import { Typography, Button, message, Modal, Checkbox, Select } from "antd";
import { CopyOutlined, MailOutlined, SendOutlined, DownloadOutlined } from "@ant-design/icons";
import { useGetSetState } from "react-use";

import { HAS_SHEETS, FILE_TYPES_DETAILS } from "common/shared";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import downloadSelectedSheetsInTask, {
  prepareSelectedSheetsInTaskForSending,
} from "common/downloadSelectedSheetsInTask";

import InfoItem from "InfoItem/InfoItem";
import Card from "Card/Card";
import Explanation from "Explanation/Explanation";
import ClientContactPicker from "ClientContactPicker/ClientContactPicker";
import SendTaskFilesModal from "Modals/SendTaskFilesModal/SendTaskFilesModal";
import SendPublicUploadEmail from "Modals/SendPublicUploadEmail/SendPublicUploadEmail";

import "./ClientCommunicationCard.scss";

export default function ClientCommunicationCard({
  task,
  project,
  client,
  apiUser,
  users,
  organisationDetails,
  activityItemsByRequest,
  attachmentsPrefix,
}) {
  const [getState, setState] = useGetSetState({
    publicUrlsForSending: undefined,
    sheetIdsForSending: undefined,
    selectedTaskRevisionId: undefined,
    isSendTaskFilesModalVisible: false,
    isSendPublicUploadEmailVisible: false,
  });

  let attachmentsTargetLabel = attachmentsPrefix.substring(`${task.id}/`.length).split("//").join("/");

  if (!attachmentsTargetLabel) {
    attachmentsTargetLabel = `the root folder of this ${getSimpleLabel("task")}.`;
  }

  let fullUserDetails = users.find((x) => x.id === task.assignedTo);

  if (!fullUserDetails) {
    fullUserDetails = apiUser;
  }

  const clientContactId = task ? task.clientContact : project?.clientContact;
  const fullContactDetails = client?.contacts?.find((contact) => contact.id === clientContactId);

  function generatePublicUploadUrl() {
    let prefix = attachmentsPrefix || `${task.id}/`;
    if (!prefix || !prefix.startsWith(task.id)) {
      message.error(
        `Cannot upload to folders outside of this ${getSimpleLabel(
          "task"
        )}. Please select a folder that is within the attachments of this ${getSimpleLabel("task")}.`,
        5
      );
      throw new Error("Cannot upload to folders outside of this task");
    }
    const publicUploadDetails = {
      project: project?.id,
      task: task?.id,
      organisation: task?.organisation || project?.organisation,
      email: fullUserDetails.id,
      firstName: fullUserDetails.firstName,
      lastName: fullUserDetails.lastName,
      projectTitle: project?.title,
      taskTitle: task?.title,
      client: client?.name,
      attachmentsPrefix: prefix,
    };

    let publicUploadDetailsBase64;

    try {
      publicUploadDetailsBase64 = btoa(JSON.stringify(publicUploadDetails));
      let url = `/public-upload?details=${publicUploadDetailsBase64}`;
      return url;
    } catch (e) {
      message.error(
        `Failed to generate URL for direct upload due to a non-standard character in the ${
          task ? getSimpleLabel("task") : getSimpleLabel("project")
        } details`
      );
      throw e;
    }
  }

  async function confirmSendPublicUploadLinkEmail() {
    if (!clientContactId || !fullContactDetails) {
      Modal.error({
        title: `No ${getSimpleLabel("client")} contact selected`,
        content: `In order to send the direct upload link, first you have to select a  ${getSimpleLabel(
          "client"
        )} contact for this ${task ? getSimpleLabel("task") : getSimpleLabel("project")}`,
      });
      return;
    }

    if (!fullContactDetails.email) {
      Modal.error({
        title: `Selected  ${getSimpleLabel("client")} contact has no email address`,
        content: `In order to send the direct upload link, first you need to add an email address to the contact labelled ${fullContactDetails.id}`,
      });
      return;
    }

    if (!fullContactDetails.firstName) {
      Modal.error({
        title: `Selected  ${getSimpleLabel("client")} contact has no first name`,
        content: `In order to send the direct upload link, first you need to add a first name to the contact labelled ${fullContactDetails.id}`,
      });
      return;
    }

    let messageKey = "email-direct-upload-link";

    let publicUploadUrl;
    try {
      publicUploadUrl = generatePublicUploadUrl();
    } catch (e) {
      return;
    }

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm action",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to send the direct upload link via email to <br />
              <b>
                {fullContactDetails?.firstName} {fullContactDetails?.lastName}
              </b>{" "}
              at <b>{fullContactDetails?.email}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    try {
      message.loading({ content: `Sending email...`, key: messageKey, duration: 0 });

      let publicUploadUrlActivityItem = await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: task.id,
            content: publicUploadUrl,
            organisation: task.organisation,
            type: "PUBLIC_UPLOAD_URL_GENERATED",
            author: apiUser.id,
          },
        },
      });
      let shortUrl = `${window.location.origin}/public-upload-url/${publicUploadUrlActivityItem.data.createActivityItem.id}`;
      await callRest({
        route: "/sendPublicUploadEmail",
        method: "POST",
        body: {
          to: fullContactDetails.email,
          senderDetails: {
            firstName: fullUserDetails.firstName,
            lastName: fullUserDetails.lastName,
          },
          receiverDetails: fullContactDetails,
          link: shortUrl,
          taskId: task?.id,
          projectId: project?.id,
          taskTitle: task?.title,
          projectTitle: project?.title,
          organisation: task.organisation || project.organisation,
        },
        includeCredentials: false,
      });
      message.success({ content: `Email sent to ${fullContactDetails.email}`, key: messageKey, duration: 5 });
    } catch (e) {
      message.success({ content: `Failed to send email`, key: messageKey, duration: 5 });
      console.error("error sending email = ", e);
    }
  }

  async function onClientContactSubmit(clientContact) {
    await window.callGraphQLSimple({
      message: `Failed to set  ${getSimpleLabel("client")} contact`,
      queryName: task ? "updateTask" : "updateProject",
      variables: {
        input: {
          id: task?.id || project?.id,
          clientContact: clientContact || null,
        },
      },
    });
  }

  async function onSendClick() {
    displayDownloadOrSendFlow("SEND");
  }

  function onDownloadClick() {
    displayDownloadOrSendFlow("DOWNLOAD");
  }

  function displayDownloadOrSendFlow(flowType) {
    let selectedTaskRevisionId = task.revisions.items.slice(-1)[0].id;

    Modal.confirm({
      title: `Select ${getSimpleLabel("task revision")} to ${flowType === "DOWNLOAD" ? "download" : "send"}`,
      icon: null,
      content: (
        <>
          <Select
            defaultValue={selectedTaskRevisionId}
            style={{ width: "300px" }}
            onChange={(id) => (selectedTaskRevisionId = id)}
          >
            {task.revisions.items.map((taskRevision) => {
              return (
                <Select.Option value={taskRevision.id} key={taskRevision.id}>
                  {taskRevision.name} - {taskRevision.description}
                </Select.Option>
              );
            })}
          </Select>
        </>
      ),
      okText: "Continue",
      onOk: () => {
        let targetTaskRevision = task.revisions.items.find((x) => x.id === selectedTaskRevisionId);
        if (!targetTaskRevision) {
          message.error(`Failed to find ${getSimpleLabel("task revision")}`);
          return;
        }

        if (targetTaskRevision.files?.items?.length === 0) {
          message.error(`There are no files to ${flowType === "DOWNLOAD" ? "download" : "send"}`);
          return;
        }

        if (!targetTaskRevision.reviewAcceptDate) {
          message.error(`This ${getSimpleLabel("task revision")} has not been reviewed yet`);
          return;
        }

        let reportFiles = targetTaskRevision.files.items.filter((x) => x.type === "REPORT");

        let reportFilesWithOldTemplates = reportFiles.filter((reportFile) => {
          let templateDetails = organisationDetails.templates.items.find(
            (template) => template.id === reportFile.templateId
          );
          if (!templateDetails.key) {
            return true;
          }
        });

        if (reportFilesWithOldTemplates.length > 0) {
          message.error(
            `This ${getSimpleLabel(
              "task revision"
            )} has report files which are based on old templates. Old report templates are not compatible with this functionality.`,
            8
          );
          return;
        }

        let sheetOptions = [];
        let defaultSelectedOptions = [];
        targetTaskRevision.files.items.forEach((crtFile) => {
          if (!HAS_SHEETS[crtFile.type]) {
            let firstSheet = crtFile.sheets.items[0];
            sheetOptions.push({
              label: `${FILE_TYPES_DETAILS[crtFile.type].label} - ${crtFile.name}`,
              value: firstSheet.id,
            });
            if (!firstSheet.excludeFromRegister) {
              defaultSelectedOptions.push(firstSheet.id);
            }
          } else {
            crtFile.sheets.items.forEach((crtSheet) => {
              sheetOptions.push({
                label: `${FILE_TYPES_DETAILS[crtFile.type].label} - ${crtFile.name} - ${crtSheet.name}`,
                value: crtSheet.id,
              });
              if (!crtSheet.excludeFromRegister) {
                defaultSelectedOptions.push(crtSheet.id);
              }
            });
          }
        });
        let selectedOptions = defaultSelectedOptions;
        Modal.confirm({
          title: `Which files/sheets do you want to ${flowType === "DOWNLOAD" ? "download" : "send"}?`,
          icon: null,
          content: (
            <>
              <Checkbox.Group
                options={sheetOptions}
                defaultValue={defaultSelectedOptions}
                onChange={(newSelectedOptions) => (selectedOptions = newSelectedOptions)}
              />
            </>
          ),
          okText: flowType === "DOWNLOAD" ? "Download" : "Continue",
          okButtonProps: {
            icon: flowType === "DOWNLOAD" ? <DownloadOutlined /> : null,
          },
          onOk: async () => {
            if (selectedOptions.length === 0) {
              message.error("You have not selected any sheets or files. Please select at least one file or sheet.");
              return;
            }
            if (flowType === "DOWNLOAD") {
              await downloadSelectedSheetsInTask({
                taskId: task.id,
                sheetIds: selectedOptions,
                users,
                organisationDetails,
                taskRevisionId: selectedTaskRevisionId,
              });
            } else {
              const publicUrls = await prepareSelectedSheetsInTaskForSending({
                taskId: task.id,
                sheetIds: selectedOptions,
                users,
                organisationDetails,
                taskRevisionId: selectedTaskRevisionId,
              });

              setState({
                publicUrlsForSending: publicUrls,
                sheetIdsForSending: selectedOptions,
                isSendTaskFilesModalVisible: true,
                selectedTaskRevisionId,
              });
            }
          },
        });
      },
    });
  }

  function displayIssueSection() {
    if (!apiUser.featureFlags?.serverPoweredReportPublish) {
      return null;
    }
    return (
      <InfoItem
        includeColon={false}
        label={
          <>
            {getSimpleLabel("issue-to-client")}{" "}
            <Explanation
              title={
                <>
                  The "Download" button allows you to download a zip file containing multiple files from this{" "}
                  {getSimpleLabel("task")}, from whichever {getSimpleLabel("task revision")} they belong to. <br />
                </>
              }
            />
          </>
        }
        className="send-design-package"
        value={
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button type="primary" data-cy="send-design-package-button" onClick={onSendClick}>
              <SendOutlined />
              Send
            </Button>
            <Button type="primary" data-cy="download-design-package-button" onClick={onDownloadClick}>
              <DownloadOutlined />
              Download
            </Button>
          </div>
        }
      />
    );
  }

  return (
    <Card className="client-communication-card" title="Client communication">
      <div className="card-body">
        <InfoItem
          label={`${getSimpleLabel("Client")} contact`}
          value={
            <ClientContactPicker
              client={client}
              value={task?.clientContact || project?.clientContact}
              onSubmit={onClientContactSubmit}
            />
          }
        />
        <InfoItem
          includeColon={false}
          label={
            <>
              <Typography.Text>Direct upload</Typography.Text>{" "}
              <Explanation
                title={`${getSimpleLabel(
                  "Clients"
                )} can use this link to upload files and folders directly to the attachments of this ${
                  task ? "task" : "project"
                }`}
              />
            </>
          }
          className="public-upload-container"
          value={
            <>
              <Button
                data-cy="copy-share-link-button"
                onClick={async () => {
                  let publicUploadUrl;
                  let shortUrl;

                  try {
                    publicUploadUrl = generatePublicUploadUrl();
                    console.log("publicUploadUrl:", publicUploadUrl);
                    let publicUploadUrlActivityItem = await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to record activity item",
                      variables: {
                        input: {
                          parentId: task.id,
                          content: publicUploadUrl,
                          organisation: task.organisation,
                          type: "PUBLIC_UPLOAD_URL_GENERATED",
                          author: apiUser.id,
                        },
                      },
                    });
                    console.log("publicUploadUrlActivityItem", publicUploadUrlActivityItem.data.createActivityItem);
                    shortUrl = `${window.location.origin}/public-upload-url/${publicUploadUrlActivityItem.data.createActivityItem.id}`;
                    console.log("shortUrl", shortUrl);
                  } catch (e) {
                    console.error("Error ocurred while creating the shortURL:", e);
                    return;
                  }

                  // await navigator.clipboard.writeText(publicUploadUrl);
                  await navigator.clipboard.writeText(shortUrl);
                  message.success({
                    content: (
                      <Typography.Text>
                        Link copied. Uploads will be made to <b>{attachmentsTargetLabel}</b>
                      </Typography.Text>
                    ),
                  });
                }}
              >
                <CopyOutlined />
                Copy link
              </Button>
              <Button onClick={() => confirmSendPublicUploadLinkEmail()}>
                <MailOutlined />
                Email link
              </Button>
            </>
          }
        />
        {displayIssueSection()}
      </div>
      {getState().isSendTaskFilesModalVisible && (
        <SendTaskFilesModal
          apiUser={apiUser}
          taskRevisionId={getState().selectedTaskRevisionId}
          task={task}
          project={project}
          client={client}
          onClose={() => setState({ isSendTaskFilesModalVisible: false })}
          activityItemsByRequest={activityItemsByRequest}
          publicUrlsForSending={getState().publicUrlsForSending}
          sheetIdsForSending={getState().sheetIdsForSending}
          organisationDetails={organisationDetails}
        />
      )}
      {getState().isSendPublicUploadEmailVisible && (
        <SendPublicUploadEmail
          apiUser={apiUser}
          visible={true}
          task={task}
          project={project}
          client={client}
          generatePublicUploadUrl={generatePublicUploadUrl}
          onClose={() => {
            setState({ isSendPublicUploadEmailVisible: false });
          }}
        />
      )}
    </Card>
  );
}
