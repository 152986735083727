import getAllPermissionsForUser from "./getAllPermissionsForUser";
import { getSimpleLabel } from "./labels";

const READ = "View";
const WRITE = "Edit";

export let PERMISSION_GROUPS = [
  {
    label: "Allow",
    children: [
      {
        label: "General",
        children: [
          {
            id: "CREATE_CLIENT",
            label: "Create client",
          },
          {
            id: "CREATE_PROJECT",
            label: "Create project",
          },
          {
            id: "CREATE_TASK",
            label: "Create task",
          },
          {
            id: "CREATE_QUOTE",
            label: "Create quote",
          },
          {
            id: "CREATE_INVOICE",
            label: "Create invoice",
          },
          {
            id: "CREATE_SUPPLIER",
            label: "Create supplier",
          },
          {
            id: "CREATE_PURCHASE_ORDER",
            label: "Create purchase order",
          },
          {
            id: "CREATE_REQUEST",
            label: "Create request",
          },
          {
            id: "MANAGE_TEMPLATES",
            label: "Manage templates",
          },
          {
            id: "SIMPLE_MOBILE_VIEW",
            label: "Use simple mobile view",
          },
          {
            id: "WITHOUT_DENY.EXCLUDE_FROM_FILTERS",
            label: "Exclude from filters",
          },
        ],
      },
      {
        label: "Export",
        children: [
          {
            id: "EXPORT_TASKS",
            label: `Export ${getSimpleLabel("tasks")}`,
          },
          {
            id: "EXPORT_QUOTES",
            label: `Export ${getSimpleLabel("quotes")}`,
          },
          {
            id: "EXPORT_PROJECTS",
            label: `Export ${getSimpleLabel("projects")}`,
          },
          {
            id: "EXPORT_INVOICES",
            label: `Export ${getSimpleLabel("invoices")}`,
          },
          {
            id: "EXPORT_PURCHASE_ORDERS",
            label: `Export ${getSimpleLabel("purchase orders")}`,
          },
        ],
      },
      {
        label: "Access control",
        children: [
          {
            label: READ,
            children: [
              {
                id: "IAM.VIEW",
                label: "View user & group permissions",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "IAM.EDIT_PERMISSIONS",
                label: "Edit user & group permissions",
              },
            ],
          },
        ],
      },
      {
        label: "Teams",
        children: [
          {
            label: WRITE,
            children: [
              {
                id: "TEAMS.EDIT_TEAM_MEMBERSHIP",
                label: "Edit user team membership",
              },
            ],
          },
        ],
      },
      {
        label: "Users",
        children: [
          {
            label: READ,
            children: [
              {
                id: "USERS.VIEW",
                label: "View user list page",
              },
              {
                id: "USERS.SEE_OTHERS_ACCOUNT_PAGE",
                label: "View another user's account page",
              },
              {
                id: "USERS.MAKE_USER_VISIBLE",
                label: "Display user in the user list, filters, on the timeline, etc.",
              },
            ],
          },

          {
            label: WRITE,
            children: [
              {
                id: "USERS.INVITE_USER",
                label: "Invite users",
              },
              {
                id: "USERS.DISABLE_USER",
                label: "Disable users",
              },
              {
                id: "USERS.EDIT_OTHER_USERS_DETAILS",
                label: "Edit details on another user's account page",
              },
              {
                id: "USERS.EDIT_OWN_ENGINEERING_AUTHORITY_LEVELS",
                label: "Change one's own engineering authority levels",
              },
              {
                id: "USERS.EDIT_OTHERS_ENGINEERING_AUTHORITY_LEVELS",
                label: "Change another user's engineering authority levels",
              },
              {
                id: "USERS.EDIT_OWN_FINANCIAL_AUTHORITY_LEVELS",
                label: "Change one's own user's financial authority levels",
              },
              {
                id: "USERS.EDIT_OTHERS_FINANCIAL_AUTHORITY_LEVELS",
                label: "Change another user's financial authority levels",
              },
              {
                id: "USERS.ALLOWANCES.EDIT",
                label: "Edit allowances",
              },
            ],
          },
        ],
      },
      {
        label: "Timeline",
        children: [
          {
            label: READ,
            children: [
              {
                id: "TIMELINE.VIEW",
                label: "View timeline page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "TIMELINE.EDIT",
                label: "Change timeline blocks",
              },
            ],
          },
        ],
      },
      {
        label: "Clients",
        children: [
          {
            label: READ,
            children: [
              {
                id: "CLIENTS.VIEW",
                label: "View clients page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "CLIENTS.DOWNLOAD_CONTACTS",
                label: "Batch download client contacts",
              },
              {
                id: "CLIENT.DELETE",
                label: "Delete clients",
              },
            ],
          },
        ],
      },

      {
        label: "Projects",
        children: [
          {
            label: READ,
            children: [
              {
                id: "PROJECTS.VIEW",
                label: "View projects page",
              },
            ],
          },
        ],
      },
      {
        label: "Suppliers",
        children: [
          {
            label: READ,
            children: [
              {
                id: "SUPPLIERS.VIEW",
                label: "View suppliers page",
              },
            ],
          },
        ],
      },
      {
        label: "Supplier details",
        children: [
          {
            label: READ,
            children: [
              {
                id: "SUPPLIER_DETAILS.VIEW",
                label: "View supplier details page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "SUPPLIER.DELETE",
                label: "Delete supplier",
              },
            ],
          },
        ],
      },
      {
        label: "Purchase orders",
        children: [
          {
            label: READ,
            children: [
              {
                id: "PURCHASE_ORDERS.VIEW",
                label: "View purchase orders page",
              },
              {
                id: "PURCHASE_ORDERS.VIEW_COLUMN_TOTALS",
                label: "View totals at the top of each column on the purchase orders page",
              },
            ],
          },
        ],
      },
      {
        label: "Purchase order details",
        children: [
          {
            label: READ,
            children: [
              {
                id: "PURCHASE_ORDER_DETAILS.VIEW",
                label: "View purchase order details page",
              },
            ],
          },
        ],
      },
      {
        label: "Sprints",
        children: [
          {
            label: READ,
            children: [
              {
                id: "SPRINTS.VIEW",
                label: "View sprints page",
              },
            ],
          },
        ],
      },
      {
        label: "Document library",
        children: [
          {
            label: READ,
            children: [
              {
                id: "DOCUMENT_LIBRARY.VIEW",
                label: "View document library page",
              },
            ],
          },
        ],
      },
      {
        label: "Queue",
        children: [
          {
            label: READ,
            children: [
              {
                id: "QUEUE.VIEW",
                label: "View queue page",
              },
            ],
          },
        ],
      },
      {
        label: "Quotes",
        children: [
          {
            label: READ,
            children: [
              {
                id: "QUOTES.VIEW",
                label: "View quotes page",
              },
              {
                id: "QUOTE_DETAILS.VIEW",
                label: "View one's own quotes",
              },
              {
                id: "QUOTES.VIEW_OTHERS_QUOTES",
                label: "View quotes assigned to others",
              },
              {
                id: "QUOTES.VIEW_COLUMN_TOTALS",
                label: "View totals at the top of each column on the quotes page",
              },
              {
                id: "QUOTES.WRITE_MANUALLY_INVOICED",
                label: "Mark quotes invoiced outside of DraughtHub",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "QUOTES.WRITE_AMOUNT",
                label: "Manually edit the amounts on quote line items",
              },
            ],
          },
        ],
      },
      {
        label: "Stock",
        children: [
          {
            label: READ,
            children: [
              {
                id: "STOCK.VIEW_STOCK_PAGE",
                label: `View ${getSimpleLabel("stock")} page`,
              },
              {
                id: "STOCK.VIEW_STOCK_ITEM_DETAILS_PAGE",
                label: `View ${getSimpleLabel("stock item")} details page`,
              },
            ],
          },
        ],
      },
      {
        label: "Invoices",
        children: [
          {
            label: READ,
            children: [
              {
                id: "INVOICES.VIEW",
                label: "View invoices page",
              },
              {
                id: "INVOICES.VIEW_BATCH_CREATE_PAGE",
                label: "View batch create invoices page",
              },
              {
                id: "INVOICES.BATCH_DOWNLOAD_INVOICES",
                label: "Batch download invoices",
              },
              {
                id: "INVOICES.VIEW_COLUMN_TOTALS",
                label: "View totals at the top of each column on the invoices page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "INVOICES.PERFORM_SECOND_REVIEW",
                label: "Perform second invoice review",
              },
            ],
          },
        ],
      },

      {
        label: "Invoice details",
        children: [
          {
            label: READ,
            children: [
              {
                id: "INVOICE_DETAILS.VIEW",
                label: "View invoice details page",
              },
              {
                id: "INVOICE_DETAILS.VIEW_ACCOUNTANCY_PAGE",
                label: "View third-party accountancy page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "INVOICE_DETAILS.SEND_TO_ACCOUNTANCY",
                label: "Send invoice to accountancy software",
              },
            ],
          },
        ],
      },
      {
        label: getSimpleLabel("Tasks"),
        children: [
          {
            label: READ,
            children: [
              {
                id: "DASHBOARD.VIEW",
                label: `View ${getSimpleLabel("tasks")} page`,
              },
              {
                id: "TASK_DETAILS.VIEW",
                label: `View ${getSimpleLabel("task")} details page`,
              },
              {
                id: "TASK.VIEW_FINANCIALS",
                label: `View financials tab on ${getSimpleLabel("task")} details page`,
              },
              {
                id: "TASK.VIEW_BUDGET_BAR",
                label: `View budget bar on ${getSimpleLabel("tasks")}`,
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "TASK_DETAILS.EDIT_DUE_DATE",
                label: "Edit due date, start date, and end date on tasks & task revisions",
              },
              {
                id: "TASK_DETAILS.EDIT_REQUESTED_DATE",
                label: "Edit task & task revision requested date",
              },
              {
                id: "TASK_DETAILS.EDIT_PRIORITY",
                label: "Edit task & task revision priority",
              },
              {
                id: "TASK_DETAILS.UPDATE_TASK_REVISION_FINISHED_AT",
                label: 'Edit task revision "finished on" date',
              },
              {
                id: "TASK_DETAILS.ADD_TASK_REVISION",
                label: "Add task revision",
              },
              {
                id: "TASK_DETAILS.DELETE_TASK_REVISION",
                label: "Delete task revision",
              },
              {
                id: "TASK_DETAILS.EDIT_READ_ONLY_TASK_REVISION_DETAILS",
                label: "Edit the details of read-only task revisions",
              },
              {
                id: "TASK.ARCHIVE",
                label: "Archive task",
              },
              {
                id: "TASK.DELETE",
                label: "Delete task",
              },
            ],
          },
        ],
      },
      {
        label: `${getSimpleLabel("Client")} details`,
        children: [
          {
            label: READ,
            children: [
              {
                id: "CLIENT_DETAILS.VIEW",
                label: "View client details page",
              },
            ],
          },
        ],
      },
      {
        label: "Public upload",
        children: [
          {
            label: READ,
            children: [
              {
                id: "PUBLIC_UPLOAD.VIEW",
                label: "View public upload page",
              },
            ],
          },
        ],
      },
      {
        label: `${getSimpleLabel("Project")} details`,
        children: [
          {
            label: READ,
            children: [
              {
                id: "PROJECT_DETAILS.VIEW",
                label: "View project details page",
              },
              {
                id: "PROJECT.VIEW_FINANCIALS",
                label: "View financials tab on project details page",
              },
              {
                id: "PROJECT.VIEW_BUDGET_BAR",
                label: "View budget bar on projects",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "PROJECT.CHANGE_TEAM",
                label: "Change the team of a project",
              },
              {
                id: "PROJECT.CHANGE_CLIENT",
                label: "Change client associated with a project",
              },
              {
                id: "PROJECT.FINISH",
                label: "Finish project",
              },
              {
                id: "PROJECT.ARCHIVE",
                label: "Archive project",
              },
              {
                id: "PROJECT.DELETE",
                label: "Delete project",
              },
            ],
          },
        ],
      },
      {
        label: "Review",
        children: [
          {
            label: READ,
            children: [
              {
                id: "REVIEW.VIEW",
                label: "View review page",
              },
            ],
          },
        ],
      },
      {
        label: "Report",
        children: [
          {
            label: READ,
            children: [
              {
                id: "REPORT.VIEW",
                label: "View report page",
              },
            ],
          },
        ],
      },
      {
        label: "File details",
        children: [
          {
            label: READ,
            children: [
              {
                id: "FILE_DETAILS.VIEW",
                label: "View file details page",
              },
              {
                id: "MANAGE_STARTING_FILES",
                label: "Manage starting files",
              },
              {
                id: "ADD_FORM_ITEMS_TO_PRESETS",
                label: "Add items to presets",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "FILES.EDIT_OLD_SHEET_REVISION",
                label: "Edit old sheet revisions",
              },
              {
                id: "FILES.DELETE_SHEET_REVISION",
                label: "Delete sheet revisions",
              },
              {
                id: "FILES.EDIT_READ_ONLY_SHEET_REVISION_DETAILS",
                label: "Edit the details of read-only sheet revisions",
              },
            ],
          },
        ],
      },
      {
        label: "Account",
        children: [
          {
            label: READ,
            children: [
              {
                id: "ACCOUNT.VIEW",
                label: "View account page",
              },
            ],
          },
        ],
      },
      {
        label: "Template editor",
        children: [
          {
            label: READ,
            children: [
              {
                id: "ANNOTATION_EDITOR.VIEW",
                label: "View annotation editor page",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "EDIT_LIVE_TEMPLATES",
                label: "Edit live templates",
              },
            ],
          },
        ],
      },
      {
        label: "User timesheet",
        children: [
          {
            label: READ,
            children: [
              {
                id: "USER_TIMESHEET.VIEW",
                label: "View one's own timesheet page",
              },
              {
                id: "USER_TIMESHEET.VIEW_OTHERS_TIMESHEETS",
                label: "View other users' timesheet page",
              },
              {
                id: "USER_TIMESHEET.VIEW_OWN_TIMESHEET_OVERVIEW",
                label: "View one's own timesheet overview",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "USER_TIMESHEET.EDIT",
                label: "Edit another user's timesheet",
              },
              {
                id: "USER_TIMESHEET.REVIEW",
                label: "Review and approve timesheets",
              },
              {
                id: "USER_TIMESHEET.USE_CLOCK_IN_OUT",
                label: "Use clock in/out functionality",
              },
            ],
          },
        ],
      },
      {
        label: "Timesheets dashboard",
        children: [
          {
            label: READ,
            children: [
              {
                id: "TIMESHEETS.VIEW",
                label: "View timesheets page",
              },
            ],
          },
        ],
      },
      {
        label: "Time off - holidays and sick days",
        children: [
          {
            label: READ,
            children: [
              {
                id: "TIME_OFF.VIEW",
                label: "View time off dashboard page",
              },
            ],
          },

          {
            label: WRITE,
            children: [
              {
                id: "TIME_OFF.MANAGE",
                label: "Manage time off",
              },
              {
                id: "TIME_OFF.EDIT",
                label: "Set time off allowances for any user",
              },
              {
                id: "TIME_OFF.REQUEST_OWN_HOLIDAYS",
                label: "Request holidays for oneself",
              },
              {
                id: "TIME_OFF.REQUEST_OWN_SICK_DAYS",
                label: "Request sick days for oneself",
              },
            ],
          },
        ],
      },
      {
        label: "Organisation",
        children: [
          {
            label: READ,
            children: [
              {
                id: "ORGANISATION_SETTINGS.VIEW",
                label: "View organisation page",
              },
              {
                id: "ORGANISATION_DOCUMENTS.VIEW",
                label: "View organisation reports page",
              },
            ],
          },
        ],
      },
      {
        label: "Analytics",
        children: [
          {
            label: READ,
            children: [
              {
                id: "ANALYTICS.VIEW",
                label: "View analytics page",
              },
            ],
          },
        ],
      },
      {
        label: "Requests",
        children: [
          {
            label: READ,
            children: [
              {
                id: "REQUESTS.VIEW",
                label: "View requests page",
              },
              {
                id: "REQUESTS.VIEW_OTHERS_REQUESTS",
                label: "View requests placed by others",
              },
            ],
          },
          {
            label: WRITE,
            children: [
              {
                id: "REQUESTS.EXECUTE",
                label: "Execute requests - ask for more information, create tasks/quotes from them, etc.",
              },
              {
                id: "REQUESTS.DELETE",
                label: "Delete requests",
              },
            ],
          },
        ],
      },
    ],
  },
];

PERMISSION_GROUPS[1] = JSON.parse(JSON.stringify(PERMISSION_GROUPS[0]));
PERMISSION_GROUPS[1].label = "Deny";
PERMISSION_GROUPS[0].children.unshift({
  id: "FULL.READ_WRITE",
  label: "Full access",
  children: [],
});
convertToDeny(PERMISSION_GROUPS[1]);
applyIdsToPermissions({ children: PERMISSION_GROUPS });

function applyIdsToPermissions(parent) {
  if (!parent.id) {
    parent.id = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
  }

  if (parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      applyIdsToPermissions(parent.children[i]);
    }
  }
}

function convertToDeny(parent) {
  if (parent.id) {
    parent.id = `DENY.${parent.id}`;
  }
  if (!parent.children || parent.children.length === 0) {
    parent.label = `Deny - ${parent.label}`;
  }

  if (parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      let child = parent.children[i];

      if (child.id?.startsWith("WITHOUT_DENY.")) {
        // remove element
        parent.children.splice(i, 1);
        i--;
        continue;
      }
      convertToDeny(child);
    }
  }
}

export function findFullPermissionDetails(id, items) {
  let found;

  for (let i = 0; i < items?.length; i++) {
    if (items[i]?.id === id) {
      return items[i];
    } else if (Array.isArray(items[i].children)) {
      found = findFullPermissionDetails(id, items[i]?.children);
      if (found) {
        return found;
      }
    }
  }
}

export async function fetchGroups({ organisation }) {
  try {
    let params = {
      organisation,
    };
    let items = [];

    while (true) {
      let response;
      if (global.isBrowser) {
        response = await window.callGraphQLSimple({
          message: "Failed to list the groups",
          query: "listGroupsByOrganisation",
          variables: params,
        });
      } else {
        response = await global.callGraphQLSimple({
          message: "Failed to list the groups",
          queryName: "listGroupsByOrganisation",
          variables: params,
        });
      }

      const pageOfItems = response.data.listGroupsByOrganisation.items;
      params.nextToken = response.data.listGroupsByOrganisation.nextToken;
      items = [...items, ...pageOfItems];

      if (!params.nextToken) {
        break;
      }
    }

    return items;
  } catch (err) {
    console.log("err = ", err);
  }
}

export function isAuthorised(necessaryPermissionsIdList, user, ignoreFullAccess = false) {
  if (!Array.isArray(necessaryPermissionsIdList)) {
    throw new Error(
      `Error from isAuthorised() - You need to pass an array of necessary permissions to check. Instead, you passed a ${typeof necessaryPermissionsIdList}`
    );
  }

  necessaryPermissionsIdList = necessaryPermissionsIdList || [];

  const denyPermissionsIdList = necessaryPermissionsIdList.map((permissionId) => `DENY.${permissionId}`);

  if (!user) {
    user = window.apiUser;
  }

  if (!user && !ignoreFullAccess) {
    return true;
  }
  const currentUserPermissions = getAllPermissionsForUser(user);

  const isDenied = denyPermissionsIdList.every((permissionId) => {
    return currentUserPermissions.includes(permissionId);
  });

  if (isDenied) {
    return false;
  }

  if (!ignoreFullAccess) {
    if (currentUserPermissions.includes("FULL.READ_WRITE") || user?.isHidden) {
      return true;
    }
  }

  if (!currentUserPermissions) {
    return false;
  }

  const isAuthorised = necessaryPermissionsIdList.some((targetPermission) => {
    return currentUserPermissions.includes(targetPermission);
  });

  return isAuthorised;
}

export function getGroupNamesForUser(userId, groups) {
  groups = groups || window.groups;

  return groups.filter((group) => group.members?.includes(userId)).map((group) => group.name);
}
