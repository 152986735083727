import React from "react";
import moment from "moment";
import { getReportTaskRevisions } from "ReportPage/ReportTaskRevisions/ReportTaskRevisions";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import axios from "axios";
import { Page, Text, View, Image, Document } from "@react-pdf/renderer";
import awsExports from "aws-exports";

import { getFile } from "graphql/queries_custom";
import { fetchAndSetLinkedTasks, getCat2Check, fileHasAtLeastOneExport } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import {
  getAttachmentFullKeyFromLocalKey,
  setLambdaPdfInsert,
  displayPdfTextarea,
  displayPdfAttachment,
} from "common/documentRenderHelpers";
import getS3File from "common/getS3File";

import {
  getLatestFileVersion,
  getFilenameFromKey,
  getLatestRevision,
  buildFileName,
  getAttachmentTypeFromKey,
} from "common/shared";
import { getImagesFromReport, getSelectedFileFromTask } from "ReportPage/Report/reportHelpers";

import ReportPreview from "ReportPage/ReportPreview";

import DRAMatrix from "./dra-matrix.png";

import CoverBackground from "./cover-background.jpg";
import RearCoverImage from "./rear-cover-image.png";
import {
  PageFooter,
  FrontCover,
  RearCover,
  getReportReference,
  getReportFrontCoverInfoItems,
  colors,
  styles,
} from "./AWD_CommonReportComponents";

import { Table } from "ReportPage/PdfTable";

import "./AWD_Report.scss";

const DRA_ITEMS_PER_PAGE = 5;

const REVISION_HISTORY_COLUMNS = [
  {
    title: "REV.",
    dataIndex: "name",
    key: "name",
    width: "40",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
    width: "195.5",
  },
  {
    title: "AUTHOR",
    dataIndex: "author",
    key: "author",
    width: "60",
  },
  {
    title: "DATE",
    dataIndex: "reviewAcceptDate",
    key: "date",
    width: "60",
  },
  {
    title: "CHECKED",
    dataIndex: "checkedBy",
    key: "checkedBy",
    width: "60",
  },
  {
    title: "APPROVED",
    dataIndex: "approved",
    key: "approved",
    width: "70",
  },
];

export class AWD_Report extends React.Component {
  state = {
    calculationsFile: null,
    isLoading: true,
    designersRiskAssessmentImageUrl: null,
    attachmentImages: null,
    defaultRiskAssessmentReference: null,
  };

  async componentDidMount() {
    await fetchAndSetLinkedTasks.call(this);
    const { taskRevision, task, file } = this.props;
    const { linkedTasksDetails } = this.state;
    let newState = {
      defaultRiskAssessmentReference: (
        await buildFileName({
          organisation: task.organisation,
          taskId: task.id,
          fileType: "DESIGNERS RISK ASSESSMENT",
          taskRevisionName: taskRevision.name,
          clientInitials: task.client.initials,
          projectInitials: task.project.initials,
          taskInitials: task.initials,
          fileId: file.id,
        })
      ).file,
    };
    const designersRiskAssessmentImageUrl = await getS3File("report_images/andun_risk_assessment.png");
    const reportFile = this.props.file;
    const cat2CheckTask = getCat2Check({ task, linkedTasksDetails });
    if (cat2CheckTask) {
      const cat2LatestRevision = getLatestRevision(cat2CheckTask);
      const cat2ReportFile = cat2LatestRevision.files.items.find((x) => x.type === "REPORT");
      if (cat2ReportFile) {
        newState.cat2ReportJsonData = await this.loadForm({
          file: cat2ReportFile,
        });
      }
    }
    // if we don't receive the preview data, we load it ourselves
    // this is useful on pages that don't already have that data to pass it down to us
    if (!this.props.previewData) {
      newState.reportJsonData = await this.loadForm({
        file: reportFile,
      });
    } else {
      newState.reportJsonData = this.props.previewData;
    }
    try {
      newState.attachmentImages = await this.retrieveImages(newState.reportJsonData);
      this.setState({
        ...newState,
        calculationsFile: await this.loadCalculationsFile(newState.reportJsonData),
        designersRiskAssessmentImageUrl,
        isLoading: false,
      });
    } catch (e) {
      // nothing, we already show an error message in the function which actually failed
    }
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      try {
        const attachmentImages = await this.retrieveImages(this.props.previewData);
        this.setState({
          reportJsonData: this.props.previewData,
          attachmentImages,
          isLoading: false,
        });
      } catch (e) {
        // nothing, we already show an error message in the function which actually failed
      }
    }
  }

  loadForm = async ({ file }) => {
    const fileKey = getLatestFileVersion(file).key.replace("public/", "");
    const filePublicURL = await getS3File(fileKey);
    const form = (await axios.get(filePublicURL)).data;
    return form;
  };

  loadCalculationsFile = async (reportJsonData) => {
    const { taskRevision } = this.props;

    const calculationsFileField = reportJsonData?.fields.calculationsFile;
    let selectedFile = getSelectedFileFromTask({
      fieldData: calculationsFileField,
      taskRevision,
    });
    const calculationsFileFromTaskRevision = taskRevision.files.items.find((x) => x.constantId === selectedFile);
    if (!calculationsFileFromTaskRevision) {
      // not all tasks include a calculations file
      return;
    }
    const calculationsFile = (
      await callGraphQLSimple({
        message: "Failed to retrieve calculations file details",
        queryName: "getFile",
        variables: {
          id: calculationsFileFromTaskRevision.id,
        },
      })
    ).data.getFile;
    if (this.props.setReportReferenceCallback) {
      this.props.setReportReferenceCallback((params) => {
        const reportReference = getReportReference(params);
        const { projects } = this.props;
        const { task } = params;
        const project = projects.find((x) => x.id === task.projectId);
        const completeReference = `${reportReference.split(".pdf").join("")} ${task.client.name} ${project.title} ${
          task.title
        }`;
        return completeReference;
      });
    }
    if (this.props.onCalculationsFileLoad) {
      this.props.onCalculationsFileLoad(calculationsFile);
    }
    return calculationsFile;
  };

  retrieveImages = async (reportJsonData) => {
    let imagesInReport = getImagesFromReport(reportJsonData);
    const attachmentImages = this.state.attachmentImages || {};
    let imageKeys = imagesInReport
      .map((image) => image.localKey)
      // we only want to fetch the images we don't already have
      .filter((localKey) => !attachmentImages || !attachmentImages.hasOwnProperty(localKey));

    if (!imageKeys || imageKeys.length === 0) {
      return attachmentImages;
    }

    try {
      const imagePromises = [];
      for (let i = 0; i < imageKeys.length; i++) {
        const localKey = imageKeys[i];
        const fullKey = getAttachmentFullKeyFromLocalKey({
          projectFolder: this.props.projectFolder,
          localKey,
        });
        imagePromises.push(
          getS3File(fullKey).catch(() => {
            throw new Error(fullKey);
          })
        );
      }
      let images = await Promise.all(imagePromises);
      imageKeys.forEach((key, i) => {
        attachmentImages[key] = images[i];
      });
    } catch (e) {
      console.log("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to retrieve image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      throw e;
    }

    return attachmentImages;
  };

  displayRevisionHistory = ({ task, users, calculationsFile }) => {
    let sheetForHistory;
    if (calculationsFile) {
      sheetForHistory = JSON.parse(JSON.stringify(calculationsFile.sheets.items[0]));
    }

    const revisions = getReportTaskRevisions({
      task,
      users,
      columns: REVISION_HISTORY_COLUMNS,
      useInitials: true,
      sheet: sheetForHistory,
    });

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: REVISION_HISTORY_COLUMNS[col].width || `${95 / revisions.columns.length}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.gray : colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
        textAlign: "center",
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.accentColorDark : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return (
      <Table
        columns={revisions.columns}
        data={revisions.data.map((row) => ({ ...row, approved: "MR" }))}
        style_function={cell_style}
      />
    );
  };

  displayDesignCertificate = () => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);

    let documentsProduced = [];

    function getSheetDrawingName(file, sheet) {
      const latestSheetRevision = sheet.revisions.items.slice(-1)[0];
      const latestFileVersion = file.versions.items.slice(-1)[0];
      const customId = (latestFileVersion.customId || "").trim();

      if (customId && customId.length > 0) {
        return customId;
      } else {
        return `${task.id.split("-").slice(1).join("-")}-${sheet.description}${latestSheetRevision.name}`;
      }
    }

    for (let i = 0; i < taskRevision.files.items.length; i++) {
      const file = taskRevision.files.items[i];
      if (file.isArchived || file.type === "REPORT") {
        continue;
      }

      file.sheets.items.forEach((sheet) => {
        if (sheet.excludeFromRegister) {
          return;
        }
        documentsProduced.push(getSheetDrawingName(file, sheet));
      });
    }

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "20%" : "80%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const SIGNATURE_HEIGHT = 30;

    let signature = "";

    if (assignedTo) {
      if (assignedTo.signature) {
        signature = (
          <Image
            src={assignedTo.signature}
            style={{
              height: SIGNATURE_HEIGHT,
              width: SIGNATURE_HEIGHT * (assignedTo.signatureWidth / assignedTo.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", fontSize: 20 }}>
            {assignedTo.firstName} {assignedTo.lastName}
          </Text>
        );
      }
    }

    return (
      <View>
        <Text style={[styles.pageTitle, { marginBottom: 10 }]}>Temporary Works Design Certificate</Text>
        <Text style={[styles.paragraph, { marginBottom: 5 }]}>
          <Text style={styles.bold}>Project:</Text> {task.project.title}
        </Text>
        <Text style={[styles.paragraph, { marginBottom: 5 }]}>
          <Text style={styles.bold}>Client:</Text> {task.client.name}
        </Text>
        <Text style={[styles.paragraph, { marginBottom: 5 }]}>
          <Text style={styles.bold}>Design Brief Issued:</Text> {reportJsonData.fields.designBriefIssued.value}
        </Text>
        <Text style={[styles.paragraph, { marginBottom: 5 }]}>
          <Text style={styles.bold}>Design Brief Reference:</Text> {reportJsonData.fields.designBriefReference.value}
        </Text>
        <Text
          style={[
            styles.paragraph,
            {
              marginBottom: reportJsonData.fields.designComplies.value === "Yes" ? 10 : 5,
            },
          ]}
        >
          <Text style={styles.bold}>Does the design comply with the brief:</Text>{" "}
          {reportJsonData.fields.designComplies.value}
        </Text>
        {reportJsonData.fields.designComplies.value === "Yes" ? null : (
          <>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>How does it differ from the design brief?</Text>
            </Text>
            <Text style={[styles.paragraph]}>{reportJsonData.fields.designNoCompliesReason.value}</Text>
          </>
        )}

        <Table
          style={{ marginTop: 0 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : null,
            },
            { name: "Title", value: assignedTo?.position },
            {
              name: "Signature",
              value: signature,
            },
            {
              name: "",
              value:
                "To be signed by the Temporary Works Designer or other person authorised to sign on behalf of the organisation responsible for the Design of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />

        <Text style={[styles.paragraph, styles.bold, { marginTop: 20, marginBottom: 0 }]} minPresenceAhead={30}>
          Documents Produced
        </Text>

        {documentsProduced.map((documentName, i) => (
          <Text style={[styles.paragraph, { marginBottom: 0 }]} key={i}>
            {documentName}
          </Text>
        ))}

        <Text style={[styles.paragraph, styles.bold, { marginTop: 15, marginBottom: 0 }]} minPresenceAhead={30}>
          Notes:
        </Text>
        <Text style={[styles.paragraph]}>{reportJsonData.fields.designCertificateNotes.value}</Text>
        <PageFooter pageColor="white" colors={colors} styles={styles} />
      </View>
    );
  };

  displayStandards = () => {
    const { reportJsonData } = this.state;

    const designStandardsFieldNames = Array(16)
      .fill(null)
      .map((_, i) => `designStandards${i}`);
    const designStandardsFields = designStandardsFieldNames
      .map((fieldName) => reportJsonData.fields[fieldName])
      .filter((fieldValue) => fieldValue && Array.isArray(fieldValue.value) && fieldValue.value.length > 0);

    return (
      <View>
        <Text style={[styles.sectionSubtitle]}>Design standards used</Text>
        {designStandardsFields.length === 0 ? (
          <Text key={`paragraph-${Math.random()}`} style={[styles.paragraph, styles.lastParagraph]} />
        ) : (
          designStandardsFields.map((fieldValue, fieldIndex) => {
            return fieldValue.value.map((item, i) => (
              <Text
                style={[
                  styles.paragraph,
                  i === fieldValue.value.length - 1 && fieldIndex === designStandardsFields.length - 1
                    ? styles.lastParagraph
                    : {},
                ]}
                key={i}
              >
                {item}
              </Text>
            ));
          })
        )}
      </View>
    );
  };

  displayCheckCertificate = () => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    const { cat2ReportJsonData } = this.state;
    let cat2CheckLatestRevision;
    if (cat2CheckTask) {
      cat2CheckLatestRevision = getLatestRevision(cat2CheckTask);
      if (!cat2ReportJsonData) {
        return null;
      }
    }

    let reviewerId;
    if (cat2CheckTask) {
      reviewerId = cat2CheckTask.assignedTo;
    } else {
      reviewerId = taskRevision.checkedBy;
    }

    let reviewer = users.find((x) => x.id === reviewerId);

    let reviewedAt = "";

    if (cat2CheckTask) {
      reviewedAt = moment(cat2CheckLatestRevision.reviewAcceptDate || undefined).format("DD/MM/YY");
    } else {
      if (taskRevision.reviewAcceptDate) {
        reviewedAt = moment(taskRevision.reviewAcceptDate || undefined).format("DD/MM/YY");
      }
    }

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "20%" : "80%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const REVIEWER_SIGNATURE_HEIGHT = 30;
    let signature = null;

    if (reviewedAt && reviewer) {
      if (reviewer.signature) {
        signature = (
          <Image
            src={reviewer.signature}
            style={{
              height: REVIEWER_SIGNATURE_HEIGHT,
              width: REVIEWER_SIGNATURE_HEIGHT * (reviewer.signatureWidth / reviewer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", fontSize: 20 }}>
            {reviewer.firstName} {reviewer.lastName}
          </Text>
        );
      }
    }
    return (
      <>
        <Text style={[styles.pageTitle]}>
          CAT.{" "}
          {reportJsonData.fields.proposedCatCheck.value === "same"
            ? task.catLevel
            : reportJsonData.fields.proposedCatCheck.value}{" "}
          Design Check
        </Text>
        <Text style={[styles.paragraph, styles.bold]}>Job. No: {task.id.split("-")[1]}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Job. Extension No: {task.id.split("-")[2]}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Client: {task.client.name}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Job title: {task.project.title}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Structure: {task.title}</Text>
        <Text style={[styles.paragraph, { marginTop: 20 }]}>
          I certify that reasonable professional skill and care has been used in the checking of the design for the{" "}
          {task.title} listed in the attached schedule.
        </Text>
        <Text style={[styles.paragraph]}>
          I certify, but without undertaking any responsibility other than towards {task.client.name} that in my opinion
          the Contractor’s erection proposals and proposed Temporary works details specified in the schedule attached
          hereto relating to the {task.project.title} {task.title} are satisfactory for the proper discharge of his
          responsibilities under the Contract for the safety of the said part of the works and for their safe execution
          in accordance with the Drawings and Specification.
        </Text>

        <Table
          style={{ marginTop: 20 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: reviewer ? `${reviewer.firstName} ${reviewer.lastName}` : "",
            },
            { name: "Title", value: reviewer?.position },
            {
              name: "Signature",
              value: signature,
            },
            {
              name: "",
              value:
                "To be signed by the Temporary Works checker or other person authorised to sign on behalf of the organisation responsible for the Checking of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />
        <PageFooter pageColor="white" colors={colors} styles={styles} />
      </>
    );
  };

  displayCheckCertificateSchedule = () => {
    const { task, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const COL_WIDTHS = {
        0: 140,
        1: 40,
        2: 50,
        3: 234,
      };

      const baseStyle = {
        width: `${COL_WIDTHS[col]}`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "1pt 4pt",
        fontSize: 8,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const documentsData = reportJsonData.fields.checkCertificateDocuments.value.map((documentKey) => {
      return {
        documentNumber: documentKey.split("/").slice(-1)[0],
        revision:
          reportJsonData.fields.checkCertificateDocuments.revisions &&
          reportJsonData.fields.checkCertificateDocuments.revisions[documentKey],
        date:
          reportJsonData.fields.checkCertificateDocuments.dates &&
          reportJsonData.fields.checkCertificateDocuments.dates[documentKey] &&
          moment(reportJsonData.fields.checkCertificateDocuments.dates[documentKey]).format("DD/MM/YYYY"),
        description:
          reportJsonData.fields.checkCertificateDocuments.descriptions &&
          reportJsonData.fields.checkCertificateDocuments.descriptions[documentKey],
      };
    });

    return (
      <>
        <Text style={[styles.pageTitle]}>
          CAT.{" "}
          {reportJsonData.fields.proposedCatCheck.value === "same"
            ? task.catLevel
            : reportJsonData.fields.proposedCatCheck.value}{" "}
          Design Check - Schedule
        </Text>
        <Text style={[styles.paragraph, styles.bold]}>Job. No: {task.id.split("-")[1]}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Job. Extension No: {task.id.split("-")[2]}</Text>
        <Text style={[styles.paragraph, styles.bold]}>
          Date: {moment(taskRevision.reviewAcceptDate || undefined).format("DD MMMM YYYY")}
        </Text>
        <Text style={[styles.paragraph, styles.bold]}>Client: {task.client.name}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Job title: {task.project.title}</Text>
        <Text style={[styles.paragraph, styles.bold]}>Structure: {task.title}</Text>

        <Table
          style={{ marginTop: 5 }}
          includeHeader={true}
          columns={[
            {
              title: "Doc No",
              dataIndex: "documentNumber",
              key: "documentNumber",
              width: 120,
            },
            {
              title: "Rev.",
              dataIndex: "revision",
              key: "revision",
              width: 30,
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              width: 50,
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
              width: 200,
            },
          ]}
          data={documentsData}
          style_function={cell_style}
        />
        {reportJsonData.fields.checkCertificateNotes?.value?.length > 0 && (
          <>
            <Text style={[styles.paragraph, styles.bold, { marginTop: 10 }]} minPresenceAhead={30}>
              Notes:
            </Text>
            <Text style={[styles.paragraph]}>{reportJsonData.fields.checkCertificateNotes.value}</Text>
          </>
        )}
        <PageFooter pageColor="white" colors={colors} styles={styles} />
      </>
    );
  };

  displayCalculations = () => {
    const { calculationsFile } = this.state;

    if (!calculationsFile) {
      return null;
    }
    const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
    const firstExport = latestCalculationsFileVersion.exports[0];

    return displayPdfAttachment({
      key: firstExport.key,
      label: "Calculations",
      hasBorders: false,
    });
  };

  displayDesignersRiskAssessment = ({ calculationsFile, riskAssessmentItems }) => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);

    const reviewer = users.find((x) => x.id === taskRevision.checkedBy);
    const reportReference = getReportReference({
      calculationsFile,
      task,
      reportJsonData,
    });

    const cell_style1 = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const COL_WIDTHS = {
        0: 80,
        1: 20,
      };

      const baseStyle = {
        width: `${COL_WIDTHS[col]}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const cell_style2 = (row, col) => {
      const COL_WIDTHS = {
        0: 60,
        1: 40,
      };
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      let borderRightWidth = 1;
      let borderBottomWidth = 1;
      let width = `${COL_WIDTHS[col]}%`;
      let padding = "5pt 5pt";

      if (row === 1) {
        if (col === 0) {
          borderRightWidth = 0;
        }
      }

      if (col === 1 && row < 4 && row > 1) {
        borderBottomWidth = 0;
      }

      if (col === 0 && row > 1) {
        padding = "11pt 5pt";
      }

      const baseStyle = {
        width,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding,
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const cell_style3 = (row, col, rows, totalRows) => {
      let borderTopWidth = 1;
      let borderBottomWidth = row === totalRows ? 1 : 0;
      let borderLeftWidth = col === 0 ? 1 : 0;
      let borderRightWidth = 1;
      let padding = "5 3";
      let fontSize = row === 0 ? 10 : 8;

      const COL_WIDTHS = {
        0: 50,
        1: 50,
        2: 70,
        3: 129,
        4: 70,
        5: 25,
        6: 25,
        7: 40,
        8: 155,
        9: 25,
        10: 25,
        11: 40,
        12: 45,
        13: 155,
        14: 155,
      };

      const COL_ALIGN = {
        0: "center",
        1: "center",
        2: "left",
        3: "left",
        4: "left",
        5: "center",
        6: "center",
        7: "center",
        8: "left",
        9: "center",
        10: "center",
        11: "center",
        12: "center",
        13: "left",
        14: "left",
      };

      let height = "100%";
      let marginTop = 0;

      let backgroundColor = "#fff";

      function getRiskColor(value) {
        if (value <= 5) {
          return "#9FCE63";
        } else if (value <= 7) {
          return "#F5C342";
        } else if (value <= 10) {
          return "#DE8344";
        } else {
          return "#EA3323";
        }
      }

      if (row > 0) {
        if (col === 7) {
          backgroundColor = getRiskColor(rows[row - 1].riskRating);
        } else if (col === 11) {
          backgroundColor = getRiskColor(rows[row - 1].residualRiskRating);
        }
      }

      const style = {
        width: `${COL_WIDTHS[col]}`,
        backgroundColor,
        display: "block",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding,
        fontSize,
        textAlign: row === 0 ? "center" : `${COL_ALIGN[col]}`,
        whiteSpace: "wrap",
        height,
        marginTop,
        fontWeight: row === 0 ? "bold" : "normal",
        color: "#333",
      };

      return style;
    };

    return (
      <View style={{ position: "absolute", left: 0, top: 0 }}>
        <View style={{ position: "absolute", left: 0, top: -40 }}>
          {/* First column start */}
          <Text
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Client:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 45,
              top: 0,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {task.client.name}
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 0,
              top: 20,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Project:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 45,
              top: 20,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {task.project.title}
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 0,
              top: 40,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Element:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 45,
              top: 40,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            Designers Risk Assessment
          </Text>
          {/* First column end */}

          {/* Second column start */}
          <Text
            style={{
              position: "absolute",
              left: 300,
              top: 20,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Job No:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 345,
              top: 20,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {task.id.split("-").slice(1).join("-")}
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 300,
              top: 40,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Doc No:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 345,
              top: 40,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {reportReference && reportReference.length > 0 && reportReference.split("-").slice(-1)[0]}
          </Text>
          {/* Second column end */}
          {/* Third column start */}
          <Text
            style={{
              position: "absolute",
              left: 550,
              top: 20,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            By:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 595,
              top: 20,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {assignedTo?.firstName[0].toLowerCase()}
            {assignedTo?.lastName[0].toLowerCase()}
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 550,
              top: 40,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Checked:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 595,
              top: 40,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {reviewer?.firstName[0].toLowerCase()}
            {reviewer?.lastName[0].toLowerCase()}
          </Text>
          {/* Third column end */}

          {/* Fourth column start */}
          <Text
            style={{
              position: "absolute",
              left: 750,
              top: 40,
              fontWeight: "bold",
              color: colors.accentColor,
              fontSize: 10,
              textAlign: "right",
              width: 40,
            }}
          >
            Date:
          </Text>
          <Text
            style={{
              position: "absolute",
              left: 795,
              top: 40,
              fontWeight: "bold",
              color: colors.darkGray,
              fontSize: 10,
              width: 200,
            }}
          >
            {moment(taskRevision.reviewAcceptDate || undefined).format("dddd MMM DD, YYYY")}
          </Text>
          {/* Fourth column end */}
        </View>
        <Table
          style={{ marginTop: 30, width: 470 }}
          includeHeader={false}
          columns={[
            {
              title: "impact",
              dataIndex: "impact",
              key: "impact",
            },
            {
              title: "probability",
              dataIndex: "probability",
              key: "probability",
            },
          ]}
          data={[
            {
              impact: (
                <View>
                  <Text style={[styles.bold]}>Impact:</Text>
                </View>
              ),
              probability: (
                <View>
                  <Text style={[styles.bold]}>Probability:</Text>
                </View>
              ),
            },
            {
              impact: <Text>1: Nil or slight injury / illness, property damage or environmental issue.</Text>,
              probability: <Text>1 - Highly Unlikely</Text>,
            },
            {
              impact: <Text>2: Minor injury / illness, property damage or environmental issue.</Text>,
              probability: <Text>2 - Unlikely</Text>,
            },
            {
              impact: <Text>3: Moderate injury or illness, property damage or environmental issue.</Text>,
              probability: <Text>3 - Possible</Text>,
            },
            {
              impact: <Text>4: Major injury or illness, property damage or environmental issue.</Text>,
              probability: <Text>4 - Likely</Text>,
            },
            {
              impact: (
                <Text>5: Fatal or long term disabling injury or illness, property damage or environmental issue.</Text>
              ),
              probability: <Text>5 - Highly Likely</Text>,
            },
          ]}
          style_function={cell_style1}
        />
        <Table
          style={{ width: 150, position: "absolute", top: 30, left: 490 }}
          includeHeader={false}
          columns={[
            {
              title: "label",
              dataIndex: "label",
              key: "label",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              label: (
                <View>
                  <Text style={[styles.bold]}>Risk type:</Text>
                </View>
              ),
              value: (
                <View>
                  <Text style={[styles.bold]}></Text>
                </View>
              ),
            },
            {
              label: <Text>Design</Text>,
              value: <Text>D</Text>,
            },
            {
              label: <Text>Construction</Text>,
              value: <Text>C</Text>,
            },
            {
              label: <Text>Operational</Text>,
              value: <Text>O</Text>,
            },
            {
              label: <Text>Decomissioning</Text>,
              value: <Text>D</Text>,
            },
            {
              label: <Text>-</Text>,
              value: <Text>-</Text>,
            },
          ]}
          style_function={cell_style1}
        />
        <Table
          style={{ width: 400, position: "absolute", top: 30, left: 658 }}
          includeHeader={false}
          columns={[
            {
              title: "label",
              dataIndex: "label",
              key: "label",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              label: (
                <View>
                  <Text style={[styles.bold]}>Risk Rating</Text>
                </View>
              ),
              value: (
                <View>
                  <Text style={[styles.bold]}></Text>
                </View>
              ),
            },
            {
              label: <Text>Risk Rating System:</Text>,
              value: <Text></Text>,
            },
            {
              label: <Text>Where the rating is 5 or less, no further action is required.</Text>,
              value: <Text></Text>,
            },
            {
              label: (
                <Text>Where the rating is 6 or more, the risk is unacceptable and control measures are required.</Text>
              ),
              value: <Text></Text>,
            },
          ]}
          style_function={cell_style2}
        />
        <Image src={DRAMatrix} style={{ position: "absolute", left: 922, top: 75, width: 100 }} />

        <Table
          style={{ marginBottom: 20, marginTop: 30 }}
          includeHeader={true}
          columns={[
            {
              title: "Ref No",
              dataIndex: "refNo",
              key: "refNo",
            },
            {
              title: "Risk Type",
              dataIndex: "riskType",
              key: "riskType",
            },
            {
              title: (
                <View>
                  <Text>Activity /</Text>
                  <Text>Element</Text>
                </View>
              ),
              dataIndex: "element",
              key: "element",
            },
            {
              title: "Potential Hazard",
              dataIndex: "potentialHazard",
              key: "potentialHazard",
            },
            {
              title: "Population at Risk",
              dataIndex: "populationAtRisk",
              key: "populationAtRisk",
            },
            {
              title: "Prob",
              dataIndex: "probability",
              key: "probability",
            },
            {
              title: "Imp",
              dataIndex: "impact",
              key: "impact",
            },
            {
              title: "Risk Rating",
              dataIndex: "riskRating",
              key: "riskRating",
            },
            {
              title: "Action at Design Stage",
              dataIndex: "actionDesignStage",
              key: "actionDesignStage",
            },
            {
              title: "Prob",
              dataIndex: "residualProbability",
              key: "residualProbability",
            },
            {
              title: "Imp",
              dataIndex: "residualImpact",
              key: "residualImpact",
            },
            {
              title: "Risk Rating",
              dataIndex: "residualRiskRating",
              key: "residualRiskRating",
            },
            {
              title: "Residual Risk",
              dataIndex: "hasResidualRisk",
              key: "hasResidualRisk",
            },
            {
              title: "Residual Risk Description",
              dataIndex: "residualRiskDescription",
              key: "residualRiskDescription",
            },
            {
              title: "Notes",
              dataIndex: "notes",
              key: "notes",
            },
          ]}
          data={riskAssessmentItems.map((item, i) => {
            return {
              ...item,
              refNo: item.refNo === undefined || item.refNo === null || item.refNo.length === 0 ? i + 1 : item.refNo,
              riskRating: item.probability && item.impact ? parseInt(item.probability) * parseInt(item.impact) : 0,
              residualRiskRating:
                item.residualProbability && item.residualImpact
                  ? parseInt(item.residualProbability) * parseInt(item.residualImpact)
                  : 0,
            };
          })}
          style_function={cell_style3}
        />
      </View>
    );
  };

  displayCat2Check = () => {
    const { task, users } = this.props;
    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    const { cat2ReportJsonData } = this.state;
    if (!cat2ReportJsonData) {
      return null;
    }

    const latestRevision = getLatestRevision(cat2CheckTask);
    const calculationsFile = latestRevision.files.items.find((x) => x.type === "EXCEL" && !x.isArchived);

    const cat2Checker = users.find((x) => x.id === cat2CheckTask.assignedTo);
    const reviewedAt = latestRevision.reviewAcceptDate
      ? moment(latestRevision.reviewAcceptDate).format("DD/MM/YY")
      : "";

    let calculationsFileReference = "";
    if (calculationsFile) {
      const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
      if (latestCalculationsFileVersion.customId) {
        calculationsFileReference = latestCalculationsFileVersion.customId;
      } else {
        calculationsFileReference = getFilenameFromKey(latestCalculationsFileVersion.key);
      }
    }

    const cat2CheckCalculationsHasExport = fileHasAtLeastOneExport(calculationsFile);

    return (
      <>
        <View>
          <Text style={[styles.sectionSubtitle]}>Check Philosophy</Text>
          <Text style={[styles.paragraph]}>
            <Text style={styles.bold}>Project Title:</Text> {cat2CheckTask.project.title}
          </Text>
          <Text style={[styles.paragraph]}>
            <Text style={styles.bold}>Project Title:</Text> {cat2CheckTask.title}
          </Text>
          <Text style={[styles.paragraph]}>
            <Text style={styles.bold}>Calculation Reference:</Text> {calculationsFileReference}
          </Text>
          <Text style={[styles.paragraph]}>
            <Text style={styles.bold}>Check By:</Text>{" "}
            {cat2Checker ? `${cat2Checker.firstName} ${cat2Checker.lastName}` : null}
          </Text>
          <Text style={[styles.paragraph]}>
            <Text style={styles.bold}>Date:</Text> {reviewedAt}
          </Text>
          <Text style={[styles.sectionSubtitle]}>Information received</Text>
          {Array.isArray(cat2ReportJsonData.fields.attachmentsUtilised.value)
            ? cat2ReportJsonData.fields.attachmentsUtilised.value.map((item, i) => (
                <Text style={[styles.paragraph]} key={i}>
                  {getFilenameFromKey(item, true)}
                </Text>
              ))
            : null}
          <View break></View>
        </View>
        {["Brief", "Layout", "Loading", "Stability", "Assumptions", "Exclusions"].map((fieldName) =>
          displayPdfTextarea({
            fieldName,
            targetReportJsonData: cat2ReportJsonData,
            attachmentImages: this.state.attachmentImages,
            styles,
            projectFolder: this.props.projectFolder,
          })
        )}
        <View break></View>
        {["Summary", "Recommendations"].map((fieldName) =>
          displayPdfTextarea({
            fieldName,
            targetReportJsonData: cat2ReportJsonData,
            attachmentImages: this.state.attachmentImages,
            styles,
            projectFolder: this.props.projectFolder,
          })
        )}
        <View>
          {cat2CheckCalculationsHasExport && (
            <Text
              render={({ pageNumber }) => {
                if (calculationsFile) {
                  const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
                  const firstExport = latestCalculationsFileVersion.exports[0];

                  setLambdaPdfInsert(pageNumber, {
                    deletePage: false,
                    key: firstExport.key,
                    hasBorders: false,
                  });
                }
                return null;
              }}
            />
          )}
        </View>
      </>
    );
  };

  displayAppendix = () => {
    const { reportJsonData, attachmentImages } = this.state;

    const appendixSections = reportJsonData.fields.appendix.value;

    return appendixSections.map((section, i) => {
      return (
        <React.Fragment key={`appendix-${section.id}-${i}`}>
          <Page size="A4" style={styles.page} wrap={false}>
            <View>
              <Text style={styles.pageTitle} break>
                {section.title}
              </Text>
              <Text style={{ ...styles.pageSubtitle, marginTop: -10 }} break>
                {section.description}
              </Text>
            </View>
          </Page>

          {section.value.map((key, j) => {
            const type = getAttachmentTypeFromKey(key);

            if (type === "PDF") {
              return displayPdfAttachment({
                elementKey: j,
                key,
                fieldName: "supportingInformation",
                hasBorders: false,
              });
            } else {
              return (
                <Page size="A4" wrap={false} key={j}>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "90%",
                      height: "90%",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                  >
                    <Image style={{ display: "block", marginTop: 0 }} src={attachmentImages[key]} />
                  </View>
                </Page>
              );
            }
          })}
        </React.Fragment>
      );
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayRiskAssessmentPages = ({ calculationsFile }) => {
    const { reportJsonData } = this.state;
    const riskAssessment = reportJsonData.fields.riskAssessment;
    const riskAssessmentAllItems = riskAssessment.value.filter((item) => item);
    let pages = [];
    let numberOfPages = Math.ceil(riskAssessmentAllItems.length / DRA_ITEMS_PER_PAGE);
    for (let i = 0; i < numberOfPages; i++) {
      pages.push(
        <Page
          size={[1190.55, 841.89]}
          style={[styles.page, { paddingLeft: 66, paddingRight: 66, paddingTop: 80 }]}
          key={i}
        >
          <View>
            {this.displayDesignersRiskAssessment({
              calculationsFile,
              riskAssessmentItems: riskAssessmentAllItems.slice(i * DRA_ITEMS_PER_PAGE, (i + 1) * DRA_ITEMS_PER_PAGE),
            })}
          </View>
        </Page>
      );
    }
    return pages;
  };

  displayDocument = () => {
    const { task, file, users, taskRevision } = this.props;
    const { isLoading, calculationsFile, linkedTasksDetails, reportJsonData } = this.state;

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "AWD/awd-logo.png",
      },
      {
        bucket: awsExports.aws_user_files_s3_bucket,
        name: "clientLogo",
        key: "public/" + task.client.key,
        width: 110,
        height: 35,
      },
    ];

    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: linkedTasksDetails,
    });

    let reportType = reportJsonData.fields.reportType.value;

    // this is used to mark pages where we do not want page borders, logo or other markers to be applied
    window.lambdaPdfPageNumbersToSkipBorders = [0, -1];

    window.pdfPageNumbersToDownload = {};

    let isDefaultTemplate = file.templateId.includes("default");

    return (
      <Document>
        <FrontCover
          approvedAt={taskRevision.reviewAcceptDate}
          infoItems={getReportFrontCoverInfoItems({
            reportType,
            task,
            calculationsFile,
            reportJsonData,
          })}
          coverImage={CoverBackground}
        />

        <Page size="A4" style={styles.page} wrap>
          <View>
            <Text style={{ ...styles.pageTitle, marginTop: 0 }} break>
              Document Revision History
            </Text>
            {this.displayRevisionHistory({ task, users, calculationsFile })}
          </View>
        </Page>

        {isDefaultTemplate && reportType === "design" ? (
          <Page size="A4" style={styles.page}>
            {this.displayDesignCertificate()}
          </Page>
        ) : null}

        {isDefaultTemplate && reportType === "check" ? (
          <Page size="A4" style={{ ...styles.page, paddingBottom: 140 }}>
            <View>{this.displayCheckCertificate(reportType)}</View>
          </Page>
        ) : null}
        {isDefaultTemplate && reportType === "check" ? (
          <Page size="A4" style={{ ...styles.page, paddingBottom: 140 }}>
            <View>{this.displayCheckCertificateSchedule(reportType)}</View>
          </Page>
        ) : null}
        {file.templateId.includes("generic_report") && (
          <Page size="A4" style={styles.page} wrap>
            {reportJsonData.fields.content.value.map((section) =>
              displayPdfTextarea({
                fieldName: "content",
                sectionId: section.id,
                targetReportJsonData: reportJsonData,
                attachmentImages: this.state.attachmentImages,
                styles,
                projectFolder: this.props.projectFolder,
              })
            )}
          </Page>
        )}

        {isDefaultTemplate && (
          <Page size="A4" style={styles.page} wrap>
            {["Brief", "Layout"].map((fieldName) =>
              displayPdfTextarea({
                fieldName,
                targetReportJsonData: reportJsonData,
                attachmentImages: this.state.attachmentImages,
                styles,
                projectFolder: this.props.projectFolder,
              })
            )}
            <View>
              {this.displayStandards()}
              <Text style={[styles.sectionSubtitle]}>Information received</Text>
              {Array.isArray(reportJsonData.fields.attachmentsUtilised.value) &&
              reportJsonData.fields.attachmentsUtilised.value.length > 0 ? (
                reportJsonData.fields.attachmentsUtilised.value.map((key, i) => (
                  <Text style={[styles.paragraph]} key={i}>
                    {getFilenameFromKey(key, true)}
                  </Text>
                ))
              ) : (
                <Text key={`paragraph-${Math.random()}`} style={[styles.paragraph, styles.lastParagraph]} />
              )}
            </View>
            {["Loading", "Stability", "Assumptions", "Exclusions"].map((fieldName) =>
              displayPdfTextarea({
                fieldName,
                targetReportJsonData: reportJsonData,
                attachmentImages: this.state.attachmentImages,
                styles,
                projectFolder: this.props.projectFolder,
              })
            )}
            {reportJsonData.fields.TextSectionsDynamic.value.map((section) =>
              displayPdfTextarea({
                fieldName: "TextSectionsDynamic",
                sectionId: section.id,
                targetReportJsonData: reportJsonData,
                attachmentImages: this.state.attachmentImages,
                styles,
                projectFolder: this.props.projectFolder,
              })
            )}
          </Page>
        )}

        {isDefaultTemplate && (
          <Page size="A4" style={styles.page}>
            {["Summary", "Recommendations"].map((fieldName) =>
              displayPdfTextarea({
                fieldName,
                targetReportJsonData: reportJsonData,
                attachmentImages: this.state.attachmentImages,
                styles,
                projectFolder: this.props.projectFolder,
              })
            )}
          </Page>
        )}

        {calculationsFile && this.displayCalculations()}

        {isDefaultTemplate && reportType === "design" && this.displayRiskAssessmentPages({ calculationsFile })}

        {isDefaultTemplate && cat2CheckTask && (
          <Page size="A4" style={styles.page}>
            <View>
              <Text style={[styles.pageTitle]}>Check Philosophy</Text>
            </View>
          </Page>
        )}

        {isDefaultTemplate && cat2CheckTask && (
          <Page size="A4" style={styles.page}>
            <View>{this.displayCat2Check(reportType)}</View>
          </Page>
        )}

        {Array.isArray(reportJsonData.fields.appendix.value) && reportJsonData.fields.appendix.value.length > 0
          ? this.displayAppendix()
          : null}

        <RearCover coverImage={RearCoverImage} />
      </Document>
    );
  };

  render() {
    const { task, users, taskRevision, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-AWD">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(this.state.reportJsonData)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(AWD_Report);
