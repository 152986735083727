/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
          formsCanOnlyBeAddedToOneTask
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
          formsCanOnlyBeAddedToOneTask
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
          formsCanOnlyBeAddedToOneTask
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVariable = /* GraphQL */ `
  mutation CreateVariable(
    $input: CreateVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    createVariable(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateVariable = /* GraphQL */ `
  mutation UpdateVariable(
    $input: UpdateVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    updateVariable(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteVariable = /* GraphQL */ `
  mutation DeleteVariable(
    $input: DeleteVariableInput!
    $condition: ModelVariableConditionInput
  ) {
    deleteVariable(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const createSprint = /* GraphQL */ `
  mutation CreateSprint(
    $input: CreateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    createSprint(input: $input, condition: $condition) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSprint = /* GraphQL */ `
  mutation UpdateSprint(
    $input: UpdateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    updateSprint(input: $input, condition: $condition) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSprint = /* GraphQL */ `
  mutation DeleteSprint(
    $input: DeleteSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    deleteSprint(input: $input, condition: $condition) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $input: CreateBillInput!
    $condition: ModelBillConditionInput
  ) {
    createBill(input: $input, condition: $condition) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill(
    $input: UpdateBillInput!
    $condition: ModelBillConditionInput
  ) {
    updateBill(input: $input, condition: $condition) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill(
    $input: DeleteBillInput!
    $condition: ModelBillConditionInput
  ) {
    deleteBill(input: $input, condition: $condition) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const createTimesheetTag = /* GraphQL */ `
  mutation CreateTimesheetTag(
    $input: CreateTimesheetTagInput!
    $condition: ModelTimesheetTagConditionInput
  ) {
    createTimesheetTag(input: $input, condition: $condition) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const updateTimesheetTag = /* GraphQL */ `
  mutation UpdateTimesheetTag(
    $input: UpdateTimesheetTagInput!
    $condition: ModelTimesheetTagConditionInput
  ) {
    updateTimesheetTag(input: $input, condition: $condition) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimesheetTag = /* GraphQL */ `
  mutation DeleteTimesheetTag(
    $input: DeleteTimesheetTagInput!
    $condition: ModelTimesheetTagConditionInput
  ) {
    deleteTimesheetTag(input: $input, condition: $condition) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const createTimelineBlock = /* GraphQL */ `
  mutation CreateTimelineBlock(
    $input: CreateTimelineBlockInput!
    $condition: ModelTimelineBlockConditionInput
  ) {
    createTimelineBlock(input: $input, condition: $condition) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const updateTimelineBlock = /* GraphQL */ `
  mutation UpdateTimelineBlock(
    $input: UpdateTimelineBlockInput!
    $condition: ModelTimelineBlockConditionInput
  ) {
    updateTimelineBlock(input: $input, condition: $condition) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const deleteTimelineBlock = /* GraphQL */ `
  mutation DeleteTimelineBlock(
    $input: DeleteTimelineBlockInput!
    $condition: ModelTimelineBlockConditionInput
  ) {
    deleteTimelineBlock(input: $input, condition: $condition) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createBoardTask = /* GraphQL */ `
  mutation CreateBoardTask(
    $input: CreateBoardTaskInput!
    $condition: ModelBoardTaskConditionInput
  ) {
    createBoardTask(input: $input, condition: $condition) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateBoardTask = /* GraphQL */ `
  mutation UpdateBoardTask(
    $input: UpdateBoardTaskInput!
    $condition: ModelBoardTaskConditionInput
  ) {
    updateBoardTask(input: $input, condition: $condition) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteBoardTask = /* GraphQL */ `
  mutation DeleteBoardTask(
    $input: DeleteBoardTaskInput!
    $condition: ModelBoardTaskConditionInput
  ) {
    deleteBoardTask(input: $input, condition: $condition) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
            formsCanOnlyBeAddedToOneTask
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
            formsCanOnlyBeAddedToOneTask
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
            formsCanOnlyBeAddedToOneTask
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createSubtask = /* GraphQL */ `
  mutation CreateSubtask(
    $input: CreateSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    createSubtask(input: $input, condition: $condition) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSubtask = /* GraphQL */ `
  mutation UpdateSubtask(
    $input: UpdateSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    updateSubtask(input: $input, condition: $condition) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSubtask = /* GraphQL */ `
  mutation DeleteSubtask(
    $input: DeleteSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    deleteSubtask(input: $input, condition: $condition) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createTaskActivityItem = /* GraphQL */ `
  mutation CreateTaskActivityItem(
    $input: CreateTaskActivityItemInput!
    $condition: ModelTaskActivityItemConditionInput
  ) {
    createTaskActivityItem(input: $input, condition: $condition) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskActivityItem = /* GraphQL */ `
  mutation UpdateTaskActivityItem(
    $input: UpdateTaskActivityItemInput!
    $condition: ModelTaskActivityItemConditionInput
  ) {
    updateTaskActivityItem(input: $input, condition: $condition) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskActivityItem = /* GraphQL */ `
  mutation DeleteTaskActivityItem(
    $input: DeleteTaskActivityItemInput!
    $condition: ModelTaskActivityItemConditionInput
  ) {
    deleteTaskActivityItem(input: $input, condition: $condition) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const createActivityItem = /* GraphQL */ `
  mutation CreateActivityItem(
    $input: CreateActivityItemInput!
    $condition: ModelActivityItemConditionInput
  ) {
    createActivityItem(input: $input, condition: $condition) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const updateActivityItem = /* GraphQL */ `
  mutation UpdateActivityItem(
    $input: UpdateActivityItemInput!
    $condition: ModelActivityItemConditionInput
  ) {
    updateActivityItem(input: $input, condition: $condition) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivityItem = /* GraphQL */ `
  mutation DeleteActivityItem(
    $input: DeleteActivityItemInput!
    $condition: ModelActivityItemConditionInput
  ) {
    deleteActivityItem(input: $input, condition: $condition) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuoteActivityItem = /* GraphQL */ `
  mutation CreateQuoteActivityItem(
    $input: CreateQuoteActivityItemInput!
    $condition: ModelQuoteActivityItemConditionInput
  ) {
    createQuoteActivityItem(input: $input, condition: $condition) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const updateQuoteActivityItem = /* GraphQL */ `
  mutation UpdateQuoteActivityItem(
    $input: UpdateQuoteActivityItemInput!
    $condition: ModelQuoteActivityItemConditionInput
  ) {
    updateQuoteActivityItem(input: $input, condition: $condition) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const deleteQuoteActivityItem = /* GraphQL */ `
  mutation DeleteQuoteActivityItem(
    $input: DeleteQuoteActivityItemInput!
    $condition: ModelQuoteActivityItemConditionInput
  ) {
    deleteQuoteActivityItem(input: $input, condition: $condition) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const createInvoiceActivityItem = /* GraphQL */ `
  mutation CreateInvoiceActivityItem(
    $input: CreateInvoiceActivityItemInput!
    $condition: ModelInvoiceActivityItemConditionInput
  ) {
    createInvoiceActivityItem(input: $input, condition: $condition) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const updateInvoiceActivityItem = /* GraphQL */ `
  mutation UpdateInvoiceActivityItem(
    $input: UpdateInvoiceActivityItemInput!
    $condition: ModelInvoiceActivityItemConditionInput
  ) {
    updateInvoiceActivityItem(input: $input, condition: $condition) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const deleteInvoiceActivityItem = /* GraphQL */ `
  mutation DeleteInvoiceActivityItem(
    $input: DeleteInvoiceActivityItemInput!
    $condition: ModelInvoiceActivityItemConditionInput
  ) {
    deleteInvoiceActivityItem(input: $input, condition: $condition) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const createDocumentActivityItem = /* GraphQL */ `
  mutation CreateDocumentActivityItem(
    $input: CreateDocumentActivityItemInput!
    $condition: ModelDocumentActivityItemConditionInput
  ) {
    createDocumentActivityItem(input: $input, condition: $condition) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const updateDocumentActivityItem = /* GraphQL */ `
  mutation UpdateDocumentActivityItem(
    $input: UpdateDocumentActivityItemInput!
    $condition: ModelDocumentActivityItemConditionInput
  ) {
    updateDocumentActivityItem(input: $input, condition: $condition) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const deleteDocumentActivityItem = /* GraphQL */ `
  mutation DeleteDocumentActivityItem(
    $input: DeleteDocumentActivityItemInput!
    $condition: ModelDocumentActivityItemConditionInput
  ) {
    deleteDocumentActivityItem(input: $input, condition: $condition) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const createQuoteLineItem = /* GraphQL */ `
  mutation CreateQuoteLineItem(
    $input: CreateQuoteLineItemInput!
    $condition: ModelQuoteLineItemConditionInput
  ) {
    createQuoteLineItem(input: $input, condition: $condition) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateQuoteLineItem = /* GraphQL */ `
  mutation UpdateQuoteLineItem(
    $input: UpdateQuoteLineItemInput!
    $condition: ModelQuoteLineItemConditionInput
  ) {
    updateQuoteLineItem(input: $input, condition: $condition) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteQuoteLineItem = /* GraphQL */ `
  mutation DeleteQuoteLineItem(
    $input: DeleteQuoteLineItemInput!
    $condition: ModelQuoteLineItemConditionInput
  ) {
    deleteQuoteLineItem(input: $input, condition: $condition) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createPurchaseOrderLineItem = /* GraphQL */ `
  mutation CreatePurchaseOrderLineItem(
    $input: CreatePurchaseOrderLineItemInput!
    $condition: ModelPurchaseOrderLineItemConditionInput
  ) {
    createPurchaseOrderLineItem(input: $input, condition: $condition) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const updatePurchaseOrderLineItem = /* GraphQL */ `
  mutation UpdatePurchaseOrderLineItem(
    $input: UpdatePurchaseOrderLineItemInput!
    $condition: ModelPurchaseOrderLineItemConditionInput
  ) {
    updatePurchaseOrderLineItem(input: $input, condition: $condition) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const deletePurchaseOrderLineItem = /* GraphQL */ `
  mutation DeletePurchaseOrderLineItem(
    $input: DeletePurchaseOrderLineItemInput!
    $condition: ModelPurchaseOrderLineItemConditionInput
  ) {
    deletePurchaseOrderLineItem(input: $input, condition: $condition) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const createInvoiceLineItem = /* GraphQL */ `
  mutation CreateInvoiceLineItem(
    $input: CreateInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    createInvoiceLineItem(input: $input, condition: $condition) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateInvoiceLineItem = /* GraphQL */ `
  mutation UpdateInvoiceLineItem(
    $input: UpdateInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    updateInvoiceLineItem(input: $input, condition: $condition) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteInvoiceLineItem = /* GraphQL */ `
  mutation DeleteInvoiceLineItem(
    $input: DeleteInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    deleteInvoiceLineItem(input: $input, condition: $condition) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createTaskRevision = /* GraphQL */ `
  mutation CreateTaskRevision(
    $input: CreateTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    createTaskRevision(input: $input, condition: $condition) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTaskRevision = /* GraphQL */ `
  mutation UpdateTaskRevision(
    $input: UpdateTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    updateTaskRevision(input: $input, condition: $condition) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTaskRevision = /* GraphQL */ `
  mutation DeleteTaskRevision(
    $input: DeleteTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    deleteTaskRevision(input: $input, condition: $condition) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSheet = /* GraphQL */ `
  mutation CreateSheet(
    $input: CreateSheetInput!
    $condition: ModelSheetConditionInput
  ) {
    createSheet(input: $input, condition: $condition) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSheet = /* GraphQL */ `
  mutation UpdateSheet(
    $input: UpdateSheetInput!
    $condition: ModelSheetConditionInput
  ) {
    updateSheet(input: $input, condition: $condition) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSheet = /* GraphQL */ `
  mutation DeleteSheet(
    $input: DeleteSheetInput!
    $condition: ModelSheetConditionInput
  ) {
    deleteSheet(input: $input, condition: $condition) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createSheetRevision = /* GraphQL */ `
  mutation CreateSheetRevision(
    $input: CreateSheetRevisionInput!
    $condition: ModelSheetRevisionConditionInput
  ) {
    createSheetRevision(input: $input, condition: $condition) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSheetRevision = /* GraphQL */ `
  mutation UpdateSheetRevision(
    $input: UpdateSheetRevisionInput!
    $condition: ModelSheetRevisionConditionInput
  ) {
    updateSheetRevision(input: $input, condition: $condition) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSheetRevision = /* GraphQL */ `
  mutation DeleteSheetRevision(
    $input: DeleteSheetRevisionInput!
    $condition: ModelSheetRevisionConditionInput
  ) {
    deleteSheetRevision(input: $input, condition: $condition) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFileVersion = /* GraphQL */ `
  mutation CreateFileVersion(
    $input: CreateFileVersionInput!
    $condition: ModelFileVersionConditionInput
  ) {
    createFileVersion(input: $input, condition: $condition) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFileVersion = /* GraphQL */ `
  mutation UpdateFileVersion(
    $input: UpdateFileVersionInput!
    $condition: ModelFileVersionConditionInput
  ) {
    updateFileVersion(input: $input, condition: $condition) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFileVersion = /* GraphQL */ `
  mutation DeleteFileVersion(
    $input: DeleteFileVersionInput!
    $condition: ModelFileVersionConditionInput
  ) {
    deleteFileVersion(input: $input, condition: $condition) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const createAsyncJob = /* GraphQL */ `
  mutation CreateAsyncJob(
    $input: CreateAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    createAsyncJob(input: $input, condition: $condition) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAsyncJob = /* GraphQL */ `
  mutation UpdateAsyncJob(
    $input: UpdateAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    updateAsyncJob(input: $input, condition: $condition) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAsyncJob = /* GraphQL */ `
  mutation DeleteAsyncJob(
    $input: DeleteAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    deleteAsyncJob(input: $input, condition: $condition) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            notesStart
            notesEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAuditItem = /* GraphQL */ `
  mutation CreateAuditItem(
    $input: CreateAuditItemInput!
    $condition: ModelAuditItemConditionInput
  ) {
    createAuditItem(input: $input, condition: $condition) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const updateAuditItem = /* GraphQL */ `
  mutation UpdateAuditItem(
    $input: UpdateAuditItemInput!
    $condition: ModelAuditItemConditionInput
  ) {
    updateAuditItem(input: $input, condition: $condition) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const deleteAuditItem = /* GraphQL */ `
  mutation DeleteAuditItem(
    $input: DeleteAuditItemInput!
    $condition: ModelAuditItemConditionInput
  ) {
    deleteAuditItem(input: $input, condition: $condition) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const createHistoricData = /* GraphQL */ `
  mutation CreateHistoricData(
    $input: CreateHistoricDataInput!
    $condition: ModelHistoricDataConditionInput
  ) {
    createHistoricData(input: $input, condition: $condition) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const updateHistoricData = /* GraphQL */ `
  mutation UpdateHistoricData(
    $input: UpdateHistoricDataInput!
    $condition: ModelHistoricDataConditionInput
  ) {
    updateHistoricData(input: $input, condition: $condition) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistoricData = /* GraphQL */ `
  mutation DeleteHistoricData(
    $input: DeleteHistoricDataInput!
    $condition: ModelHistoricDataConditionInput
  ) {
    deleteHistoricData(input: $input, condition: $condition) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const createTimesheetBlock = /* GraphQL */ `
  mutation CreateTimesheetBlock(
    $input: CreateTimesheetBlockInput!
    $condition: ModelTimesheetBlockConditionInput
  ) {
    createTimesheetBlock(input: $input, condition: $condition) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      notesStart
      notesEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTimesheetBlock = /* GraphQL */ `
  mutation UpdateTimesheetBlock(
    $input: UpdateTimesheetBlockInput!
    $condition: ModelTimesheetBlockConditionInput
  ) {
    updateTimesheetBlock(input: $input, condition: $condition) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      notesStart
      notesEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTimesheetBlock = /* GraphQL */ `
  mutation DeleteTimesheetBlock(
    $input: DeleteTimesheetBlockInput!
    $condition: ModelTimesheetBlockConditionInput
  ) {
    deleteTimesheetBlock(input: $input, condition: $condition) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      notesStart
      notesEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const createHoliday = /* GraphQL */ `
  mutation CreateHoliday(
    $input: CreateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    createHoliday(input: $input, condition: $condition) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const updateHoliday = /* GraphQL */ `
  mutation UpdateHoliday(
    $input: UpdateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    updateHoliday(input: $input, condition: $condition) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const deleteHoliday = /* GraphQL */ `
  mutation DeleteHoliday(
    $input: DeleteHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    deleteHoliday(input: $input, condition: $condition) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          notesStart
          notesEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createPublicArticle = /* GraphQL */ `
  mutation CreatePublicArticle(
    $input: CreatePublicArticleInput!
    $condition: ModelPublicArticleConditionInput
  ) {
    createPublicArticle(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const updatePublicArticle = /* GraphQL */ `
  mutation UpdatePublicArticle(
    $input: UpdatePublicArticleInput!
    $condition: ModelPublicArticleConditionInput
  ) {
    updatePublicArticle(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const deletePublicArticle = /* GraphQL */ `
  mutation DeletePublicArticle(
    $input: DeletePublicArticleInput!
    $condition: ModelPublicArticleConditionInput
  ) {
    deletePublicArticle(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const createInterval = /* GraphQL */ `
  mutation CreateInterval(
    $input: CreateIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    createInterval(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const updateInterval = /* GraphQL */ `
  mutation UpdateInterval(
    $input: UpdateIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    updateInterval(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const deleteInterval = /* GraphQL */ `
  mutation DeleteInterval(
    $input: DeleteIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    deleteInterval(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const createStockItem = /* GraphQL */ `
  mutation CreateStockItem(
    $input: CreateStockItemInput!
    $condition: ModelStockItemConditionInput
  ) {
    createStockItem(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
export const updateStockItem = /* GraphQL */ `
  mutation UpdateStockItem(
    $input: UpdateStockItemInput!
    $condition: ModelStockItemConditionInput
  ) {
    updateStockItem(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
export const deleteStockItem = /* GraphQL */ `
  mutation DeleteStockItem(
    $input: DeleteStockItemInput!
    $condition: ModelStockItemConditionInput
  ) {
    deleteStockItem(input: $input, condition: $condition) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
export const createWorkingHoursRule = /* GraphQL */ `
  mutation CreateWorkingHoursRule(
    $input: CreateWorkingHoursRuleInput!
    $condition: ModelWorkingHoursRuleConditionInput
  ) {
    createWorkingHoursRule(input: $input, condition: $condition) {
      id
      parentId
      organisation
      startTime
      endTime
      dayOfWeek
      startDate
      endDate
      breaks {
        id
        startTime
        endTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkingHoursRule = /* GraphQL */ `
  mutation UpdateWorkingHoursRule(
    $input: UpdateWorkingHoursRuleInput!
    $condition: ModelWorkingHoursRuleConditionInput
  ) {
    updateWorkingHoursRule(input: $input, condition: $condition) {
      id
      parentId
      organisation
      startTime
      endTime
      dayOfWeek
      startDate
      endDate
      breaks {
        id
        startTime
        endTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkingHoursRule = /* GraphQL */ `
  mutation DeleteWorkingHoursRule(
    $input: DeleteWorkingHoursRuleInput!
    $condition: ModelWorkingHoursRuleConditionInput
  ) {
    deleteWorkingHoursRule(input: $input, condition: $condition) {
      id
      parentId
      organisation
      startTime
      endTime
      dayOfWeek
      startDate
      endDate
      breaks {
        id
        startTime
        endTime
      }
      createdAt
      updatedAt
    }
  }
`;
