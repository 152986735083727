import moment from "moment";
import React from "react";

import { Page, Text, View, Image } from "@react-pdf/renderer";
import { ATTACHMENT_INSERT_PATTERN, BULLET_POINT_CHARACTER, BULLET_POINT_CHARACTER_OLD } from "common/constants";
import { formatCurrency, getAttachmentTypeFromKey } from "common/shared";
import { getInvoicePaymentTerms } from "common/invoiceHelpers/sharedInvoiceHelpers";

import PDFIcon from "graphics/icon_pdf.png";

export function displayPdfTextarea({
  fieldName,
  targetReportJsonData,
  sectionId,
  suffix,
  attachmentImages,
  styles,
  style = {},
  projectFolder,
  displayTitle = true,
  props,
  pageSize,
  pagePadding,
  options,
  isNewTemplate,
}) {
  // console.log("displayPdfTextarea() attachmentImages = ", attachmentImages);
  if (!targetReportJsonData) {
    return null;
  }

  let fieldData;
  if (targetReportJsonData.fields) {
    fieldData = targetReportJsonData.fields[fieldName];
  } else {
    fieldData = targetReportJsonData[fieldName];
  }

  if (!fieldData) {
    return null;
  }

  let title = fieldName;
  let text = fieldData.value;

  if (sectionId) {
    const sectionData = fieldData.value.find((section) => section.id === sectionId);
    text = sectionData.text;
    title = sectionData.title;
  }
  title = title.trim();

  // try {
  //   const parsedText = JSON.parse(text);
  //   // console.log("parsedText = ", parsedText);
  //   return displayDocumentOutputTextarea({ nodes: parsedText, style, pageSize, pagePadding, attachmentImages });
  // } catch (e) {
  //   // it means the content was created with the old system, so we use the old logic to render it
  // }

  text = fillInDynamicInfo(text, props);
  if (!text || Array.isArray(text)) {
    return null;
  }

  let paragraphs = text.split("[").join("\n[").split("]").join("]\n").split("\n");

  if (paragraphs.length === 0) {
    paragraphs = [""];
  }

  let sectionElements = [
    {
      paragraphs: [],
    },
  ];
  let sectionIndex = 0;

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];

    const attachment = getAttachmentsFromString(paragraph, styles)[0];
    if (attachment) {
      let imageStyle = {
        width: attachment.size,
      };
      let viewStyle = {};
      if (attachment.align === "left") {
        viewStyle.alignItems = "flex-start";
      } else if (attachment.align === "center" || attachment.align === "middle") {
        viewStyle.alignItems = "center";
      } else if (attachment.align === "right") {
        viewStyle.alignItems = "flex-end";
      }

      if (attachment.type === "IMAGE") {
        let src = attachmentImages[attachment.localKey] || "broken-image";
        sectionElements[sectionIndex].paragraphs.push(
          <View style={viewStyle} key={`image-${i}-${Math.random()}`}>
            <Image src={src} style={imageStyle} />
          </View>
        );
      } else if (attachment.type === "PAGE_BREAK") {
        sectionIndex++;
        sectionElements.push({
          paragraphs: [],
        });
      } else {
        let offsetTop = -styles?.page?.paddingTop || 0;
        let offsetLeft = -styles?.page?.paddingLeft || 0;

        if (pagePadding) {
          offsetTop = pagePadding.top;
          offsetLeft = pagePadding.left;
        }

        sectionElements[sectionIndex].paragraphs.push(
          displayPdfAttachment({
            offsetTop,
            offsetLeft,
            wrapInPage: false,
            elementKey: i,
            key:
              attachment.key ||
              getAttachmentFullKeyFromLocalKey({
                localKey: attachment.localKey,
                projectFolder,
              }),

            fieldName,
            suffix,
            pageSize,
          })
        );
      }
    } else {
      let contentWithDifferentFonts = [];
      for (let i = 0; i < paragraph.length; i++) {
        if (paragraph[i] === BULLET_POINT_CHARACTER || paragraph[i] === BULLET_POINT_CHARACTER_OLD) {
          contentWithDifferentFonts.push({
            isSpecial: true,
            content: BULLET_POINT_CHARACTER,
          });
        } else {
          const latestSection = contentWithDifferentFonts.slice(-1)[0];
          if (!latestSection || latestSection.isSpecial) {
            contentWithDifferentFonts.push({ content: "" });
          }

          contentWithDifferentFonts.slice(-1)[0].content += paragraph[i];
        }
      }
      if (isNewTemplate) {
        if (paragraph.length === 0) {
          contentWithDifferentFonts.push({ content: "" });
        }
      }

      let paragraphStyle = styles?.paragraph || {};
      if ((!options || options.skipLastParagraphMargin !== true) && i === paragraphs.length - 1) {
        paragraphStyle = {
          ...paragraphStyle,
          ...(styles?.lastParagraph || {}),
        };
      }

      if (isNewTemplate) {
        sectionElements[sectionIndex].paragraphs.push(
          <Text key={`paragraph-${i}-${Math.random()}`} style={style} widows={3} orphans={3}>
            {contentWithDifferentFonts.map((section) => {
              return (
                <Text key={Math.random()} style={style}>
                  {section.content || " "}
                </Text>
              );
            })}
          </Text>
        );
      } else {
        sectionElements[sectionIndex].paragraphs.push(
          <Text key={`paragraph-${i}-${Math.random()}`} style={paragraphStyle} widows={3} orphans={3}>
            {contentWithDifferentFonts.map((section) => {
              if (section.isSpecial) {
                return (
                  <Text style={{ fontFamily: "Averta" }} key={Math.random()}>
                    {section.content}
                  </Text>
                );
              } else {
                return <Text key={Math.random()}>{section.content}</Text>;
              }
            })}
          </Text>
        );
      }
    }
  }

  if (isNewTemplate) {
    return sectionElements.map((section) => section.paragraphs);
  } else {
    return sectionElements.map((section, subSectionIndex) => {
      let shouldDisplaySectionTitle = subSectionIndex === 0 && displayTitle && title !== "";
      return (
        <View key={"section " + fieldName + Math.random()} style={style} break={subSectionIndex !== 0}>
          {shouldDisplaySectionTitle && (
            <Text style={styles?.sectionSubtitle} minPresenceAhead={30}>
              {title}
            </Text>
          )}

          {section.paragraphs}
        </View>
      );
    });
  }
}

export function displayPdfAttachment({
  wrapInPage = true,
  key,
  deletePage = true,
  hasBorders = false,
  hasPageNumber = false,
  pagesToExclude,
  label,
  offsetTop,
  offsetLeft,
  pageSize,
  pagePadding,
}) {
  if (pagePadding && offsetTop === undefined) {
    offsetTop = pagePadding.top;
  }
  if (pagePadding && offsetLeft === undefined) {
    offsetLeft = pagePadding.left;
  }

  offsetLeft = offsetLeft || 0;
  offsetTop = offsetTop || 0;

  label = label || key;
  if (key.includes("/attachments/")) {
    label = label.split("/attachments/").slice(-1)[0];
  }

  let pagePaddingHorizontal = offsetLeft;
  let pagePaddingVertical = offsetTop;

  if (pagePadding) {
    pagePaddingHorizontal += pagePadding.right;
    pagePaddingVertical += pagePadding.bottom;
  }

  let containerWidth = (pageSize?.width || 500) - pagePaddingHorizontal;
  let containerHeight = (pageSize?.height || 600) - pagePaddingVertical;

  const content = (
    <>
      <View
        style={{
          width: containerWidth,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {key.toLowerCase().endsWith("pdf") ? (
          <>
            <Image src={PDFIcon} style={{ width: 100, margin: "20 auto 0" }} />
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 20,
                color: "#123245",
              }}
            >
              {label}
            </Text>
          </>
        ) : (
          <>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 20,
                color: "#123245",
              }}
            >
              {key.split("/").pop()}
            </Text>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 20,
                color: "#123245",
              }}
            >
              This page will be replaced by the actual image when you download the document
            </Text>
          </>
        )}
      </View>

      <Text
        render={({ pageNumber }) => {
          setLambdaPdfInsert(pageNumber, {
            deletePage,
            key,
            hasBorders,
            pagesToExclude,
          });
          return null;
        }}
      />
    </>
  );

  if (wrapInPage) {
    return (
      <Page size="A4" key={`${key}${Math.random()}`}>
        {content}
      </Page>
    );
  } else {
    return (
      <View
        wrap={false}
        key={`${key}${Math.random()}`}
        style={{
          height: containerHeight - 1,
          width: containerWidth,
        }}
      >
        {content}
      </View>
    );
  }
}

export function fillInDynamicInfo(text, props) {
  text = replaceDynamicInfoItem({
    item: "task title",
    fieldsToRead: ["task.title"],
    text,
    props,
  });
  text = replaceDynamicInfoItem({
    item: "project title",
    fieldsToRead: ["project.title", "task.project.title", "quote.project.title", "invoice.project.title"],
    text,
    props,
  });
  text = replaceDynamicInfoItem({
    item: "client name",
    fieldsToRead: [
      "client.name",
      "project.client.name",
      "task.client.name",
      "quote.client.name",
      "invoice.client.name",
    ],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "quote title",
    fieldsToRead: ["quote.title"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "purchase order title",
    fieldsToRead: ["purchaseOrder.title"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "quote id",
    fieldsToRead: ["quote.id"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "invoice id",
    fieldsToRead: ["invoice.id"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "purchase order id",
    fieldsToRead: ["purchaseOrder.id"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "invoice total",
    fieldsToRead: ["invoice.total"],
    formatCallback: (value) => formatCurrency("GBP", parseFloat(value || 0)),
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "client contact first name",
    fieldsToRead: ["clientContact.firstName"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "assignee first name",
    fieldsToRead: ["assignee.firstName"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "assignee last name",
    fieldsToRead: ["assignee.lastName"],
    text,
    props,
  });

  text = replaceDynamicInfoItem({
    item: "today",
    fieldsToRead: ["today"],
    text,
    props: {
      today: moment().format("DD MMMM YYYY"),
    },
  });

  text = replaceDynamicInfoItem({
    item: /\[today\+([0-9]+)days\]/,
    dynamicValuesCallback: (dynamicValues) => {
      return moment().add(dynamicValues[1], "day").format("DD-MM-YYYY");
    },
    text,
  });

  if (text.includes("[invoice due date]")) {
    let paymentTerms = getInvoicePaymentTerms(props);
    let dueDateFormatted = moment().add(paymentTerms, "days").format("DD-MM-YYYY");
    text = text.split("[invoice due date]").join(dueDateFormatted);
  }

  return text;
}

export function getAttachmentsFromString(fieldValue) {
  let attachments = [];
  fieldValue = fieldValue.split("]").join("]\n");
  if (fieldValue.length === 0) {
    return attachments;
  }
  let attachmentTexts = fieldValue.match(ATTACHMENT_INSERT_PATTERN);
  if (attachmentTexts) {
    attachments = attachmentTexts.map((imageText) => getImageDetails(imageText));
  }
  return attachments;
}

export function getAttachmentFullKeyFromLocalKey({ localKey, projectFolder }) {
  const attachmentsFolder = `${projectFolder}/attachments/`;

  // this is to protect from situations when the local key is actually the full key
  // (as is the case for the report fields of type attachmentPicker)
  if (localKey.indexOf(attachmentsFolder) === 0) {
    return localKey;
  }
  return `${attachmentsFolder}${localKey}`;
}

export function setLambdaPdfInsert(pageNumber, insert) {
  if (!insert.bucket) {
    if (global.isBrowser) {
      insert.bucket = window.awsExports.aws_user_files_s3_bucket;
    } else {
      insert.bucket = process.env.STORAGE_PROJECTSS3_BUCKETNAME;
    }
  }

  if (global.isBrowser) {
    window.lambdaPdfInserts[window.reportRenderCycle][pageNumber] = insert;
  } else {
    global.lambdaPdfInserts[global.reportRenderCycle][pageNumber] = insert;
  }
}

function replaceDynamicInfoItem({ text, item, props, fieldsToRead, formatCallback, dynamicValuesCallback }) {
  text = text || "";

  if (typeof item === "string") {
    item = `[${item}]`;

    if (text.includes(item)) {
      for (let i = 0; i < fieldsToRead.length; i++) {
        let fieldText = `props.${fieldsToRead[i]}`;
        try {
          let value = eval(fieldText); // eslint-disable-line
          let formattedValue = value;
          if (formatCallback) {
            formattedValue = formatCallback(value);
          }
          text = text.split(item).join(formattedValue || "");
          break;
        } catch (e) {
          // nothing
        }
      }
    }
  } else if (typeof item === "object") {
    // debugger;

    let matchResult;
    if (typeof text.match === "function") {
      matchResult = text.match(item);
    }
    if (matchResult) {
      text = text.replace(item, dynamicValuesCallback(matchResult, props));
    }
  }
  return text;
}

function getImageDetails(imageText) {
  // remove the opening and closing square brackets and any extra spaces around the main content
  imageText = imageText.substr(1, imageText.length - 2).trim();

  if (imageText === "page break") {
    return {
      type: "PAGE_BREAK",
    };
  }

  let caption = null;

  if (imageText.includes("caption=")) {
    caption = imageText.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    imageText = imageText.split(caption).join("");
  }

  let argumentsSpecifiedCount = 0;
  if (imageText.includes("align=")) {
    argumentsSpecifiedCount++;
  }
  if (imageText.includes("size=")) {
    argumentsSpecifiedCount++;
  }
  if (imageText.includes("caption=")) {
    argumentsSpecifiedCount++;
  }
  const imageParams = imageText.split(" ");
  let size = "50%";
  let align;

  let localKey = imageText;
  if (argumentsSpecifiedCount > 1) {
    localKey = imageParams.slice(0, -argumentsSpecifiedCount).join(" ");
  }

  imageParams.forEach((param) => {
    if (param.includes("size=")) {
      size = param.split("=")[1];
    } else if (param.includes("align=")) {
      align = param.split("=")[1];
    }
  });

  return {
    size,
    align,
    localKey,
    caption,
    type: getAttachmentTypeFromKey(localKey),
  };
}

export function displayDocumentOutputTextarea({
  nodes,
  style,
  pageSize,
  pagePadding,
  numberVariables,
  colorVariables,
  props,
}) {
  return nodes.map((node, i) => {
    let key = `element-${i}-${Math.random()}`;
    switch (node.type) {
      case "paragraph":
        let isEmpty = node.children.length <= 1 && !node.children[0]?.text;
        if (
          isEmpty &&
          // if before or after an attachment
          ((nodes[i + 1] && nodes[i + 1].type === "attachment") || (nodes[i - 1] && nodes[i - 1].type === "attachment"))
        ) {
          return null;
        }

        return (
          <Text key={key} style={style} widows={3} orphans={3}>
            {node.children.map((child) => {
              return (
                <TextNode
                  parent={node}
                  node={child}
                  style={style}
                  numberVariables={numberVariables}
                  colorVariables={colorVariables}
                  props={props}
                />
              );
            })}
          </Text>
        );

      case "attachment":
        // let innerPageHeight = pageSize.height - pagePadding.top - pagePadding.bottom;
        switch (node.attachment.type) {
          case "IMAGE":
            let imageStyle = {
              width: node.width,
            };
            let caption;

            if (node.caption && node.caption.trim()) {
              caption = node.caption.trim();
            }
            let viewStyle = {};
            if (node.align === "left") {
              viewStyle.alignItems = "flex-start";
            } else if (node.align === "center" || node.align === "middle") {
              viewStyle.alignItems = "center";
            } else if (node.align === "right") {
              viewStyle.alignItems = "flex-end";
            }
            const src = node.attachment.publicUrl || "broken-image";

            let captionFontSizeVariable = numberVariables?.find((variable) => variable.id === node.captionFontSize);
            let captionFontSizeNumber = captionFontSizeVariable ? parseFloat(captionFontSizeVariable.value) : 14;
            return (
              <>
                <View style={viewStyle} key={`image-${i}-${Math.random()}`}>
                  <Image src={src} style={imageStyle} />
                  {caption ? (
                    <Text
                      style={{
                        width: imageStyle.width,
                        textAlign: "center",
                        fontSize: captionFontSizeNumber,
                        fontFamily: style.fontFamily,
                        color: style.color,
                        marginTop: 5,
                      }}
                    >
                      {caption}
                    </Text>
                  ) : null}
                </View>
              </>
            );
          case "PDF":
            return displayPdfAttachment({
              key: node.attachment.key,
              label: `${node.attachment.localKey || node.attachment.key.split("/").pop()}`,
              hasBorders: false,
              pagesToExclude: [],
              wrapInPage: false,
              pageSize,
              pagePadding,
            });
          default:
            return null;
        }
      case "numbered-list":
        return (
          <View key={key} widows={3} orphans={3} style={{ marginLeft: (style.fontSize || 10) * 1.5 }}>
            {node.children.map((child, i) => {
              return (
                <View key={i} style={{ display: "flex", flexDirection: "row" }}>
                  <TextNode
                    parent={node}
                    node={{ ...child, text: `${i + 1}.` }}
                    style={{
                      ...style,
                      width: (style.fontSize || 10) * 1.5,
                      textAlign: "right",
                      marginRight: (style.fontSize || 10) / 2,
                    }}
                  />
                  ;
                  <View>
                    {child.children.map((innerChild, j) => {
                      return <TextNode parent={node} key={j} node={innerChild} style={style} />;
                    })}
                  </View>
                </View>
              );
            })}
          </View>
        );
      case "bulleted-list":
        return (
          <View key={key} widows={3} orphans={3} style={{ marginLeft: (style.fontSize || 10) * 1.5 }}>
            {node.children.map((child, i) => {
              return (
                <View key={i} style={{ display: "flex", flexDirection: "row" }}>
                  <TextNode
                    parent={node}
                    node={{ ...child, text: `${BULLET_POINT_CHARACTER}` }}
                    style={{
                      ...style,
                      fontFamily: "Helvetica",
                      width: (style.fontSize || 10) * 1.5,
                      textAlign: "right",
                      marginRight: (style.fontSize || 10) / 2,
                    }}
                  />

                  <View>
                    {child.children.map((innerChild, j) => {
                      return <TextNode parent={node} key={j} node={innerChild} style={style} />;
                    })}
                  </View>
                </View>
              );
            })}
          </View>
        );

      default:
        console.log("unknown node = ", node);
        return null;
    }
  });
}

function TextNode({ node, style, parent, numberVariables, colorVariables, props }) {
  let text = fillInDynamicInfo(node.text, props);
  return (
    <Text
      key={Math.random()}
      style={computeStyleForTextNode({
        style,
        node,
        parent,
        numberVariables,
        colorVariables,
      })}
    >
      {text || " "}
    </Text>
  );
}

function computeStyleForTextNode({ style, node, parent, numberVariables, colorVariables }) {
  let computedStyle = JSON.parse(JSON.stringify(style));
  const fontSize = getElementFontSize({ element: node, numberVariables });
  const color = getElementColor({ element: node, colorVariables });

  if (fontSize !== undefined) {
    computedStyle.fontSize = fontSize;
  }

  if (color !== undefined) {
    computedStyle.color = color;
  }

  if (node.bold || parent.bold) {
    computedStyle.fontWeight = "bold";
  }

  if (node.underline) {
    computedStyle.textDecoration = "underline";
  }

  if (parent.align) {
    computedStyle.textAlign = parent.align;
  }

  if (node.align) {
    computedStyle.textAlign = node.align;
  }

  if (node.superscript) {
    computedStyle.verticalAlign = "super";
    computedStyle.fontSize = computedStyle.fontSize * 0.7;
  }

  if (node.subscript) {
    computedStyle.verticalAlign = "sub";
    computedStyle.fontSize = computedStyle.fontSize * 0.7;
  }

  return computedStyle;
}

export function getElementFontSize({ element, numberVariables }) {
  let selectedVariable = numberVariables?.find((variable) => {
    return element[`font_size_${variable.id}`];
  });

  return selectedVariable?.value;
}

export function getElementColor({ element, colorVariables }) {
  let selectedVariable = colorVariables?.find((variable) => {
    return element[`color_${variable.id}`];
  });

  return selectedVariable?.value;
}

export function setReportPageMapping({ sourcePageGroupNumber, sourcePageGroupName, correspondingPreviewPageNumber }) {
  let existingMapping;
  if (global.isBrowser) {
    existingMapping = window.reportPageMapping[window.reportRenderCycle][sourcePageGroupNumber];
  } else {
    existingMapping = global.reportPageMapping[global.reportRenderCycle][sourcePageGroupNumber];
  }

  if (!existingMapping) {
    existingMapping = {
      sourcePageGroupNumber,
      sourcePageGroupName,
      correspondingPreviewPageNumbers: [],
    };
  }
  if (!existingMapping.correspondingPreviewPageNumbers.includes(correspondingPreviewPageNumber)) {
    existingMapping.correspondingPreviewPageNumbers.push(correspondingPreviewPageNumber);
  }

  if (global.isBrowser) {
    window.reportPageMapping[window.reportRenderCycle][sourcePageGroupNumber] = existingMapping;
  } else {
    global.reportPageMapping[global.reportRenderCycle][sourcePageGroupNumber] = existingMapping;
  }
}

export function initialiseLambdaPdfInserts() {
  if (global.isBrowser) {
    if (!window.lambdaPdfInserts) {
      window.lambdaPdfInserts = {};
    }
    if (!window.reportPageMapping) {
      window.reportPageMapping = {};
    }
    window.reportRenderCycle = window.reportRenderCycle ? window.reportRenderCycle + 1 : 1;
    window.lambdaPdfInserts[window.reportRenderCycle] = {
      control: "render cycle has started",
    };
    window.reportPageMapping[window.reportRenderCycle] = {
      control: "render cycle has started",
    };
  } else {
    if (!global.lambdaPdfInserts) {
      global.lambdaPdfInserts = {};
    }
    if (!global.reportPageMapping) {
      global.reportPageMapping = {};
    }
    global.reportRenderCycle = global.reportRenderCycle ? global.reportRenderCycle + 1 : 1;
    global.lambdaPdfInserts[global.reportRenderCycle] = {
      control: "render cycle has started",
    };
    global.reportPageMapping[global.reportRenderCycle] = {
      control: "render cycle has started",
    };
  }
}

export function processLambdaPdfInserts(lambdaPdfInserts) {
  let result = [];
  for (let pageNumber in lambdaPdfInserts) {
    // the 'control' element is there to signify the start of the sequence, so we ignore it
    if (pageNumber === "control") {
      continue;
    }

    result.push({
      pageNumber: parseInt(pageNumber),
      ...lambdaPdfInserts[pageNumber],
    });
  }
  return result;
}

export function processReportPageMapping(reportPageMapping) {
  let result = [];
  // console.log(reportPageMapping);
  // debugger;
  for (let pageNumber in reportPageMapping) {
    // the 'control' element is there to signify the start of the sequence, so we ignore it
    if (pageNumber === "control") {
      continue;
    }

    result.push({
      ...reportPageMapping[pageNumber],
    });
  }

  return result;
}
