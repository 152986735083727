import { useGetSetState } from "react-use";
import { Table, Button } from "antd";
import { Link, useLocation, withRouter } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";

import { isAuthorised } from "common/permissions";
import { getReadableStatus, processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import { getTaskIdsAndTaskRevisionIdsForRequestForm } from "common/requestHelpers";

import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
// import InfoItem from "InfoItem/InfoItem";
import RequestActivityItemDetails from "pages/RequestDetailsPage/RequestActivityItemDetails/RequestActivityItemDetails";
import ReviewPrimaryStatus from "ReviewPage/ReviewPrimaryStatus/ReviewPrimaryStatus";
import ReviewSecondaryStatus from "ReviewPage/ReviewSecondaryStatus/ReviewSecondaryStatus";
import AddRequestFormToTaskRevisionButton from "pages/RequestDetailsPage/AddRequestFormToTaskRevisionButton/AddRequestFormToTaskRevisionButton";

import "./RequestActivity.scss";

type Props = {
  request: any;
  activityItems: any[];
  users: any[];
  organisationDetails: any;
  apiUser: any;
  history: any;
};

export function RequestActivity({ request, organisationDetails, activityItems, users, apiUser, history }: Props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const preselectedActivityItemIdInUrl = queryParams.get("activityItem"); // replace 'myParam' with your parameter name

  const [getState, setState] = useGetSetState({
    selectedActivityItem: preselectedActivityItemIdInUrl
      ? activityItems.find((x) => x.id === preselectedActivityItemIdInUrl)
      : undefined,
  });

  const { selectedActivityItem } = getState();

  const columns = [
    {
      title: "Recorded at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: (createdAt: string) => {
        return <>{moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}</>;
      },
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      align: "left",
      width: 200,
      render: (author: string) => {
        let authorDetails = users.find((x) => x.id === author);
        if (authorDetails) {
          return <Avatar user={authorDetails} showLabel />;
        } else {
          return author;
        }
      },
    },
    {
      title: "Event",
      dataIndex: "content",
      key: "type",
      align: "left",
      render: (_, event: any) => {
        let contentTypeReadable = getReadableStatus(event.content?.type);
        let mainContentToDisplay: any = `${contentTypeReadable.substring(0, 1).toUpperCase()}${contentTypeReadable
          .substring(1)
          .toLowerCase()}`;
        let extraContentToDisplay: any = null;
        let extraContentToDisplayParts: any[] = [];

        switch (event.content?.type) {
          case "ASSIGNEE_CHANGED":
            let assigneeDetails = users.find((x) => x.id === event.content?.newAssigneeId);
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} assigned to{" "}
                {assigneeDetails ? <Avatar user={assigneeDetails} showLabel /> : event.content?.newAssigneeId}
              </>
            );

            break;
          case "REQUEST_FORM_CREATED":
            mainContentToDisplay = `Form added`;

            if (event.content?.formName) {
              extraContentToDisplayParts.push(<>: "{event.content?.formName}"</>);
            }
            if (event.content?.requestedForDate) {
              extraContentToDisplayParts.push(
                <>, desired delivery date: {moment(event.content?.requestedForDate).format("DD-MM-YYYY")}</>
              );
            }
            if (event.content?.requestedForDateReason) {
              extraContentToDisplayParts.push(
                <>
                  <br /> (reason for this date: "{event.content?.requestedForDateReason}")
                </>
              );
            }
            if (event.content?.priorityId) {
              let priorityLevelDetails = organisationDetails.settings?.task?.priorities?.find(
                (x) => x.id === event.content?.priorityId
              );
              if (priorityLevelDetails) {
                extraContentToDisplayParts.push(
                  <>
                    <br /> priority: {priorityLevelDetails.name}
                  </>
                );
              } else {
                extraContentToDisplayParts.push(
                  <>
                    <br /> priority: (unknown)
                  </>
                );
              }
            }
            extraContentToDisplay = <>{extraContentToDisplayParts}</>;

            break;
          case "REQUEST_FORM_DELETED":
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} form <b>deleted</b>
              </>
            );

            extraContentToDisplayParts.push(<>: "{event.content?.formName}"</>);
            if (event.content?.reason) {
              extraContentToDisplayParts.push(
                <>
                  <br /> Reason: "{event.content?.reason}"
                </>
              );
            }
            extraContentToDisplay = <>{extraContentToDisplayParts}</>;
            break;
          case "REQUEST_FORM_ADDED_TO_TASK_REVISION":
            let reviewRelatedContent: any = null;
            if (event.content.isLinkingReview) {
              // reviewRelatedContent = JSON.stringify(event.content, null, 2);
            }
            mainContentToDisplay = (
              <>
                "{event.content?.formName}" added to revision {event.content?.taskRevisionName} of{" "}
                {getSimpleLabel("task")} {processIdForDisplay(event.content?.taskId)} ({event.content?.projectTitle} -{" "}
                {event.content?.taskTitle}){reviewRelatedContent}
                <br />
                {isAuthorised(["TASK_DETAILS.VIEW"]) && isAuthorised(["REQUESTS.EXECUTE"]) && (
                  <Link to={`/tasks/${event.content?.taskId}`}>
                    <Button type="primary">Open {getSimpleLabel("task")}</Button>
                  </Link>
                )}
              </>
            );
            break;
          case "REQUEST_FORM_REMOVED_FROM_TASK_REVISION":
            mainContentToDisplay = (
              <>
                "{event.content?.formName}" removed from revision {event.content?.taskRevisionName} of{" "}
                {getSimpleLabel("task")} {processIdForDisplay(event.content?.taskId)} ({event.content?.projectTitle} -{" "}
                {event.content?.taskTitle})
              </>
            );
            break;
          case "INFORMATION_SUBMITTED":
          case "MORE_INFORMATION_REQUESTED":
            if (event.content?.reopenedByRequester) {
              mainContentToDisplay = (
                <>
                  {getSimpleLabel("Request")} form "{event.content?.formName}" <b>re-opened</b> by the requester
                </>
              );
            } else if (event.content?.formName || event.content?.message) {
              extraContentToDisplay = (
                <>
                  {" "}
                  for form "{event.content?.formName}", with message: <br />
                  <b>{event.content?.message}</b>
                </>
              );
            } else {
              mainContentToDisplay = (
                <>
                  Status changed by the engineer: <b>{mainContentToDisplay}</b>
                </>
              );
            }
            break;
          case "REJECTED":
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} <b>rejected</b> by the engineer
              </>
            );
            extraContentToDisplay = (
              <>
                <br /> Reason: "{event.content?.reason}"
              </>
            );
            break;

          case "ON_HOLD":
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} marked as <b>on hold</b> by the engineer
              </>
            );
            extraContentToDisplay = (
              <>
                <br /> Reason: "{event.content?.reason}"
              </>
            );
            break;
          case "CANCELLED":
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} <b>cancelled</b> by the engineer
              </>
            );
            extraContentToDisplay = (
              <>
                <br /> Reason: "{event.content?.reason}"
              </>
            );
            break;
          case "FINISHED":
            mainContentToDisplay = (
              <>
                {getSimpleLabel("Request")} marked as <b>finished</b>
              </>
            );

            break;
          case "REVIEW_STATUS_CHANGE":
            const taskIdsAndRevisionIds = getTaskIdsAndTaskRevisionIdsForRequestForm({
              activityItems,
              formFileId: event.content.taskFilesSentActivityItemId,
            });
            let taskFilesSentActivityItem = activityItems.find(
              (x) => x.id === event.content.taskFilesSentActivityItemId
            );
            let activityItemForReviewLinkedToTaskRevision = activityItems.find(
              (x) =>
                x.content?.formFileId === event.content.taskFilesSentActivityItemId &&
                x.content?.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION"
            );
            mainContentToDisplay = (
              <>
                Review status changed to:
                <br />
                <ReviewPrimaryStatus taskRevision={event.content} />
                <ReviewSecondaryStatus taskRevision={event.content} />
                {isAuthorised(["REQUESTS.EXECUTE"]) && !activityItemForReviewLinkedToTaskRevision && (
                  <>
                    <br />
                    <div style={{ marginTop: "0.5rem" }}>
                      <AddRequestFormToTaskRevisionButton
                        users={users}
                        key="add-form-to-task-revision"
                        request={request}
                        apiUser={apiUser}
                        formFileId={event.content.taskFilesSentActivityItemId}
                        formName={"External review comments"}
                        taskIdsAndRevisionIds={taskIdsAndRevisionIds}
                        formCreationActivityItem={event.id}
                        isLinkingReview={true}
                        predefinedTaskId={taskFilesSentActivityItem?.content?.taskId}
                        shouldComputeTaskFields={false}
                      />
                    </div>
                  </>
                )}
                <br />
                <Button
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => {
                    history.push(`/requests/${request.id}/review/${event.content.taskRevisionId}`);
                  }}
                  type="primary"
                >
                  Open review
                </Button>
              </>
            );
            break;
          case "TASK_FILES_SENT":
            let reviewStatusElement: any = null;
            if (true) {
            }
            mainContentToDisplay = (
              <>
                {reviewStatusElement}
                Design package issued
                <br />
                {event.content.formName}
                <br />
                {getSimpleLabel("Task")} {processIdForDisplay(event.content?.taskId)} ({event.content?.projectTitle} -{" "}
                {event.content?.taskTitle})
                <br />
                {getSimpleLabel("Task revision")} {event.content?.taskRevisionName}
                <br />
                {isAuthorised(["REQUESTS.EXECUTE"]) && (
                  <div className="activity-item-email-details">
                    To: {event.content.to}
                    {event.content.cc && event.content.cc.length > 0 ? (
                      <>
                        <br />
                        CC: {event.content.cc}
                      </>
                    ) : null}
                    {event.content.bcc && event.content.bcc.length > 0 ? (
                      <>
                        <br />
                        BCC: {event.content.bcc}
                      </>
                    ) : null}
                  </div>
                )}
                <Button icon={<EyeOutlined />} type="primary" style={{ marginTop: "0.5rem" }}>
                  See files
                </Button>
              </>
            );
            break;

          default:
            break;
        }

        return (
          <>
            {mainContentToDisplay}
            {extraContentToDisplay}
          </>
        );
      },
    },

    // {
    //   title: "Event ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
  ];
  return (
    <Card title="Activity log" className="request-activity">
      <Table
        pagination={{ pageSize: 100, hideOnSinglePage: true }}
        columns={columns}
        dataSource={[...activityItems].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))}
        rowKey={(record) => record.id}
        onRow={(row, rowIndex) => {
          return {
            onClick: () => {
              setState({ selectedActivityItem: row });
            },
          };
        }}
      />
      {selectedActivityItem && (
        <RequestActivityItemDetails
          activityItem={selectedActivityItem}
          users={users}
          request={request}
          apiUser={apiUser}
          organisationDetails={organisationDetails}
          onClose={() => {
            setState({ selectedActivityItem: undefined });
          }}
        />
      )}
    </Card>
  );
}

export default withRouter(RequestActivity);
