import { useEffect } from "react";
import { Tooltip, message, Button, Tree, Typography } from "antd";
import cx from "classnames";
import {
  QuestionCircleOutlined,
  FilePdfOutlined,
  ReloadOutlined,
  EyeOutlined,
  EyeInvisibleFilled,
  LeftOutlined,
  StarOutlined,
  QrcodeOutlined,
  DatabaseOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import cookie from "js-cookie";
import { useGetSetState } from "react-use";

import {
  ImageIcon,
  ImageIconStatic,
  SignatureIcon,
  CircleIcon,
  RectangleIcon,
  TextIcon,
  PageIcon,
  PaperclipIcon,
  PagesIcon,
  ListIcon2,
} from "common/icons";
import { getSimpleLabel } from "common/labels";
import { COOKIE_NAME_TEMPLATE_EDITOR_HIERARCHY_WIDTH } from "common/constants";

import Input from "Input/Input";

import "./HierarchyPanel.scss";

const INITIAL_WIDTH = 300;

export default function HierarchyPanel({
  fileTypeDetails,
  isPreviewVisible,
  defaultExpandedKeys,
  outputTemplate,
  selectedObjects = [],
  setSelectedObjects,
  setSelectedObjectForEditingChildren,
  updateObject,
  changeObjectOrder,
  moveChildToNewParent,
}) {
  const [getState, setState] = useGetSetState({
    isDraggingWidth: false,
    dragStartX: undefined,
    dragStartWidth: parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_HIERARCHY_WIDTH)) || INITIAL_WIDTH,
    panelWidth: parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_HIERARCHY_WIDTH)) || INITIAL_WIDTH,
    selectedObjectForEditing: undefined,
  });

  useEffect(() => {
    window.addEventListener("mouseup", onWindowMouseUp);
    window.addEventListener("mousemove", onWindowMouseMove);

    return () => {
      window.removeEventListener("mouseup", onWindowMouseUp);
      window.removeEventListener("mousemove", onWindowMouseMove);
    };
  }, []);

  function onWindowMouseUp() {
    setState({
      selectedObjectForEditing: undefined,
      isDraggingWidth: false,
    });
  }

  function onWindowMouseMove(e) {
    const { isDraggingWidth, dragStartX, dragStartWidth } = getState();
    if (isDraggingWidth) {
      let deltaX = e.screenX - dragStartX;
      let newPanelWidth = Math.max(dragStartWidth + deltaX, 10);
      cookie.set(COOKIE_NAME_TEMPLATE_EDITOR_HIERARCHY_WIDTH, newPanelWidth, {
        expires: 99999,
      });

      setState({
        panelWidth: newPanelWidth,
      });
    }
  }

  function buildHierarchyKey(object) {
    let key = object.custom_id;
    let parent = object.group;

    while (parent && parent.custom_id) {
      key = `${parent.custom_id}-${parent.custom_name}-${key}`;
      parent = parent.group;
    }

    return key;
  }

  function onSelect(_, { selectedNodes }) {
    const firstSelectedNode = selectedNodes[0];

    if (firstSelectedNode.key === "document") {
      setSelectedObjects();
      setSelectedObjectForEditingChildren();
    } else {
      if (firstSelectedNode?.object) {
        setSelectedObjects([firstSelectedNode.object]);
        setSelectedObjectForEditingChildren(firstSelectedNode.object);
      }
    }
  }

  function onObjectTitleClick(e, object, isRoot) {
    const { selectedObjectForEditing } = getState();
    if (isRoot) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSelect(null, { selectedNodes: [{ object }] });
    }
    if (selectedObjectForEditing?.custom_id === object.custom_id) {
      e.stopPropagation();
    }
  }

  function convertCanvasLevelToTreeData(objects) {
    if (!objects) {
      return [];
    }
    const { selectedObjectForEditing } = getState();
    let eventedObjects = objects;

    return eventedObjects.map((object) => {
      let title = object.custom_name;
      if (title === undefined) {
        title = object.type;
      }
      let primaryIcon = null;
      switch (object.type) {
        case "text":
          primaryIcon = <TextIcon />;
          break;
        case "image":
          primaryIcon = <ImageIconStatic />;
          break;
        case "rect":
          primaryIcon = <RectangleIcon />;
          break;
        case "ellipse":
          primaryIcon = <CircleIcon />;
          break;
        default:
          break;
      }

      switch (object.custom_type) {
        case "chapter":
          primaryIcon = <PagesIcon />;
          break;
        case "page":
          primaryIcon = <PageIcon />;
          break;
        case "section":
          primaryIcon = <ListIcon2 />;
          break;
        case "image_container":
          primaryIcon = <ImageIcon />;
          break;
        case "signature":
          primaryIcon = <SignatureIcon />;
          break;
        case "qr-code":
          primaryIcon = <QrcodeOutlined />;
          break;
        case "dynamic_file":
          primaryIcon = <PaperclipIcon />;
          break;
        case "component":
          primaryIcon = <StarOutlined />;
          break;
        default:
          break;
      }

      let conditionalDisplayIcon = null;
      let repeatIcon = null;
      let dataFieldsIcon = null;
      let pdfIcon = null;
      let liveCopyIcon = null;

      if (object.custom_usesMultipleConditions || object.custom_usesConditionalDisplay) {
        conditionalDisplayIcon = (
          <Tooltip title="This element has conditional display enabled">
            <QuestionCircleOutlined />
          </Tooltip>
        );
      }

      if (object.custom_repeatForDataSource && object.custom_repeatFor) {
        repeatIcon = (
          <Tooltip title="This element is repeated">
            <ReloadOutlined />
          </Tooltip>
        );
      }

      if (object.custom_dataFields && object.custom_dataFields.length > 0) {
        dataFieldsIcon = (
          <Tooltip title="This element has data fields">
            <DatabaseOutlined />
          </Tooltip>
        );
      }

      if (object.custom_chapterLinkToButton) {
        pdfIcon = (
          <Tooltip title="This element is linked to a form button and will not be included in the main document. It is used to generate a completely separate PDF when that button is clicked.">
            <FilePdfOutlined />
          </Tooltip>
        );
      }

      if (object.isLiveCopyOf) {
        liveCopyIcon = (
          <Tooltip title="This element is a live copy of another element. That means whenever the original element updates, this element will also update">
            <CopyOutlined />
          </Tooltip>
        );
      }

      let visibilityIcon = object.visible ? <EyeOutlined /> : <EyeInvisibleFilled />;

      let isRoot = object.custom_type === "document";

      let key = buildHierarchyKey(object);

      let result = {
        title: (
          <div
            className={cx("hierarchy-item-label", { "root-element": isRoot })}
            onDoubleClick={() => (isRoot ? undefined : setState({ selectedObjectForEditing: object }))}
            onMouseUp={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              onObjectTitleClick(e, object, isRoot);
            }}
          >
            <div className="extra-item-icons">
              {conditionalDisplayIcon}
              {repeatIcon}
              {dataFieldsIcon}
              {pdfIcon}
              {liveCopyIcon}
            </div>
            <Input
              showBorder={selectedObjectForEditing?.custom_id === object.custom_id}
              disabled={!selectedObjectForEditing || selectedObjectForEditing?.custom_id !== object.custom_id}
              defaultValue={title}
              fireOnChangeWithoutBlur
              onEnter={() => {
                setState({ selectedObjectForEditing: undefined });
              }}
              onChange={(value) => {
                updateObject({
                  objectId: object.custom_id,
                  fields: {
                    custom_name: value,
                  },
                });
              }}
            />

            {!isRoot && (
              <div
                className="visibility-button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateObject({
                    objectId: object.custom_id,
                    fields: {
                      visible: !object.visible,
                    },
                  });
                }}
              >
                {visibilityIcon}
              </div>
            )}
          </div>
        ),
        key,
        children: object.type === "group" ? convertCanvasLevelToTreeData(object.objects) : undefined,
        isLeaf: object.type !== "group",
        icon: primaryIcon,
        object,
      };

      return result;
    });
  }

  function onDrop(info) {
    let dropTarget = info.node.object;
    const dragTarget = info.dragNode.object;
    const dropPosition = info.dropPosition;
    const dragPosition = info.dragNode.pos.split("-").slice(-1)[0];

    if (!dropTarget || !dragTarget) {
      return;
    }

    if (dropTarget.custom_type === "document") {
      dropTarget = outputTemplate;
    } else if (dropTarget.custom_type === "page") {
      if (info.dropToGap) {
        dropTarget = outputTemplate;
      }
    } else {
      if (info.dropToGap) {
        dropTarget = dropTarget.group;
      }
    }

    if (dropTarget.custom_id === "root") {
      if (
        dragTarget.custom_type === "page" ||
        dragTarget.custom_type === "chapter" ||
        !fileTypeDetails.isDocumentTemplate
      )
        changeObjectOrder({
          childId: dragTarget.custom_id,
          newPosition: dropPosition,
        });
    } else if (dropTarget.custom_id === dragTarget.group?.custom_id) {
      let newPosition = dropPosition + (dropPosition > dragPosition ? 0 : 1);
      changeObjectOrder({
        childId: dragTarget.custom_id,
        newPosition,
      });
    } else {
      if (dropTarget.custom_type === "chapter" && !["chapter", "page"].includes(dragTarget.custom_type)) {
        message.error("You can only move chapters or pages into a chapter");
        return;
      }
      if (dragTarget.custom_type === "section") {
        if (dropTarget.custom_type === "page" || dropTarget.custom_type === "section") {
          moveChildToNewParent({
            childId: dragTarget.custom_id,
            newParentId: dropTarget.custom_id,
            newPosition: dropPosition,
          });
        }
      } else {
        if (dropTarget.custom_type === "page") {
          message.error("You cannot move an object directly into a page. It needs to be part of a section");
        } else if (dropTarget.custom_type === "section") {
          moveChildToNewParent({
            childId: dragTarget.custom_id,
            newParentId: dropTarget.custom_id,
            newPosition: dropPosition,
          });
        }
      }
    }
  }

  if (!outputTemplate) {
    return null;
  }

  let treeChildren;

  let topLevelObjects = [
    {
      custom_type: "document",
      custom_name: "Document",
      custom_id: "document",
      type: "group",
      visible: true,
      evented: true,
      hasControls: false,
      objects: outputTemplate.objects,
    },
  ];
  treeChildren = convertCanvasLevelToTreeData(topLevelObjects);

  let selectedKeys = selectedObjects.map((element) => buildHierarchyKey(element));

  return (
    <div
      className={cx("hierarchy-panel panel", {
        "is-preview-visible": isPreviewVisible,
      })}
      style={{ width: getState().panelWidth }}
    >
      <div className="section">
        <Typography.Text className="title" style={{ textAlign: "center" }}>
          {getSimpleLabel(fileTypeDetails.label)} template
        </Typography.Text>
      </div>
      <Link style={{ display: "block", width: "100%" }} to={`/organisation/templates`} className="back-button">
        <Button style={{ marginBottom: "1rem", display: "block", width: "100%" }} type="dark" icon={<LeftOutlined />}>
          Back to templates
        </Button>
      </Link>
      <Typography.Text className="panel-title">Object hierarchy</Typography.Text>
      <Tree.DirectoryTree
        defaultExpandedKeys={[...(defaultExpandedKeys || []), "document"]}
        className="hierarchy-tree"
        draggable
        blockNode
        onDrop={onDrop}
        treeData={treeChildren}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        multiple
      />
      <div
        className="border-drag"
        onMouseDown={(e) => {
          const { panelWidth } = getState();
          setState({
            isDraggingWidth: true,
            dragStartX: e.screenX,
            dragStartWidth: panelWidth,
          });
        }}
      />
    </div>
  );
}
