import { useState } from "react";
import { Storage } from "aws-amplify";
import { Form, Modal, Button, notification, Typography } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import {
  copyS3MainFile,
  createFileVersionInApi,
  createFileWithSheets,
  createTaskInApi,
  isFileOpen,
  pointSheetsToLatestFileVersion,
  showFileIsOpenModal,
  useForceUpdate,
} from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { FILE_TYPE_EXTENSIONS } from "common/constants";
import { getLatestFileVersion, getLatestRevision } from "common/shared";
import { getSimpleLabel } from "common/labels";

import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import TaskPicker from "TaskPicker/TaskPicker";

import "./CreateExternalReferenceModal.scss";

export function CreateExternalReferenceModal({
  onClose,
  task,
  apiUser,
  visible,
  file,
  tasks,
  projects,
  sprints,
  clients,
  organisationDetails,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const forceUpdate = useForceUpdate();
  const latestFileVersion = getLatestFileVersion(file);
  const externalReferences = latestFileVersion.externalReferences || [];
  const taskRevision = task.revisions.items[task.revisions.items.length - 1];

  function findTemplate(templates, fileType) {
    let templatesForFileType = templates.items.filter((template) => template.type === fileType && template.isLive);
    if (!templatesForFileType || templatesForFileType.length === 0) {
      templatesForFileType = templates.items.filter((template) => template.type === fileType && !template.isDeprecated);
    }

    let defaultTemplate = templatesForFileType.find((template) => template.isDefault);
    if (!defaultTemplate) {
      defaultTemplate = templatesForFileType && templatesForFileType[0];
    }

    return defaultTemplate;
  }

  async function addXrefFileToTask({ name, task, taskRevision, fileType, templateDetails }) {
    const createFileParameters = {
      name,
      fileType,
      sheetCount: 1,
      taskRevision,
      apiUser,
      task,
      taskInitials: task.initials,
      doPublish: false,
      isExternalReference: true,
      templateId: templateDetails.id,
      templateVersionNumber: templateDetails.currentVersionNumber,
      status: "",
      extension: "",
      excludeFromRegister: true,
      sheetRevisionDescription: "",
      sheetRevisionName: "A",
    };

    if (Object.keys(FILE_TYPE_EXTENSIONS).includes(fileType)) {
      createFileParameters.extension = templateDetails.key
        ? templateDetails.key.split(".").slice(-1)[0]
        : FILE_TYPE_EXTENSIONS[fileType][0];
    } else {
      throw new Error(`Unknown file type: ${fileType}`);
    }

    const file = await createFileWithSheets(createFileParameters);

    return file;
  }

  async function onFileInputChange(e) {
    const fileToUpload = e.target.files[0];
    if (!fileToUpload.name.includes(".")) {
      Modal.error({
        title: "Invalid file",
        content: "The file you are trying to upload does not have an extension.",
      });
      return;
    }
    const fileExtension = fileToUpload.name.split(".").slice(-1)[0];
    const acceptedExtensions = FILE_TYPE_EXTENSIONS[file.type];

    if (!acceptedExtensions.includes(fileExtension)) {
      Modal.error({
        title: "Wrong file type",
        content: `File extension must be: ${acceptedExtensions.join(
          ", "
        )}. The file you are trying to upload has the extension: ${fileExtension}`,
      });

      return;
    }

    setIsLoading(true);
    const backlog = sprints[sprints.length - 1];
    console.log("fileToUpload = ", fileToUpload);
    const project = projects.find((x) => x.id === task.projectId);
    const client = clients.find((x) => x.id === project.clientId);
    const newlyCreatedTask = await createTaskInApi({
      apiUser,
      catLevel: 0,
      includedFileTypes: [],
      initials: "REF",
      project,
      clientDetails: client,
      projectId: project.id,
      sprintId: backlog.id,
      status: "",
      title: fileToUpload.name,
      isExternalReference: true,
      projects,
    });

    const xRefTaskDetails = (
      await callGraphQLSimple({
        message: `Could not retrieve ${getSimpleLabel("task")} details`,
        queryName: "getTaskSimple",
        variables: {
          id: newlyCreatedTask.id,
        },
      })
    ).data.getTask;

    const templateDetails = findTemplate(organisationDetails.templates, file.type);
    if (!templateDetails) {
      notification.error({
        message: <Typography.Text>Could not find a template for file type: {file.type}</Typography.Text>,
        duration: 0,
      });
      return;
    }
    const nameWithoutExtension = fileToUpload.name.replace(`.${fileExtension}`, "");
    const newFile = await addXrefFileToTask({
      name: nameWithoutExtension,
      task: xRefTaskDetails,
      taskRevision: xRefTaskDetails.revisions.items[0],
      fileType: file.type,
      templateDetails: templateDetails,
    });

    const newFileVersionKey = newFile.versions.items[0].key.replace("public/", "");

    await Storage.put(newFileVersionKey, fileToUpload, {
      progressCallback(progress) {
        setUploadProgress(progress);
      },
    });
    setUploadProgress();

    onSubmit({ linkedTask: xRefTaskDetails.id });
  }

  async function onSubmit({ linkedTask }) {
    const fileIsOpen = await isFileOpen({ task, file });
    if (fileIsOpen) {
      showFileIsOpenModal(file);
      return;
    }

    setIsLoading(true);

    try {
      const linkedTaskData = (
        await callGraphQLSimple({
          message: `Could not retrieve ${getSimpleLabel("task")} details`,
          queryName: "getTaskSimple",
          variables: {
            id: linkedTask,
          },
        })
      ).data.getTask;

      const linkedTaskRevision = getLatestRevision(linkedTaskData);

      const fileOfSameType = linkedTaskRevision.files.items.find((x) => x.type === file.type);

      if (!fileOfSameType) {
        alert(`Latest revision for specified ${getSimpleLabel("task")} does not have a file of the same type`);
        setIsLoading(false);
        return;
      }

      const linkedFileData = (
        await callGraphQLSimple({
          message: "Could not retrieve file details",
          queryName: "getFile",
          variables: {
            id: fileOfSameType.id,
          },
        })
      ).data.getFile;

      const linkedFileVersion = getLatestFileVersion(linkedFileData);

      const oldVersionNumber = latestFileVersion.versionNumber;
      const newVersionNumber = oldVersionNumber + 1;

      const newFileVersion = (
        await createFileVersionInApi({
          apiUser,
          file,
          task,
          taskRevision,
          versionNumber: newVersionNumber,
          processingStatus: "IN_QUEUE",
        })
      ).data.createFileVersion;

      const updatedFileWithContents = (
        await callGraphQLSimple({
          message: "Failed to retrieve file",
          queryName: "getFile",
          variables: {
            id: file.id,
          },
        })
      ).data.getFile;

      await pointSheetsToLatestFileVersion({
        apiUser,
        task,
        file: updatedFileWithContents,
        taskRevision,
      });

      const newExternalReference = {
        id: String(Math.floor(Math.random() * 100000)),
        fileVersionId: linkedFileVersion.id,
        taskId: linkedTaskData.id,
        taskRevisionId: linkedTaskRevision.id,
        taskRevisionName: linkedTaskRevision.name,
        fileId: linkedFileData.id,
        versionNumber: linkedFileVersion.versionNumber,
        key: linkedFileVersion.key,
      };

      await callGraphQLSimple({
        message: "Could not create external reference",
        queryName: "updateFileVersion",
        variables: {
          input: {
            id: newFileVersion.id,
            externalReferences: [...externalReferences, newExternalReference],
          },
        },
      });

      await copyS3MainFile({
        task,
        apiUser,
        taskRevision,
        file: updatedFileWithContents,
        oldVersionNumber,
        newVersionNumber,
        extension: updatedFileWithContents.extension,
        openConfirmationModal: false,
        doPublish: false,
        oldFileVersion: latestFileVersion,
        newFileVersion,
      });

      await callRest({
        route: "/sendToConsumer",
        method: "POST",
        body: {
          operation: "ATTACH_EXTERNAL_REFERENCES",
          key: newFileVersion.key,
          publishedKey: newFileVersion.exports[0].rawKey,
          annotatedKey: newFileVersion.exports[0].key,
          projectId: task.project.id,
          taskId: task.id,
          organisation: task.organisation,
          fileId: file.id,
          taskRevisionId: taskRevision.id,
          fileVersionId: newFileVersion.id,
          versionNumber: newFileVersion.versionNumber,
          executable: file.type,
          taskRevisionName: taskRevision.name,
          customId: newFileVersion.customId,
          externalReferences: [newExternalReference],
        },
      });

      await callGraphQLSimple({
        message: "Could not update file",
        queryName: "updateFile",
        variables: {
          input: {
            id: file.id,
            randomNumber: Math.floor(Math.random() * 1000000),
          },
        },
      });

      form.resetFields();
      setIsLoading(false);
      onClose();
    } catch (e) {
      console.log(e);
      notification.error({
        message: (
          <>
            <Typography.Text>Could not create external reference</Typography.Text>
            <br />
            <Typography.Text>Reason: {e.message || JSON.stringify(e)}</Typography.Text>
          </>
        ),
        duration: 0,
      });
      setIsLoading(false);
    }
  }

  function checkLinkedTask(_, selectedLinkedTaskId) {
    return new Promise((resolve, reject) => {
      console.log("checkLinkedTask()");
      if (!selectedLinkedTaskId) {
        return reject("");
      }
      const parts = selectedLinkedTaskId.split("-");
      if (parts.length !== 3) {
        return reject("");
      }

      return resolve();
    });
  }

  function checkDuplicate(_, selectedLinkedTaskId) {
    return new Promise((resolve, reject) => {
      console.log(
        "checkDuplicate() selectedLinkedTaskId = ",
        selectedLinkedTaskId,
        "externalReferences = ",
        externalReferences
      );
      const referenceAlreadyExists = externalReferences.find((x) => x.taskId === selectedLinkedTaskId);

      if (referenceAlreadyExists) {
        return reject("");
      }

      return resolve();
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const externalRefsInProject = tasks.filter(
    (x) => x.isExternalReference && x.projectId === task.projectId && x.id !== task.id
  );

  let submitLabel = "Submit";
  if (isLoading) {
    submitLabel = "Loading...";
    if (uploadProgress) {
      submitLabel = `Uploading... ${
        uploadProgress ? `${Math.floor((uploadProgress.loaded / uploadProgress.total) * 100)}%` : ""
      }`;
    }
  }

  return (
    <Modal
      maskClosable={false}
      title="Add Xref"
      visible={visible}
      onOk={onSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="create-external-reference-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item label="Upload a file" name="uploadedFile">
          <>
            <Button
              type="dark"
              disabled={isLoading}
              onClick={() => {
                document.querySelector("#upload-external-reference-file").click();
              }}
            >
              <CloudUploadOutlined />
              <span>Upload</span>
            </Button>

            <input
              id="upload-external-reference-file"
              style={{ display: "none" }}
              type="file"
              onChange={onFileInputChange}
            />
          </>
        </Form.Item>
        <Form.Item
          label="Or choose an Xref from this project"
          name="linkedTask"
          className="linked-task"
          rules={[
            {
              required: true,
              message: "You must specify a task",
              validator: checkLinkedTask,
            },
            {
              required: true,
              message: "There is already an external reference attached to that task",
              validator: checkDuplicate,
            },
          ]}
        >
          <TaskPicker
            includeProjectName={false}
            includeTaskId={false}
            tasks={externalRefsInProject}
            value={form.getFieldValue("linkedTask")}
            onSearch={() => {
              form.setFieldsValue({ linkedTask: "" });
              forceUpdate();
            }}
            onChange={(linkedTask) => {
              form.setFieldsValue({ linkedTask });
              forceUpdate();
            }}
          />

          {/* <Button type="primary" className="create-task" onClick={() => setIsUploadModalVisible(true)}>
                <PlusCircleOutlined /> Create new
              </Button> */}
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {submitLabel}
          </Button>
        </div>
      </Form>
      <br />
      {isCreateTaskModalVisible && (
        <CreateTaskModal
          onClose={() => setIsCreateTaskModalVisible(false)}
          apiUser={apiUser}
          onSave={(task) => {
            form.setFieldsValue({ linkedTask: task.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateExternalReferenceModal,
  subscriptions: ["tasks", "projects", "sprints", "clients"],
});
