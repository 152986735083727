import { useState } from "react";
import { Button, Timeline, Modal, Tag, message } from "antd";
import moment from "moment";
import cx from "classnames";
import { Link } from "react-router-dom";

import { PlusCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { REVIEW_SECONDARY_STATUS_READABLE, REVIEW_STATUS_READABLE } from "common/constants";
import { isAuthorised } from "common/permissions";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";
import { getSimpleLabel } from "common/labels";

import CreateSheetRevisionModal from "CreateSheetRevisionModal/CreateSheetRevisionModal";
import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";
import TaskRevisionItem from "./TaskRevisionItem/TaskRevisionItem";
import Input from "Input/Input";

import "./TaskRevisions.scss";

export function TaskRevisions({
  apiUser,
  user,
  users,
  task,
  openCreateTaskRevisionModal,
  history,
  linkedTasksDetails,
  organisationDetails,
  windowWidth,
  activityItemsByRequest,
}) {
  const [isCreateSheetRevisionModalOpen, setIsCreateSheetRevisionModalOpen] = useState(false);
  if (!apiUser || !task || !users) {
    return null;
  }

  async function changeRevisionName(revision, newName) {
    const oldName = revision.name;

    let nameIsAlreadyUsed = task.revisions.items.some((x) => x.name === newName && x.id !== revision.id);

    if (nameIsAlreadyUsed) {
      message.error({
        content: `This name is already used by another ${getSimpleLabel(
          "task revision"
        )} and hasn't been saved. Please choose a different name`,
      });
      return;
    }

    if (newName === oldName) {
      return;
    }

    let messageKey = "updating-task-revision-name";

    message.loading({ content: "Updating the name...", key: messageKey, duration: 0 });

    let updatePromises = [];
    if (organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
      for (let file of revision.files.items) {
        for (let sheet of file.sheets.items) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
          updatePromises.push(
            window.callGraphQLSimple({
              message: `Failed to update name`,
              mutation: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  name: newName,
                },
              },
            })
          );
        }
      }
    }
    updatePromises.push(
      window.callGraphQLSimple({
        message: "Failed to update name",
        queryCustom: "updateTaskRevision",
        variables: {
          input: {
            id: revision.id,
            name: newName,
          },
        },
      })
    );

    try {
      await Promise.all(updatePromises);
      message.success({ content: "Name updated", key: messageKey, duration: 2 });
    } catch (e) {
      message.error({ content: "Update failed", key: messageKey, duration: 5 });
      if (organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
        for (let file of revision.files.items) {
          for (let sheet of file.sheets.items) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            let latestSheetRevision = sheet.revisions.items.slice(-1)[0];
            updatePromises.push(
              window.callGraphQLSimple({
                message: "Failed to update name",
                mutation: "updateSheetRevision",
                variables: {
                  input: {
                    id: latestSheetRevision.id,
                    name: oldName,
                  },
                },
              })
            );
          }
        }
      }
      updatePromises.push(
        window.callGraphQLSimple({
          message: "Failed to update name",
          queryCustom: "updateTaskRevision",
          variables: {
            input: {
              id: revision.id,
              name: oldName,
            },
          },
        })
      );

      try {
        await Promise.all(updatePromises);
      } catch (e) {
        // this is the revert operation, so if this one fails too, there's nothing we can do
      }
    }

    window.callGraphQLSimple({
      message: `Failed to update name`,
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  function getTimelineEntries() {
    return [...task.revisions.items]
      .reverse()
      .filter((revision) => !revision.isArchived)
      .map((revision, i) => {
        let reviewerInitials = null;

        const reviewerUserData = users.find((user) => user.id === revision.checkedBy);
        if (reviewerUserData) {
          reviewerInitials = reviewerUserData.firstName + " " + reviewerUserData.lastName;
        }

        let reviewSecondaryStatus = null;
        let reviewStatus = null;
        if (revision.reviewSecondaryStatus) {
          reviewSecondaryStatus = (
            <Tag color={revision.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}>
              {REVIEW_SECONDARY_STATUS_READABLE[revision.reviewSecondaryStatus]}
            </Tag>
          );
        }
        if (revision.reviewStatus) {
          reviewStatus = (
            <Tag color={REVIEW_STATUS_READABLE[revision.reviewStatus].color}>
              {revision.reviewStatus === "SUCCESS" && <CheckCircleFilled className="icon" />}
              {REVIEW_STATUS_READABLE[revision.reviewStatus].label}
            </Tag>
          );
        }

        let authorData = users.find((x) => x.id === revision.author);
        let requestFormActivityItem = getDetailsForFormAndTaskRevision({
          activityItemsByRequest: activityItemsByRequest,
          taskRevisionId: revision.id,
        });

        return {
          ...revision,
          descriptionText: revision.description,
          requestFormActivityItem,
          base: task.revisions.items.find((x) => x.id === revision.base)?.name || "-",
          key: `${task.clientId}/${revision.name}/${i}`,
          createdAt: moment(revision.createdAt).format("DD-MM-YYYY"),
          checkedBy: reviewerInitials,
          authorForDisplay: authorData ? `${authorData?.firstName || ""} ${authorData?.lastName || ""}` : undefined,
          nameWithReviewStatus: (
            <div className="task-revision-name-and-status-wrapper">
              {reviewStatus ? (
                <Link
                  className="task-revision-name-link task-revision-name-and-status"
                  to={`/tasks/${task.id}/review/${revision.id}`}
                  key={`go-to-review-link-revision/${revision.name}`}
                  data-testid="task-revision-name-and-status"
                  data-cy="task-revision-review-status"
                >
                  {reviewStatus}
                  {reviewSecondaryStatus}
                </Link>
              ) : null}
              <InfoItem
                fullWidth
                inline
                label="Name"
                className="task-revision-title-info-item"
                value={
                  <Input
                    className="task-revision-title"
                    data-cy="task-revision-title-input"
                    defaultValue={revision.name}
                    onChange={(name) => changeRevisionName(revision, name)}
                    disabled={
                      revision.isReadOnly &&
                      !isAuthorised(["FULL.READ_WRITE", "TASK_DETAILS.EDIT_READ_ONLY_TASK_REVISION_DETAILS"])
                    }
                  />
                }
              />
            </div>
          ),
        };
      });
  }

  function onAddTaskRevisionButtonClick() {
    if (!task.assignedTo && !organisationDetails?.settings?.task?.allowMultipleLiveTaskRevisions) {
      Modal.error({
        title: "Assignee required",
        content: (
          <>
            In order to create a {getSimpleLabel("task revision")}, the {getSimpleLabel("task")} must first be assigned
            to someone
          </>
        ),
      });
    } else if (!isAuthorised(["TASK_DETAILS.ADD_TASK_REVISION"])) {
      Modal.error({
        title: "Not authorised",
        content: <>Not authorised to create a {getSimpleLabel("task revision")}.</>,
      });
    } else {
      openCreateTaskRevisionModal();
    }
  }

  function displayRevisions() {
    const timelineEntries = getTimelineEntries();

    return (
      <Timeline
        mode="left"
        className={cx("task-revisions-timeline", {
          "hide-dates": organisationDetails?.settings?.task?.allowMultipleLiveTaskRevisions,
        })}
      >
        {timelineEntries.map((revision, i) => {
          return (
            <Timeline.Item label={revision.createdAt} key={revision.key}>
              <TaskRevisionItem
                visualIndex={timelineEntries.length - i}
                index={i}
                revision={revision}
                task={task}
                apiUser={apiUser}
                users={users}
                user={user}
                history={history}
                linkedTasksDetails={linkedTasksDetails}
                organisationDetails={organisationDetails}
                windowWidth={windowWidth}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  }

  let addTaskRevisionButton = null;
  let addSheetRevisionsButton = null;

  if (!organisationDetails?.settings?.task?.cannotCreateNewTaskRevisions) {
    addTaskRevisionButton = (
      <Button
        icon={<PlusCircleOutlined />}
        type="primary"
        className="create-task-revision"
        disabled={task.isFinished || task.isArchived || task.isUnderReview}
        onClick={onAddTaskRevisionButtonClick}
      >
        Add {getSimpleLabel("task revision")}
      </Button>
    );

    let latestTaskRevision = task.revisions.items.slice(-1)[0];
    if (
      latestTaskRevision &&
      !organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
      latestTaskRevision.files?.items?.length &&
      !task.isReadOnly &&
      !task.isFinished &&
      !task.isArchived &&
      !latestTaskRevision.isReadOnly &&
      isAuthorised(["TASK_DETAILS.ADD_TASK_REVISION"])
    ) {
      addSheetRevisionsButton = (
        <Button
          icon={<PlusCircleOutlined />}
          className="create-sheet-revisions"
          onClick={() => {
            setIsCreateSheetRevisionModalOpen(true);
          }}
        >
          Add revision to all files/sheets
        </Button>
      );
    }
  }

  return (
    <>
      <Card
        className="task-revisions"
        title={`${getSimpleLabel("Task revisions")}`}
        actions={[addSheetRevisionsButton, addTaskRevisionButton].filter((x) => x)}
      >
        {displayRevisions()}
      </Card>
      {isCreateSheetRevisionModalOpen ? (
        <CreateSheetRevisionModal
          onClose={() => setIsCreateSheetRevisionModalOpen(false)}
          apiUser={apiUser}
          task={task}
          taskRevision={task.revisions.items.slice(-1)[0]}
        />
      ) : null}
    </>
  );
}

export default withRouter(TaskRevisions);
