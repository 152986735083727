import { useEffect, useState } from "react";
import moment from "moment";
import {
  Typography,
  Modal,
  message,
  Button,
  Select,
  notification,
  Switch,
  InputNumber,
  Collapse,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import { Storage } from "aws-amplify";
import {
  EditOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import cookie from "js-cookie";

import { RestoreIcon } from "common/icons";
import { publish, downloadBlob } from "common/helpers";
import { TEMPLATE_PARENT_TYPES } from "common/shared";
import getS3File from "common/getS3File";
import {
  FILE_TYPES_READABLE,
  FILE_TYPE_EXTENSIONS,
  COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT,
  REVIEW_DEFAULT_COMMENT_FONT_SIZE,
  COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH,
} from "common/constants";
import { calculateReadableSize } from "common/helpers";
import { callRest } from "common/apiHelpers";
import { TOOLS } from "../Toolbar/Toolbar";
import { getSimpleLabel } from "common/labels";

import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import TemplateEditorPreviewPanelSection from "./TemplateEditorPreviewPanelSection/TemplateEditorPreviewPanelSection";
import TextVariables from "../ObjectPanel/TextVariables/TextVariables";

import "./CanvasPanel.scss";

const INITIAL_WIDTH = 400;

export default function CanvasPanel({
  template,
  fileType,
  organisationDetails,
  windowHeight,
  onToolClick,
  templateTask,
  onPdfPreviewLoaded,
  setIsPreviewVisible,
  isPreviewVisible,
  pdfPreviewData,
  fileTypeDetails,
  showFormEditor,
  hideFormEditor,
  recordChange,
  s3Versions,
  history,
  targetS3VersionId,
  restoreTemplateVersion,
  templatePdfKey,
  isFormEditorVisible,
  outputTemplate,
  setOutputTemplate,
  forceUpdate,
  updateObject,
  form,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [previewTask, setPreviewTask] = useState();
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [isDraggingWidth, setIsDraggingWidth] = useState(false);
  const [dragStartX, setDragStartX] = useState();
  const [dragStartWidth, setDragStartWidth] = useState(
    parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH)) || INITIAL_WIDTH
  );
  const [panelWidth, setPanelWidth] = useState(
    parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH)) || INITIAL_WIDTH
  );

  useEffect(() => {
    fetchPreviewTask();
  }, [template.previewTaskId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("mouseup", onWindowMouseUp);
    window.addEventListener("mousemove", onWindowMouseMove);

    return () => {
      window.removeEventListener("mouseup", onWindowMouseUp);
      window.removeEventListener("mousemove", onWindowMouseMove);
    };
  }, []);

  function onWindowMouseUp() {
    setIsDraggingWidth(false);
  }

  function onWindowMouseMove(e) {
    setIsDraggingWidth((isDraggingWidth) => {
      setDragStartX((dragStartX) => {
        setDragStartWidth((dragStartWidth) => {
          if (isDraggingWidth) {
            let deltaX = e.screenX - dragStartX;
            let newPanelWidth = Math.max(dragStartWidth - deltaX, 10);
            cookie.set(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH, newPanelWidth, {
              expires: 99999,
            });

            setPanelWidth(newPanelWidth);
          }
          return dragStartWidth;
        });
        return dragStartX;
      });
      return isDraggingWidth;
    });
  }

  let previewTaskRevision = previewTask?.revisions.items.find((x) => x.id === template.previewTaskRevisionId);
  let previewFile = previewTaskRevision?.files.items.find((x) => x.id === template.previewFileId);

  async function fetchPreviewTask() {
    if (!template.previewTaskId) {
      return;
    }
    const previewTask = (
      await window.callGraphQLSimple({
        message: `Failed to fetch preview ${getSimpleLabel("task")} details`,
        queryCustom: "getTaskWithFiles",
        variables: {
          id: template.previewTaskId,
        },
      })
    ).data.getTask;
    setPreviewTask(previewTask);
  }

  async function triggerAnnotate() {
    setIsAnnotating(true);
    await onToolClick(TOOLS.UPLOAD);

    let previewFileVersion = previewFile.versions.items.slice(-1)[0];

    let publishedKey = `public/${organisationDetails.id}/templates/${fileType}/${template.id}_preview.pdf`;
    try {
      await callRest({
        route: "/annotate",
        method: "POST",
        body: {
          eventId: template.id,
          sourceKey: templatePdfKey,
          publishedKey,
          organisation: organisationDetails.id,
          templateId: template.id,
          taskRevisionId: template.previewTaskRevisionId,
          fileId: template.previewFileId,
          fileVersionId: previewFileVersion.id,
          taskId: template.previewTaskId,
          sheetNames: [previewFile.sheets.items[0].name],
          isTemplate: true,
          fileType,
        },
      });

      const pdfData = (
        await Storage.get(publishedKey.replace("public/", ""), {
          download: true,
        })
      ).Body;

      const pdfDataArrayBuffer = await new Response(pdfData).arrayBuffer();

      onPdfPreviewLoaded(pdfDataArrayBuffer);
      setIsPreviewVisible(true);
    } catch (e) {
      notification.error({
        message: "Failed to generate preview",
      });
    }

    setIsAnnotating(false);
  }

  async function updateTemplateField({ fieldName, value }) {
    await window.callGraphQLSimple({
      message: "Failed to update template details",
      mutation: "updateTemplate",
      variables: {
        input: {
          id: template.id,
          [fieldName]: value,
        },
      },
    });

    await window.callGraphQLSimple({
      message: "Failed to refresh organisation details",
      mutation: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  async function onFileInputChange(e) {
    const fileToUpload = e.target.files[0];
    if (!fileToUpload) {
      return;
    }
    if (!fileToUpload.name.includes(".")) {
      Modal.error({
        title: "Invalid file",
        content: "The file you are trying to upload does not have an extension.",
      });
      return;
    }
    const fileExtension = fileToUpload.name.split(".").slice(-1)[0];
    const acceptedExtensions = FILE_TYPE_EXTENSIONS[fileType];

    if (!acceptedExtensions.includes(fileExtension)) {
      Modal.error({
        title: "Wrong file type",
        content: `File extension must be: ${acceptedExtensions.join(
          ", "
        )}. The file you are trying to upload has the extension: ${fileExtension}`,
      });

      return;
    }

    setIsUploading(true);
    await Storage.put(template.key, fileToUpload);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await republish();
    setIsUploading(false);
    message.success(`${FILE_TYPES_READABLE[fileType]} file updated`);
  }

  async function republish() {
    const dbFile = templateTask.revisions?.items[0].files?.items[0];
    if (!fileTypeDetails.isDocumentTemplate) {
      await publish({
        file: dbFile,
        fileVersionId: dbFile.versions?.items.slice(-1)[0].id,
        task: templateTask,
        taskRevisionId: templateTask.revisions?.items[0].id,
      });
    }
  }

  function displayHistory() {
    if (s3Versions === undefined) {
      return (
        <Typography.Text>
          <LoadingOutlined style={{ marginRight: "0.5rem" }} />
          Loading history...
        </Typography.Text>
      );
    }

    return (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 100 }}
        rowKey={"VersionId"}
        rowClassName={(row) => {
          if (row.VersionId === targetS3VersionId) {
            return "selected-row";
          }
        }}
        tableLayout="fixed"
        scroll={{ y: windowHeight - 550 }}
        dataSource={s3Versions}
        onRow={(row) => ({
          onClick: () => history.push(`${window.location.pathname}?versionId=${row.VersionId}`),
        })}
        columns={[
          {
            title: "",
            width: 40,
            render: (_, version) => {
              if (version.index === s3Versions.length) {
                return null;
              }
              return (
                <Tooltip title="Restore this version" placement="left">
                  <div className="restore-icon-container" onClick={() => restoreTemplateVersion(version)}>
                    <RestoreIcon
                      onClick={() => history.push(`${window.location.pathname}?versionId=${version.VersionId}`)}
                    />
                  </div>
                </Tooltip>
              );
            },
          },
          {
            width: 50,
            render: (_, version) => {
              return version.index;
            },
          },

          {
            title: "Created",
            render: (_, version) => {
              return moment(version.LastModified).format("DD/MM/YYYY HH:mm:ss");
            },
          },

          {
            title: "Size",
            width: 70,
            render: (_, version) => {
              return calculateReadableSize({ size: version.Size });
            },
          },
        ]}
      />
    );
  }

  function displayDocumentFields() {
    return (
      <Collapse defaultActiveKey={["form"]} accordion>
        {!fileTypeDetails.isFormOnly && (
          <Collapse.Panel header="Form fields" key="form">
            <InfoItem
              inline
              value={
                isFormEditorVisible ? (
                  <Button type="primary" icon={<CloseCircleOutlined />} onClick={hideFormEditor}>
                    Close form
                  </Button>
                ) : (
                  <Button type="primary" icon={<EditOutlined />} onClick={showFormEditor}>
                    Open form
                  </Button>
                )
              }
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel header="Data used" key="data-used">
          <TextVariables
            object={outputTemplate}
            updateObject={updateObject}
            fileTypeDetails={fileTypeDetails}
            template={template}
            form={form}
            organisationDetails={organisationDetails}
            isDataSection
          />
        </Collapse.Panel>

        <Collapse.Panel header="General settings" key="general-settings">
          <InfoItem
            label="Default review comment font size"
            value={
              <Input
                fullWidth
                showBorder
                fireOnChangeWithoutBlur
                defaultValue={template.reviewCommentFontSize}
                placeholder={
                  organisationDetails.settings?.review?.defaultCommentSize || REVIEW_DEFAULT_COMMENT_FONT_SIZE
                }
                onChange={(value) => {
                  updateTemplateField({
                    fieldName: "reviewCommentFontSize",
                    value: value || null,
                  });
                }}
              />
            }
          />
          <InfoItem
            label="Exclude from Document Register by default"
            value={
              <Switch
                checked={template.excludeFromRegisterByDefault}
                onChange={(value) => {
                  updateTemplateField({
                    fieldName: "excludeFromRegisterByDefault",
                    value,
                  });
                }}
              />
            }
          />
          {fileTypeDetails.isDocumentTemplate && (
            <InfoItem
              label="Output type"
              inline
              value={
                <Select
                  onChange={(value) => {
                    updateTemplateField({
                      fieldName: "outputType",
                      value,
                    });
                  }}
                  value={template.outputType}
                  placeholder="PDF (by default)"
                >
                  {(!fileTypeDetails.outputTypes || fileTypeDetails.outputTypes?.includes("PDF")) && (
                    <Select.Option value="PDF">PDF</Select.Option>
                  )}
                  {fileTypeDetails.outputTypes?.includes("SPREADSHEET") && (
                    <Select.Option value="SPREADSHEET">CSV</Select.Option>
                  )}
                  {fileTypeDetails.outputTypes?.includes("SPREADSHEET-EXCEL") && (
                    <Select.Option value="SPREADSHEET-EXCEL">Excel</Select.Option>
                  )}
                </Select>
              }
            />
          )}
          {fileType === "REPORT" && (
            <InfoItem
              label="Parent type"
              inline
              value={
                <Select
                  onChange={(value) => {
                    updateTemplateField({
                      fieldName: "parentType",
                      value,
                    });
                  }}
                  value={template.parentType}
                  placeholder={`${getSimpleLabel("Task")} (by default)`}
                >
                  {TEMPLATE_PARENT_TYPES.map(({ label, value }, i) => (
                    <Select.Option value={value} key={i}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          )}
        </Collapse.Panel>

        {!fileTypeDetails.isFormOnly && (
          <Collapse.Panel header="Guides" key="guides">
            <InfoItem
              label="Display page padding outline"
              inline
              value={
                <Switch
                  checked={cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT) !== "false"}
                  onChange={(value) => {
                    cookie.set(COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT, value, { expires: 9999 });
                    forceUpdate();
                  }}
                />
              }
            />
            {/* <InfoItem
              label="Display page height outline"
              inline
              value={
                <Switch
                  checked={cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PAGE_HEIGHT_LIMIT) !== "false"}
                  onChange={(value) => {
                    cookie.set(COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PAGE_HEIGHT_LIMIT, value, { expires: 9999 });
                    forceUpdate();
                  }}
                />
              }
            /> */}
          </Collapse.Panel>
        )}
        {!fileTypeDetails.isFormOnly && (
          <Collapse.Panel header="Default page padding" key="padding">
            <InfoItem
              label="Default page padding top"
              inline
              value={
                <InputNumber
                  defaultValue={outputTemplate.custom_defaultPagePaddingTop}
                  onChange={(value) => {
                    setOutputTemplate({
                      ...outputTemplate,
                      custom_defaultPagePaddingTop: value,
                    });
                    recordChange();
                  }}
                />
              }
            />
            <InfoItem
              label="Default page padding bottom"
              inline
              value={
                <InputNumber
                  defaultValue={outputTemplate.custom_defaultPagePaddingBottom}
                  onChange={(value) => {
                    setOutputTemplate({
                      ...outputTemplate,
                      custom_defaultPagePaddingBottom: value,
                    });
                    recordChange();
                  }}
                />
              }
            />
            <InfoItem
              label="Default page padding left"
              inline
              value={
                <InputNumber
                  defaultValue={outputTemplate.custom_defaultPagePaddingLeft}
                  onChange={(value) => {
                    setOutputTemplate({
                      ...outputTemplate,
                      custom_defaultPagePaddingLeft: value,
                    });
                    recordChange();
                  }}
                />
              }
            />
            <InfoItem
              label="Default page padding right"
              inline
              value={
                <InputNumber
                  defaultValue={outputTemplate.custom_defaultPagePaddingRight}
                  onChange={(value) => {
                    setOutputTemplate({
                      ...outputTemplate,
                      custom_defaultPagePaddingRight: value,
                    });
                    recordChange();
                  }}
                />
              }
            />
          </Collapse.Panel>
        )}

        <Collapse.Panel header={`Version history (${s3Versions.length} versions)`} key="history">
          {displayHistory()}
        </Collapse.Panel>
      </Collapse>
    );
  }

  async function downloadTemplateFile() {
    const key = template.key;
    const filePublicURL = await getS3File(key);
    const extension = key.split(".").pop();
    const fileName = `${template.type} - ${template.name}.${extension}`;

    const fileBlob = (
      await axios({
        url: filePublicURL,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;
    downloadBlob({
      blob: fileBlob,
      fileName,
    });
  }

  function displayNonDocumentFields() {
    return (
      <Collapse defaultActiveKey={["preview"]} accordion>
        <Collapse.Panel header="Replace file" key="replace-file">
          <Button
            style={{ marginBottom: "0.5rem", width: 200 }}
            onClick={downloadTemplateFile}
            icon={<DownloadOutlined />}
            type="primary"
          >
            Download {FILE_TYPES_READABLE[fileType]} file
          </Button>
          <InfoItem
            value={
              <label htmlFor="replace-file" className="upload-button-container">
                <Button
                  style={{ width: 200 }}
                  type="dark"
                  disabled={isUploading}
                  icon={isUploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
                  onClick={() => {
                    document.querySelector(`#replace-template-file`).click();
                  }}
                >
                  <span>{isUploading ? "Uploading file..." : `Upload ${FILE_TYPES_READABLE[fileType]} file`}</span>
                </Button>

                <input
                  id="replace-template-file"
                  style={{ display: "none" }}
                  type="file"
                  onChange={onFileInputChange}
                />
              </label>
            }
          />

          <Button onClick={republish} style={{ width: 200 }}>
            Republish file
          </Button>
        </Collapse.Panel>
        <Collapse.Panel header="General settings" key="general-settings">
          <InfoItem
            label="Default review comment font size"
            inline
            value={
              <Input
                fullWidth
                showBorder
                fireOnChangeWithoutBlur
                defaultValue={template.reviewCommentFontSize}
                placeholder={
                  organisationDetails.settings?.review?.defaultCommentSize || REVIEW_DEFAULT_COMMENT_FONT_SIZE
                }
                onChange={(value) => {
                  updateTemplateField({
                    fieldName: "reviewCommentFontSize",
                    value: value || null,
                  });
                }}
              />
            }
          />
          <InfoItem
            label="Exclude from Document Register by default"
            inline
            value={
              <Switch
                checked={template.excludeFromRegisterByDefault}
                onChange={(value) => {
                  updateTemplateField({
                    fieldName: "excludeFromRegisterByDefault",
                    value,
                  });
                }}
              />
            }
          />
        </Collapse.Panel>
        <Collapse.Panel header={`Version history (${s3Versions.length} versions)`} key="history">
          {displayHistory()}
        </Collapse.Panel>
        <Collapse.Panel header="Preview" key="preview">
          <TemplateEditorPreviewPanelSection
            updateTemplateField={updateTemplateField}
            template={template}
            previewTask={previewTask}
            previewTaskRevision={previewTaskRevision}
            previewFile={previewFile}
            isPreviewVisible={isPreviewVisible}
            isAnnotating={isAnnotating}
            triggerAnnotate={triggerAnnotate}
            pdfPreviewData={pdfPreviewData}
            setIsPreviewVisible={setIsPreviewVisible}
          />
        </Collapse.Panel>
      </Collapse>
    );
  }

  if (!outputTemplate) {
    return null;
  }

  return (
    <div className="canvas-panel panel" style={{ width: panelWidth }}>
      <div className="panel-contents">
        <div className="section">
          <InfoItem
            label="Template name"
            value={
              <Input
                defaultValue={template.name}
                fullWidth
                showBorder
                onChange={(value) => updateTemplateField({ fieldName: "name", value })}
              />
            }
          />

          {fileTypeDetails.isDocumentTemplate ? displayDocumentFields() : displayNonDocumentFields()}
        </div>
      </div>
      <div
        className="border-drag"
        onMouseDown={(e) => {
          setIsDraggingWidth(true);
          setDragStartX(e.screenX);
          setPanelWidth((panelWidth) => {
            setDragStartWidth(() => {
              return panelWidth;
            });
            return panelWidth;
          });
        }}
      />
    </div>
  );
}
