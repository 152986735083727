/*
  This Higher Order Component (HOC) is responsible for all data fetching and subscriptions in the app,
  as well as passing context from the root component down to its children,
  no matter how deeply nested.

  This component is used both by the root App component, as well as any other
  component which requires access to any data from the API.

  -----------------------------------------------------------------
  It handles fetching based on several factors:

  1) If the component is the root, it will load all the collections

  2) If the component is not root, it will check the list of subscriptions required, and
  if any of those was already fetched by the root, it will get its value from there.
  Otherwise, it will fetch that data collection and create a subscription for it (e.g. tasks or projects)

  3) For individual records (task, project, review and so on) it will do an individual fetch, as
  well as creating a subscription for that particular record's ID.
  The key to this is that the URL parameter name defined in the route
  has to match the name of the collection, minus the "s" for plural. For example, if talking
  about the project details component, this needs a URL parameter called "projectId", and it also
  needs a subscription for "project". The rest is taken care of by this HOC.


  -----------------------------------------------------------------

  In order to add a new data source for a component, 4 things must be done:

  1) There needs to be a function in common/helpers.js named "fetchAndSet<dataSourceName>" e.g. "fetchAndSetTask",
    either plural or singular

  2) If it's an individual record, its name needs to be added to INDIVIDUAL_RECORDS.
    Otherwise if it's a collection, its name needs to be added to COLLECTIONS.

  3) If it's an individual record, its ID must be present in the URL params.
    The URL param name must match the format "<recordName>Id", e.g. "taskId".

  4) The data source must be specified in the subscriptions for the component which implements it.
    The subscription name is the name of the record type, e.g "task" for an individual record
    or "tasks" for the collection
*/

import React from "react";
import { Auth } from "aws-amplify";
import { message } from "antd";

import LoadingWrapper from "LoadingWrapper/LoadingWrapper";
import { Context } from "common/context";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";
import { PUBLIC_USER_ORGANISATION } from "common/constants";
import { loadApiUserAndOrganisationAndSelectedTeams } from "common/authHelpers";
import * as helpers from "common/helpers";
import { callRest } from "common/apiHelpers";
import {
  subscribeToEntity,
  subscribeToOnUpdateOrganisation,
  clearSubscriptionsForComponent,
} from "common/subscriptionHelpers";

const ROOT_SUBSCRIPTIONS = [
  "organisations",
  "users",
  "clients",
  "tasks",
  "projects",
  "sprints",
  "quotes",
  "invoices",
  "groups",
  "suppliers",
  "purchaseOrders",
  "stockItems",
  "requests",
];

// organisations are loaded separately
const COLLECTIONS = [
  "sprints",
  "clients",
  "users",
  "tasks",
  "projects",
  "timelineBlocks",
  "timesheetBlocks",
  "timesheetTags",
  "auditItems",
  "notifications",
  "quotes",
  "quoteLineItems",
  "asyncJobs",
  "holidaysByUser",
  "holidays",
  "invoices",
  "groups",
  "suppliers",
  "purchaseOrders",
  "analytics",
  "requests",
  "stockItems",
];
const INDIVIDUAL_RECORDS = [
  "organisation",
  "project",
  "task",
  "taskRevision",
  "review",
  "file",
  "quote",
  "client",
  "invoice",
  "purchaseOrder",
  "supplier",
  "request",
  "stockItem",
];

const SUBSCRIPTION_NAME_MAP = {
  holidaysByUser: "holidays",
};

const PUBLIC_COLLECTIONS = [""];
const PUBLIC_INDIVIDUAL_RECORDS = [""];

type Props = {
  Component?: any;
  subscriptions?: any;
  filters?: any;
  isRoot?: any;
  loadingClassName?: string;
  displayPreloader?: boolean;
};

export default function withSubscriptions({
  Component,
  subscriptions,
  filters,
  isRoot,
  displayPreloader = true,
}: Props) {
  // console.log("withSubscriptions() Component = ", Component);
  if (isRoot) {
    subscriptions = ROOT_SUBSCRIPTIONS;
  } else {
    // for non-root components, we don't want to re-fetch and re-subscribe to collections
    // that are already loaded by the root component
    subscriptions = (subscriptions || []).filter((x) => !ROOT_SUBSCRIPTIONS.includes(x));
  }
  return class withSubscriptions extends React.Component<any> {
    _isMounted: boolean = false;
    prevParams: string | null = null;

    state = {
      isLoading: true,
      error: null,
      context: {},
      shouldDisplayPreloader: false,
      isLoadingInitialData: true,
      hasRestoredCachedData: false, // used to pass to the root app component that we're finished
      hasFailedToRestoreCache: false,
    };

    temporaryContext = {}; // used to load data in the background after having restored cached data
    hasRestoredCachedData = false; // used to check  within the function that loads the data

    async componentDidMount() {
      this._isMounted = true;

      this.startInitialLoad();
    }

    componentDidUpdate() {
      // if the page changes, but the same component is used, we need to refresh the data
      const { match, history } = this.props;
      if (match && history) {
        const currentParams = JSON.stringify(match.params);
        if (this.prevParams && currentParams !== this.prevParams) {
          this.startInitialLoad();
          setTimeout(() => {
            let html: HTMLElement | null = document.querySelector("html");
            if (html) {
              html.scrollTop = 0;
            }
          }, 300);
        }
        this.prevParams = currentParams;
      }
    }

    startInitialLoad = async () => {
      const startTime = Date.now();
      let user;
      let weHaveSsoUserWithoutApiUser = false;
      this.setState({ isLoading: true });

      try {
        // checking for a situation where we have a user logged in via SSO, but we don't yet have it in the database
        user = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });
      } catch (e) {
        // nothing to do here
      }

      if (
        user &&
        user.signInUserSession?.idToken?.payload?.identities &&
        user.signInUserSession?.idToken?.payload?.identities[0]?.providerType === "SAML"
      ) {
        if (window.apiUser === null) {
          await callRest({
            method: "POST",
            route: `/create-saml-user`,
            includeCredentials: false,
            // @ts-ignore
            body: {
              idToken: user.signInUserSession.idToken.payload,
            },
          });
          weHaveSsoUserWithoutApiUser = true;
        }
      }

      if (window.apiUser === undefined || weHaveSsoUserWithoutApiUser) {
        await loadApiUserAndOrganisationAndSelectedTeams();
        this.startInitialLoad();
        return;
      }

      try {
        user = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });
      } catch (e) {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            isLoadingInitialData: false,
          });
        }
        return;
      }

      const organisation = user ? user.signInUserSession.idToken.payload.organisation : undefined;
      let promisesToAwait: Promise<any>[] = [];

      let organisationIdForCachingData;
      if (organisation !== PUBLIC_USER_ORGANISATION) {
        if (isRoot) {
          organisationIdForCachingData = user.signInUserSession.idToken.payload.organisation;
          await this.restoreCachedData(user);
        }

        if (subscriptions.includes("organisations")) {
          const organisationsPromise: Promise<any> = this.fetchAndSubscribeToOrganisations(user);
          promisesToAwait.push(organisationsPromise);
        }
      }

      const collectionPromises = this.fetchAndSubscribeCollections({
        organisation,
      });
      const individualPromises = this.fetchAndSubscribeIndividual({
        organisation,
      });

      if (collectionPromises) {
        promisesToAwait = [...promisesToAwait, ...collectionPromises];
      }

      if (individualPromises) {
        promisesToAwait = [...promisesToAwait, ...individualPromises];
      }

      try {
        await Promise.all(promisesToAwait);

        if (this._isMounted) {
          let newState: any = {
            ...this.state,
            isLoading: false,
            isLoadingInitialData: false,
          };
          if (isRoot && this.hasRestoredCachedData) {
            newState.context = {
              ...this.state.context,
              ...this.temporaryContext,
            };
          }
          this.setState(newState);

          if (isRoot) {
            let userAfterLoadHasFinished;
            let organisationIdAfterLoadHasFinished;
            try {
              userAfterLoadHasFinished = await Auth.currentAuthenticatedUser({
                bypassCache: false,
              });
              organisationIdAfterLoadHasFinished =
                userAfterLoadHasFinished.signInUserSession.idToken.payload.organisation;
            } catch (e) {
              console.error("Failed to get user after load has finished", e);
              return;
            }

            if (userAfterLoadHasFinished && organisationIdAfterLoadHasFinished === organisationIdForCachingData) {
              window.localDatabase.setItem(
                `rootData-${organisationIdForCachingData}`,
                JSON.stringify(newState.context)
              );
            }
          }
        }
      } catch (e) {
        console.log("error: ", e);
        if (this._isMounted) {
          this.setState({
            error: 500,
            isLoading: false,
            isLoadingInitialData: false,
          });
        }
      }
    };

    restoreCachedData = async (user) => {
      try {
        let organisationId = user.signInUserSession.idToken.payload.organisation;
        const cacheKey = `rootData-${organisationId}`;
        // console.log("restoring cache for cacheKey = ", cacheKey);
        let cachedRootData = await window.localDatabase.getItem(cacheKey);
        if (cachedRootData) {
          let parsedData = JSON.parse(cachedRootData);

          if (parsedData) {
            this.setState({
              context: parsedData,
              isLoading: false,
              hasRestoredCachedData: true,
            });

            this.hasRestoredCachedData = true;
          } else {
            this.setState({
              hasFailedToRestoreCache: true,
            });
          }
        } else {
          this.setState({
            hasFailedToRestoreCache: true,
          });
        }
      } catch (e) {
        this.setState({
          hasFailedToRestoreCache: true,
        });
        // nothing we can do, either the data is not there or it's corrupted
      }
    };

    fetchAndSubscribeToOrganisations = async (user) => {
      const organisations = await helpers.fetchAndSetOrganisations.call(this, { checkRestoredCachedData: true });
      subscribeToOnUpdateOrganisation.call(this, organisations);
      return organisations;
    };

    fetchAndSubscribeCollections = ({ organisation }) => {
      const props = this.props;
      const validSubscriptions = subscriptions.filter((x) => {
        if (organisation === PUBLIC_USER_ORGANISATION && !PUBLIC_COLLECTIONS.includes(x)) {
          return false;
        }
        return COLLECTIONS.includes(x);
      });

      return validSubscriptions.map((field) => {
        const entityNameUpperCase = field[0].toUpperCase() + field.substring(1);
        let fetchFunctionName;
        let fetchParams: {
          filter?: any;
          organisation?: string;
        } = {
          organisation,
        };

        let entityNamePlural = field;
        if (SUBSCRIPTION_NAME_MAP.hasOwnProperty(field)) {
          entityNamePlural = SUBSCRIPTION_NAME_MAP[field];
        }

        const paramsForSubscription: any = {
          entityNamePlural,
        };
        if (field === "notifications") {
          paramsForSubscription.userId = props.apiUser.id;
        } else {
          paramsForSubscription.organisation = organisation;
        }

        /* @ts-ignore */
        subscribeToEntity.call(this, paramsForSubscription);

        try {
          fetchFunctionName = `fetchAndSet${entityNameUpperCase}`;
          const filter = filters && filters[field];
          fetchParams.filter = filter;

          this[fetchFunctionName] = (params) => helpers[fetchFunctionName].call(this, params);
          const promise = helpers[fetchFunctionName].call(this, fetchParams);
          return promise;
        } catch (e) {
          return undefined;
        }
      });
    };

    fetchAndSubscribeIndividual = ({ organisation }) => {
      const filteredSubscriptions = subscriptions.filter((x) => {
        if (organisation === PUBLIC_USER_ORGANISATION && !PUBLIC_INDIVIDUAL_RECORDS.includes(x)) {
          return false;
        }
        return INDIVIDUAL_RECORDS.includes(x);
      });
      return filteredSubscriptions.map((field) => {
        const entityNameUpperCase = field[0].toUpperCase() + field.substring(1);

        /* @ts-ignore */
        subscribeToEntity.call(this, {
          entityNameSingular: field,
          organisation,
        });

        let fetchFunctionName;
        fetchFunctionName = `fetchAndSet${entityNameUpperCase}`;
        if (!this.props.match || !this.props.match.params) {
          message.error(`Details page for ${entityNameUpperCase} most likely does not use withRouter`);
          return;
        }
        if (!this.props.match.params[`${field}Id`]) {
          message.error(`Details page for ${entityNameUpperCase} does not have a URL parameter named ${field}Id`);
          return;
        }
        const filterValue = this.props.match.params[`${field}Id`];
        let fetchParams = { id: filterValue };
        this[fetchFunctionName] = (params) =>
          helpers[fetchFunctionName].call(this, {
            id: params.id,
            ...(params || {}),
          });

        const promise = helpers[fetchFunctionName].call(this, fetchParams);
        return promise;
      });
    };

    componentWillUnmount() {
      this._isMounted = false;
      clearSubscriptionsForComponent.call(this);
    }

    render() {
      try {
        const { isLoading, error, isLoadingInitialData } = this.state;

        let content: React.ReactNode | null = null;
        let fetchFunctions: {
          [key: string]: any;
        } = {};

        for (let propName in this) {
          if (propName.indexOf("fetchAndSet") === 0) {
            fetchFunctions[propName] = this[propName];
          }
        }
        if (isRoot) {
          content = (
            <Component
              {...fetchFunctions}
              {...this.props}
              {...this.state.context}
              reloadData={this.startInitialLoad}
              isLoadingInitialData={isLoadingInitialData}
              hasRestoredCachedData={this.state.hasRestoredCachedData}
              showPreloader={() => {
                this.setState({ shouldDisplayPreloader: true });
              }}
              hidePreloader={() => {
                this.setState({ shouldDisplayPreloader: false });
              }}
            />
          );
        } else {
          content = (
            <Context.Consumer>
              {(contextPassedDown) => {
                let contextToSend = {};
                subscriptions.forEach((fieldName) => {
                  if (contextPassedDown[fieldName]) {
                    contextToSend[fieldName] = contextPassedDown[fieldName];
                  }
                });

                let nonRootProps = {
                  ...fetchFunctions,
                  ...contextPassedDown,
                  ...contextToSend,
                  ...this.state.context,
                  ...this.props,
                  isPreloaderVisible: this.state.shouldDisplayPreloader,
                  context: this.state.context,
                  showPreloader: () => {
                    this.setState({ shouldDisplayPreloader: true });
                  },
                  hidePreloader: () => {
                    this.setState({ shouldDisplayPreloader: false });
                  },
                  setProps: (state, callback) => {
                    // console.log("setProps::state = ", state, "this = ", this);
                    this.setState(state, callback);
                  },
                };

                if (nonRootProps.apiUser) {
                  return <Component {...nonRootProps} />;
                } else {
                  return null;
                }
              }}
            </Context.Consumer>
          );
        }

        return (
          <ErrorBoundary>
            <LoadingWrapper
              error={error}
              isLoading={isLoading}
              content={() => content}
              displayPreloader={displayPreloader}
              shouldDisplayPreloader={this.state.shouldDisplayPreloader}
              useLogo={isRoot}
              message={
                this.state.hasFailedToRestoreCache ? (
                  <>
                    Loading the application data, please wait... <br />
                    Next time you load the app, it will be fast.
                  </>
                ) : undefined
              }
            />
          </ErrorBoundary>
        );
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };
}
