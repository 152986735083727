import React from "react";
import moment from "moment";
import cx from "classnames";
import { Typography, Button, message, Dropdown, Menu, notification } from "antd";
import DatePicker from "DatePicker/DatePicker";
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import cookie from "js-cookie";
import { RRule } from "rrule";
import query from "query-string";
import _ from "lodash";

import {
  TIMELINE_DEFAULT_LENGTH_DAYS,
  TIMELINE_DEFAULT_START_DATE,
  TIMELINE_DEFAULT_END_DATE,
  TIMELINE_DEFAULT_TASK_LENGTH_HOURS,
  TIMELINE_DEFAULT_SNAP_COEFFICIENT_DAYS,
  TIMELINE_DEFAULT_HOURS_IN_A_DAY,
  TIMELINE_DEFAULT_DAY_START_HOURS,
  TIMELINE_DEFAULT_ZOOM_LEVEL,
  TIMELINE_PSEUDO_TASKS,
  COOKIE_NAME_TIMELINE_RESTORED_ZOOM_LEVEL,
  COOKIE_NAME_TIMELINE_HOURS_IN_A_DAY,
  COOKIE_NAME_TIMELINE_HOUR_BLOCK_WIDTH,
  COOKIE_NAME_TIMELINE_FILTER_CLIENT_ID,
  COOKIE_NAME_TIMELINE_FILTER_PROJECT_ID,
  COOKIE_NAME_TIMELINE_FILTER_TASK_ID,
  COOKIE_NAME_TIMELINE_SHOW_PSEUDO_TASKS,
  COOKIE_NAME_TIMELINE_SHOW_TASK_IDS,
  COOKIE_NAME_TIMELINE_SHOW_TASK_TITLES,
  COOKIE_NAME_TIMELINE_SHOW_PROJECT_TITLES,
  COOKIE_NAME_TIMELINE_DEFAULT_DATE_RANGE,
  COOKIE_NAME_TIMELINE_USER_ROWS,
  TIMELINE_DEFAULT_DATE_RANGES_OPTIONS,
} from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { assignTaskToUser } from "common/helpers";
import { callGraphQLSimple, fetchCollection } from "common/apiHelpers";
import { getFilteredTasks } from "common/filterHelpers";
import { add1DPointsToBlock, dateAndHoursToValue1D, value1DToDateAndHours } from "./timelineHelpers";
import { isAuthorised } from "common/permissions";
import smoothScrollTo from "common/smoothScrollTo";
import { getSimpleLabel } from "common/labels";

import TaskFilters from "TaskFilters/TaskFilters";
import UnplannedTaskList from "./UnplannedTaskList/UnplannedTaskList";
import Avatar from "Avatar/Avatar";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import TimelineSettings from "./TimelineSettings/TimelineSettings";
import TimelineLegend from "./TimelineLegend/TimelineLegend";
import NewTimelineBlockModal from "Modals/NewTimelineBlockModal/NewTimelineBlockModal";
import TimelineRow from "./TimelineRow/TimelineRow";

import "./TimelinePage.scss";

let baseDayCellWidth = 60;
let baseDayCellHeight = 68;
let dateHeadersHeight = 40;

// these 2 constants are used to set a "buffer" zone around the area being displayed
// to hide from the user that we are loading things as they scroll
const WINDOWING_MARGIN_HORIZONTAL = 300;
const WINDOWING_MARGIN_VERTICAL = 100;

export const DEFAULT_TIMELINE_BLOCK_PROPERTIES = [
  "id",
  "startDate",
  "startHours",
  "durationHours",
  "taskId",
  "userId",
  "organisation",
  "isPseudoTask",
  "repeatPattern",
];

const ZOOM_LEVELS = [
  {
    dayCellWidth: baseDayCellWidth / 1.3,
    dayCellHeight: 30,
    cellType: "tiny",
    dayCellTaskIdFontSize: "0.55rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 1.5,
    dayCellHeight: 30,
    cellType: "tiny",
    dayCellTaskIdFontSize: "0.55rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 1.5,
    dayCellHeight: 40,
    cellType: "small",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 2,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 2.5,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 3.5,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 5,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 8,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 14,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
  {
    dayCellWidth: baseDayCellWidth * 22,
    dayCellHeight: baseDayCellHeight,
    cellType: "normal",
    dayCellTaskIdFontSize: "0.7rem",
  },
];

window.isModalVisible = false;

let moveDeltaX = 0;

export class TimelinePage extends React.Component {
  constructor(props) {
    super(props);

    const { organisationDetails } = this.props;
    const timelineSettings = organisationDetails.settings?.timeline;

    this.timelineRef = React.createRef();
    this.dragState = {
      lastX: undefined,
      position: 0,
      velocity: 0,
      frame: null,
    };

    this.state = {
      hoursInADay: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
      snapCoefficientDays:
        timelineSettings?.defaultRoundToHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY ||
        TIMELINE_DEFAULT_SNAP_COEFFICIENT_DAYS,
      defaultTaskLengthHours: timelineSettings?.defaultTaskLengthHours || TIMELINE_DEFAULT_TASK_LENGTH_HOURS,
      dayStartHours: TIMELINE_DEFAULT_DAY_START_HOURS,
      defaultPlanningLengthDays: TIMELINE_DEFAULT_LENGTH_DAYS,
      planningStartDate: TIMELINE_DEFAULT_START_DATE,
      planningEndDate: TIMELINE_DEFAULT_END_DATE,
      zoomLevel: null,
      dayCellWidth: null,
      dayCellHeight: null,
      cellType: false,
      hourBlockWidth: null,
      userDragHighlight: null,
      blockInClipboard: null,
      selectedBlock: null,
      selectedBlockForTooltip: null,
      selectedUser: null,
      dragStartCoordinates: null,
      dragHandle: null,
      dates: null,
      isDraggingBlock: false,
      isResizingBlock: false,
      areSettingsVisible: false,
      isModalVisible: false,
      startTimelineScrollLeft: null,
      isTaskDetailsModalVisible: false,
      defaultDateRange: "Next 4 weeks",
      filterClientId: null, // used to filter the timeline to only show tasks for a particular client
      filterProjectId: null, // used to filter the timeline to only show tasks for a particular project
      filterTaskId: null, // used to filter the timeline to only show tasks for a particular task
      showPseudoTasks: true, // used to filter the timeline to include tasks such as "site visit" or "holiday"
      showTaskIDs: false, // controls whether timeline blocks include the task ID or not
      showTaskTitles: true, // controls whether timeline blocks include the task title or not
      showProjectTitles: true, // controls whether timeline blocks include the task title or not
      filter: {},
      isNewTimelineBlockModalVisible: false,
      eventForLastClick: undefined,
      selectedUserForLastClick: undefined,
      filteredTasks: [],
      timelineScrollLeft: 0,
      timelineScrollTop: 0,
      timelineContainerWidth: 0,
      timelineContainerHeight: 0,
      userRows: undefined,
      tasksWithRevisions: undefined,
    };

    this.onThrottledTimelineScroll = _.throttle(this.onTimelineScroll, 600, {
      leading: false,
      trailing: true,
    });

    this.onDebouncedTimelineScroll = _.debounce(this.onTimelineScroll, 100, {
      leading: false,
      trailing: true,
    });

    this.onDebouncedAndThrottledTimelineScroll = () => {
      this.onThrottledTimelineScroll();
      this.onDebouncedTimelineScroll();
    };
  }

  async componentDidMount() {
    this.props.showPreloader();
    const { organisationDetails, location } = this.props;

    if (organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks) {
      let tasksWithRevisions = await fetchCollection({
        queryCustom: "listTasksWithRevisions",
        collectionLabel: `${getSimpleLabel("tasks")} with ${getSimpleLabel("task revisions")}`,
        variables: {
          organisation: organisationDetails.id,
          limit: 1000,
        },
      });
      tasksWithRevisions = tasksWithRevisions.filter((task) => !task.isHidden && !task.id?.includes("TEMPLATES"));
      this.setState({ tasksWithRevisions: tasksWithRevisions });
    }

    const cookies = {
      hoursInADay: cookie.get(COOKIE_NAME_TIMELINE_HOURS_IN_A_DAY),
      hourBlockWidth: cookie.get(COOKIE_NAME_TIMELINE_HOUR_BLOCK_WIDTH),
      filterClientId: cookie.get(COOKIE_NAME_TIMELINE_FILTER_CLIENT_ID),
      filterProjectId: cookie.get(COOKIE_NAME_TIMELINE_FILTER_PROJECT_ID),
      filterTaskId: cookie.get(COOKIE_NAME_TIMELINE_FILTER_TASK_ID),
      restoredZoomLevel: cookie.get(COOKIE_NAME_TIMELINE_RESTORED_ZOOM_LEVEL),
      defaultDateRange: cookie.get(COOKIE_NAME_TIMELINE_DEFAULT_DATE_RANGE),
      userRows: cookie.get(COOKIE_NAME_TIMELINE_USER_ROWS)
        ? JSON.parse(cookie.get(COOKIE_NAME_TIMELINE_USER_ROWS))
        : null,
    };

    let newState = {
      hoursInADay: cookies.hoursInADay || this.state.hoursInADay,
      hourBlockWidth: cookies.hourBlockWidth || this.state.hourBlockWidth,
      filterClientId: cookies.filterClientId,
      filterProjectId: cookies.filterProjectId,
      filterTaskId: cookies.filterTaskId,
      userRows: cookies.userRows,
      showPseudoTasks: cookie.get(COOKIE_NAME_TIMELINE_SHOW_PSEUDO_TASKS) !== "false",
      showTaskIDs: cookie.get(COOKIE_NAME_TIMELINE_SHOW_TASK_IDS) !== "false",
      showTaskTitles: cookie.get(COOKIE_NAME_TIMELINE_SHOW_TASK_TITLES) !== "false",
      showProjectTitles: cookie.get(COOKIE_NAME_TIMELINE_SHOW_PROJECT_TITLES) !== "false",
    };

    const queryParams = query.parse(location.search);

    if (queryParams.startDate && queryParams.endDate) {
      newState.planningStartDate = queryParams.startDate;
      newState.planningEndDate = queryParams.endDate;
    }

    if (cookies.defaultDateRange) {
      newState.defaultDateRange = cookies.defaultDateRange;
    }

    this.setState(newState, () => {
      this.computeFilteredTasks();
    });

    if (newState.planningStartDate && newState.planningEndDate) {
      await this.changePlanningDateRange([moment(newState.planningStartDate), moment(newState.planningEndDate)]);
    } else {
      await this.changePlanningDateRange(
        TIMELINE_DEFAULT_DATE_RANGES_OPTIONS[cookies.defaultDateRange || this.state.defaultDateRange]
      );
    }

    this.props.setBackground(false);
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);

    window.addEventListener("dragend", this.onWindowDragEnd);
    window.addEventListener("mouseup", this.onWindowMouseUp);
    window.addEventListener("mousemove", this.onWindowMouseMove);
    window.addEventListener("click", this.onWindowClick);

    if (cookies.restoredZoomLevel) {
      this.setZoomLevel(parseInt(cookies.restoredZoomLevel));
    } else {
      this.setZoomLevel(window.timelineZoomLevel || TIMELINE_DEFAULT_ZOOM_LEVEL);
    }

    this.buildDateList();

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "TIMELINE",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    this.computeTimelineWidth();
    this.props.hidePreloader();
  }

  componentWillUnmount() {
    this.props.setBackground(true);
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);

    window.removeEventListener("dragend", this.onWindowDragEnd);
    window.removeEventListener("mouseup", this.onWindowMouseUp);
    window.removeEventListener("mousemove", this.onWindowMouseMove);
    window.removeEventListener("click", this.onWindowClick);
  }

  componentDidUpdate(prevProps, prevState) {
    // if tasks have changed
    if (
      prevProps.tasks !== this.props.tasks ||
      prevState.filter !== this.state.filter ||
      prevProps.clients !== this.props.clients ||
      prevProps.projects !== this.props.projects
    ) {
      this.computeFilteredTasks();
    }

    if (prevProps.windowWidth !== this.props.windowWidth || prevProps.windowHeight !== this.props.windowHeight) {
      this.computeTimelineWidth();
    }
  }

  computeFilteredTasks = () => {
    const { filter, tasksWithRevisions } = this.state;
    const { projects, clients, tasks } = this.props;

    let filteredTasks = getFilteredTasks({ projects, clients, tasks: tasksWithRevisions || tasks, filter });
    this.setState({ filteredTasks });
  };

  computeTimelineWidth = () => {
    if (!this.timelineRef.current) {
      setTimeout(this.computeTimelineWidth, 100);
      return;
    }
    const bounds = this.timelineRef.current.getBoundingClientRect();
    this.setState({
      timelineContainerWidth: bounds.width,
      timelineContainerHeight: bounds.height,
    });
  };

  onTimelineScroll = (e) => {
    if (this.timelineRef.current) {
      this.setState({
        timelineScrollLeft: this.timelineRef.current.scrollLeft,
        timelineScrollTop: this.timelineRef.current.scrollTop,
      });
    }
  };

  onWindowDragEnd = () => {
    this.setState({ userDragHighlight: null });
  };

  buildDateList = () => {
    const { planningStartDate, planningEndDate } = this.state;
    let dates = [];
    let date = moment(planningStartDate).startOf("day");

    while (date.isSameOrBefore(moment(planningEndDate).endOf("day"), "day")) {
      dates.push({
        labelDay: date.format("ddd DD"),
        labelMonth: date.format("MMMM"),
        labelMonthShort: date.format("MMM"),
        date: date.format("YYYY-MM-DD"),
        dayOfWeekNumber: date.day(),
      });
      date.add(1, "days");
    }

    this.setState({ dates });
  };

  onWindowClick = () => {
    this.setState({
      selectedBlockForTooltip: null,
    });
  };

  onWindowMouseUp = async () => {
    const { dragStartCoordinates, selectedBlock, isTaskDetailsModalVisible } = this.state;

    if (window.isModalVisible || isTaskDetailsModalVisible) {
      return;
    }

    if (!selectedBlock || !dragStartCoordinates) {
      this.resetDragState();
    }

    this.dragState.lastX = undefined;

    // this.continueDragInertia();
  };

  continueDragInertia = () => {
    if (
      Math.abs(this.dragState.velocity) < 0.1 ||
      !this.timelineRef.current ||
      this.timelineRef.current.scrollLeft <= 0 ||
      this.state.dragStartCoordinates /*|| isNaN(this.dragState)*/
    ) {
      cancelAnimationFrame(this.dragState.frame);
      return;
    }

    if (!this.timelineRef.current || this.timelineRef.current.scrollLeft === undefined) {
      return;
    }
    this.timelineRef.current.scrollLeft = this.timelineRef.current.scrollLeft - this.dragState.velocity;
    // Apply some friction to reduce the velocity over time
    this.dragState.velocity *= 0.9;
    this.dragState.frame = requestAnimationFrame(this.continueDragInertia);
  };

  resetDragState = () => {
    this.setState({
      selectedBlock: null,
      selectedUser: null,
      dragStartCoordinates: null,
      dragHandle: null,
      isDraggingBlock: false,
      startTimelineScrollLeft: null,
    });
    moveDeltaX = 0;
  };

  onWindowMouseMove = (e) => {
    const { startTimelineScrollLeft } = this.state;

    if (window.isModalVisible) {
      return;
    }

    const { dragStartCoordinates } = this.state;
    if (!dragStartCoordinates) {
      return;
    }

    if (this.dragState.lastX !== undefined) {
      let currentX = e.pageX;
      this.dragState.velocity = currentX - this.dragState.lastX;
      this.dragState.lastX = currentX;
    } else {
      this.dragState.velocity = 0;
    }

    const currentCoordinates = {
      x: e.pageX,
      y: e.pageY,
    };

    moveDeltaX = currentCoordinates.x - dragStartCoordinates.x;

    if (this.timelineRef.current && startTimelineScrollLeft !== null) {
      this.timelineRef.current.scrollLeft = startTimelineScrollLeft - moveDeltaX;
    }
  };

  renameBlock = async ({ block, name }) => {
    if (!name) {
      message.error("Please enter a name for the block");
      return;
    }

    await callGraphQLSimple({
      message: "Failed to rename block",
      queryName: "updateTimelineBlock",
      variables: {
        input: {
          id: block.id,
          taskId: name,
        },
      },
    });
  };

  removeBlock = async (block) => {
    const { context, timelineBlocks } = this.props;

    this.props.setProps({
      context: {
        ...context,
        timelineBlocks: timelineBlocks.filter((crtBlock) => crtBlock.id !== block.id),
      },
    });

    await callGraphQLSimple({
      message: "Failed to remove block",
      queryName: "deleteTimelineBlock",
      variables: {
        input: {
          id: block.id,
        },
      },
    });

    this.reflowTimeline({ userId: block.userId });
  };

  pasteBlock = async ({ e, selectedUser }) => {
    const { blockInClipboard, snapCoefficientDays, hoursInADay } = this.state;
    await this.moveBlockOnDrop({
      e,
      selectedUser,
      draggableType: "block",
      duplicate: true,
      userChosenColor: blockInClipboard.userChosenColor,
      taskId: blockInClipboard.taskId,
      taskRevisionId: blockInClipboard.taskRevisionId,
      blockId: blockInClipboard.id,
      oldUserId: selectedUser.id,
      blockDurationHours: Math.max(blockInClipboard.durationHours, snapCoefficientDays * hoursInADay),
      isPseudoTask: blockInClipboard.isPseudoTask,
      isFixed: blockInClipboard.isFixed,
    });
  };

  onNewTimelineBlockModalSubmit = async ({ taskId, draggableType }) => {
    const newBlock = await this.createBlockOnDrop({
      e: this.state.eventForLastClick,
      selectedUser: this.state.selectedUserForLastClick,
      taskId,
      draggableType,
    });

    this.reflowTimeline({
      userId: this.state.selectedUserForLastClick.id,
      startingBlock: newBlock,
    });

    this.setState({
      isNewTimelineBlockModalVisible: false,
      selectedUserForLastClick: undefined,
      eventForLastClick: undefined,
    });
  };

  onRowDrop = async (e, selectedUser) => {
    const draggableType = e.dataTransfer?.getData("draggable-type");
    if (!draggableType || draggableType === "") {
      return;
    }

    if (!isAuthorised(["TIMELINE.EDIT"])) {
      message.error(`You are not authorised to edit the ${getSimpleLabel("timeline")}`);
      return;
    }

    let newBlock;

    if (draggableType === "task" || draggableType === "pseudo-task") {
      newBlock = await this.createBlockOnDrop({ e, selectedUser, draggableType });
    } else if (draggableType === "task-revision") {
      newBlock = await this.createBlockOnDrop({ e, selectedUser, draggableType });
    } else if (draggableType === "block") {
      newBlock = await this.moveBlockOnDrop({
        e,
        selectedUser,
        draggableType,
        duplicate: false,
      });
    }

    this.resetDragState();
    this.reflowTimeline({
      startingBlock: newBlock,
    });
  };

  moveBlockOnDrop = async ({
    e,
    selectedUser,
    duplicate,
    taskId,
    taskRevisionId,
    blockId,
    oldUserId,
    blockDurationHours,
    userChosenColor,
    draggableType,
    isPseudoTask = false,
  }) => {
    const { context, organisationDetails, timelineBlocks, tasks } = this.props;
    const { planningStartDate, dayCellWidth, snapCoefficientDays, hoursInADay } = this.state;

    if (e && e.dataTransfer && e.dataTransfer.getData("is-pseudo-task") === "true") {
      isPseudoTask = true;
    }
    if (!taskId) {
      taskId = e.dataTransfer?.getData("task-id");
    }

    if (!taskRevisionId) {
      taskRevisionId = e.dataTransfer?.getData("task-revision-id");
    }

    if (!blockId) {
      blockId = e.dataTransfer?.getData("block-id");
    }

    if (!oldUserId) {
      oldUserId = e.dataTransfer?.getData("user");
    }

    if (!blockDurationHours) {
      blockDurationHours = parseInt(e.dataTransfer.getData("block-duration-hours"));
    }

    let dropX = this.getLocalRowX(e);
    let daysFromStart = dropX / dayCellWidth;

    let roundedDaysSincePlanningStart =
      Math.floor(daysFromStart * (1 / snapCoefficientDays)) / (1 / snapCoefficientDays);
    let planningStartDateMoment = moment(planningStartDate);

    let newBlockDetails = {
      startDate: planningStartDateMoment.add(parseInt(roundedDaysSincePlanningStart), "day").format("YYYY-MM-DD"),
      startHours: (roundedDaysSincePlanningStart - parseInt(roundedDaysSincePlanningStart)) * hoursInADay,
      durationHours: Math.max(blockDurationHours, snapCoefficientDays * hoursInADay),
      userChosenColor: e?.dataTransfer?.getData("user-chosen-color") || userChosenColor,
      taskId,
      taskRevisionId,
      userId: selectedUser.id,
      organisation: organisationDetails.id,
      isPseudoTask: draggableType === "pseudo-task" || isPseudoTask,
    };

    let blockToReturn;

    if (duplicate) {
      let newDuplicateBlock = {
        ...newBlockDetails,
        id: String(Date.now()) + String(Math.floor(Math.random() * 10000)),
        createdAt: new Date().toISOString(),
      };
      await this.addBlockToUser({ e, newBlock: newDuplicateBlock, selectedUser, draggableType });
      blockToReturn = newDuplicateBlock;
      this.reflowTimeline({
        userId: oldUserId,
        startingBlock: newDuplicateBlock,
      });
    } else {
      if (draggableType !== "pseudo-task" && !isPseudoTask) {
        const task = tasks.find((x) => x.id === newBlockDetails.taskId);
        const shouldAssignTaskToUser = this.getTimelineTaskAssignmentSetting(task);

        if (shouldAssignTaskToUser) {
          let taskAssignedSuccessfully = await assignTaskToUser({
            task,
            user: selectedUser,
            organisationDetails,
          });

          if (!taskAssignedSuccessfully) {
            return;
          }
        }
      }

      let newTimelineBlocks = timelineBlocks.map((crtBlock) => {
        if (crtBlock.id !== blockId) {
          return crtBlock;
        }
        return {
          ...crtBlock,
          ...newBlockDetails,
        };
      });

      this.props.setProps({
        context: {
          ...context,
          timelineBlocks: newTimelineBlocks,
        },
      });

      const newBlock = (
        await callGraphQLSimple({
          message: "Failed to move block",
          queryName: "updateTimelineBlock",
          variables: {
            input: {
              id: blockId,
              ...newBlockDetails,
            },
          },
        })
      ).data.updateTimelineBlock;

      blockToReturn = newBlock;
      if (oldUserId !== selectedUser.id) {
        this.reflowTimeline({
          userId: oldUserId,
        });
      }
    }

    return blockToReturn;
  };

  getLocalRowX = (e) => {
    const userListWidth = document.querySelector(".user-list").clientWidth;
    const timelineScrollX = this.timelineRef.current.scrollLeft;
    const timelineScreenX = this.timelineRef.current.getBoundingClientRect().x;
    let pageX = e.pageX;
    if (e.touches) {
      pageX = e.touches[0].pageX;
    }
    const computedOffsetX = pageX - timelineScreenX + timelineScrollX - userListWidth;
    return Math.round(computedOffsetX);
  };

  createBlockOnDrop = async ({ e, selectedUser, draggableType, taskId }) => {
    const { planningStartDate, dayCellWidth, defaultTaskLengthHours, snapCoefficientDays, hoursInADay } = this.state;
    const { organisationDetails } = this.props;
    if (!taskId) {
      taskId = e.dataTransfer?.getData("task-id");
    }
    let taskRevisionId = e.dataTransfer?.getData("task-revision-id");
    if (!taskId || taskId === "") {
      return;
    }
    let dropX = this.getLocalRowX(e);
    let daysFromStart = dropX / dayCellWidth;

    let roundedDaysSincePlanningStart =
      Math.floor(daysFromStart * (1 / snapCoefficientDays)) / (1 / snapCoefficientDays);
    let planningStartDateMoment = moment(planningStartDate);

    let newBlock = {
      id: String(Date.now()) + String(Math.floor(Math.random() * 10000)),
      startDate: planningStartDateMoment.add(parseInt(roundedDaysSincePlanningStart), "day").format("YYYY-MM-DD"),
      startHours: (roundedDaysSincePlanningStart - parseInt(roundedDaysSincePlanningStart)) * hoursInADay,
      durationHours: Math.max(defaultTaskLengthHours, snapCoefficientDays * hoursInADay),
      taskId,
      taskRevisionId,
      userId: selectedUser.id,
      organisation: organisationDetails.id,
      createdAt: new Date().toISOString(),
      isPseudoTask: draggableType === "pseudo-task",
    };
    await this.addBlockToUser({ e, newBlock, selectedUser, draggableType });
    return newBlock;
  };

  getTimelineTaskAssignmentSetting = (task) => {
    const { organisationDetails } = this.props;

    const timelineTaskAssignmentSetting = organisationDetails.settings?.timeline?.shouldAssignTimelineTaskToUser;
    let shouldAssignTimelineTaskToUser;

    if (timelineTaskAssignmentSetting === "NEVER") {
      shouldAssignTimelineTaskToUser = false;
    }

    if (!task.assignedTo && timelineTaskAssignmentSetting === "TASK-UNASSIGNED") {
      shouldAssignTimelineTaskToUser = true;
    }

    if (!timelineTaskAssignmentSetting || timelineTaskAssignmentSetting === "ALWAYS") {
      shouldAssignTimelineTaskToUser = true;
    }

    return shouldAssignTimelineTaskToUser;
  };

  addBlockToUser = async ({ e, newBlock, selectedUser, draggableType, waitForResponse = true }) => {
    const { context, organisationDetails, tasks } = this.props;

    delete newBlock.createdAt;
    delete newBlock.updatedAt;

    let task = tasks.find((x) => x.id === newBlock.taskId);
    if (draggableType !== "pseudo-task" && task) {
      const shouldAssignTimelineTaskToUser = this.getTimelineTaskAssignmentSetting(task);

      if (shouldAssignTimelineTaskToUser) {
        let taskAssignedSuccessfully = await assignTaskToUser({
          task: tasks.find((x) => x.id === newBlock.taskId),
          user: selectedUser,
          organisationDetails,
        });
        if (!taskAssignedSuccessfully) {
          return;
        }
      }
    }

    this.props.setProps({
      context: {
        ...context,
        timelineBlocks: [...context.timelineBlocks, newBlock],
      },
    });

    let responsePromise = callGraphQLSimple({
      message: "Failed to create block",
      queryName: "createTimelineBlock",
      variables: {
        input: newBlock,
      },
    });

    if (waitForResponse) {
      await responsePromise;
    }

    try {
      e?.dataTransfer.clearData();
    } catch (e) {
      // nothing to do, this function can get called without being an actual 'drop' event
    }

    return true;
  };

  onRowDragOver = (e, user) => {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.userDragHighlight !== user.id) {
      this.setState({ userDragHighlight: user.id });
    }
  };

  changePlanningDateRange = async (dates) => {
    const { apiUser } = this.props;
    let planningStartDate = null;
    let planningEndDate = null;
    if (dates) {
      planningStartDate = dates[0].startOf("day").format("YYYY-MM-DD");
      planningEndDate = dates[1].endOf("day").format("YYYY-MM-DD");
    }

    const params = {
      organisation: apiUser.organisation,
      startDate: planningStartDate,
      endDate: planningEndDate,
    };
    await this.props.fetchAndSetTimelineBlocks(params);

    this.setState(
      {
        planningStartDate,
        planningEndDate,
      },
      this.buildDateList
    );
  };

  zoomIn = () => {
    const { zoomLevel } = this.state;
    if (zoomLevel >= ZOOM_LEVELS.length - 1) {
      return;
    }

    this.setZoomLevel(zoomLevel + 1);
  };

  zoomOut = () => {
    const { zoomLevel } = this.state;
    if (zoomLevel <= 0) {
      return;
    }

    this.setZoomLevel(zoomLevel - 1);
  };

  setZoomLevel = (zoomLevel) => {
    const { hoursInADay } = this.state;
    const { dayCellWidth, dayCellHeight, cellType, dayCellTaskIdFontSize } =
      ZOOM_LEVELS[zoomLevel] || ZOOM_LEVELS[TIMELINE_DEFAULT_ZOOM_LEVEL];

    window.timelineZoomLevel = zoomLevel;

    cookie.set(COOKIE_NAME_TIMELINE_RESTORED_ZOOM_LEVEL, zoomLevel);

    let oldZoomLevel = this.state.zoomLevel;

    this.setState({
      zoomLevel,
      dayCellWidth,
      dayCellHeight,
      hourBlockWidth: dayCellWidth / hoursInADay,
      cellType,
    });
    this.scrollToMatchZoomLevelChange({
      oldZoomLevel,
      newZoomLevel: zoomLevel,
    });

    document.documentElement.style.setProperty("--timeline-date-headers-height", `${dateHeadersHeight}px`);
    document.documentElement.style.setProperty("--timeline-day-cell-width", `${dayCellWidth}px`);
    document.documentElement.style.setProperty("--timeline-day-cell-height", `${dayCellHeight}px`);
    document.documentElement.style.setProperty("--timeline-day-cell-task-id", dayCellTaskIdFontSize);
  };

  scrollToMatchZoomLevelChange = async ({ oldZoomLevel, newZoomLevel }) => {
    if (
      oldZoomLevel === undefined ||
      newZoomLevel === undefined ||
      oldZoomLevel === null ||
      newZoomLevel === null ||
      oldZoomLevel === newZoomLevel
    ) {
      return;
    }
    const { timelineRef } = this;
    const oldTimelineWidth = this.calculateTimelineWidthAtZoomLevel(oldZoomLevel);
    const newTimelineWidth = this.calculateTimelineWidthAtZoomLevel(newZoomLevel);

    // calculate where the center of the viewport is
    const viewportCenter = timelineRef.current.scrollLeft + timelineRef.current.clientWidth / 2;

    // calculate the new scrollLeft value to center the viewport on the same date
    const newScrollLeft = (viewportCenter / oldTimelineWidth) * newTimelineWidth - timelineRef.current.clientWidth / 2;

    // await new Promise((resolve) => setTimeout(resolve, 50));

    smoothScrollTo({
      element: timelineRef.current,
      to: newScrollLeft,
      axis: "x",
      duration: 0,
    });
  };

  calculateTimelineWidthAtZoomLevel = (zoomLevel) => {
    const { planningStartDate, planningEndDate } = this.state;
    const dayBlockWidth = ZOOM_LEVELS[zoomLevel].dayCellWidth;
    const days = moment(planningEndDate).diff(moment(planningStartDate), "days");
    return days * dayBlockWidth;
  };

  getNonWorkingDaysForUser = ({ user, axisStartDate, axisEndDate }) => {
    let nonWorkingDays = [];
    let workingDays = [];
    (user?.workingHours || [])
      .filter((rule) => rule.repeatPattern?.length > 0)
      .forEach((rule) => {
        try {
          const rruleOptions = RRule.parseText(rule.repeatPattern);
          const startDate = moment(axisStartDate).add(moment().utcOffset(), "minutes").utc();
          const endDate = moment(axisEndDate).add(moment().utcOffset(), "minutes").utc();

          rruleOptions.dtstart = startDate.toDate();
          rruleOptions.until = endDate.toDate();
          const rrule = new RRule(rruleOptions);

          const occurences = rrule.all();
          workingDays.push(...occurences.map((dateObj) => moment(dateObj).format("YYYY-MM-DD")));
        } catch (e) {
          notification.error({
            message: (
              <Typography.Text>
                Failed to parse working hours rule:
                <br />
                <b>{rule.repeatPattern}</b>
              </Typography.Text>
            ),
          });
        }
      });

    let currentDay = moment(axisStartDate);
    while (currentDay.isSameOrBefore(axisEndDate, "day")) {
      const day = currentDay.format("YYYY-MM-DD");

      if (!workingDays.includes(day)) {
        nonWorkingDays.push(day);
      }
      currentDay.add(1, "day");
    }

    return nonWorkingDays;
  };

  getSortedTimelineBlocksForUser = ({ startingBlock, blocks, axisStartDate }) => {
    return JSON.parse(JSON.stringify(blocks)).sort((a, b) => {
      const aBounds = add1DPointsToBlock({ block: a, axisStartDate });
      const bBounds = add1DPointsToBlock({ block: b, axisStartDate });

      if (aBounds.start1D < bBounds.start1D) {
        return -1;
      }

      if (aBounds.start1D > bBounds.start1D) {
        return 1;
      }

      if (a.id === startingBlock.id) {
        return -1;
      }

      if (b.id === startingBlock.id) {
        return 1;
      }

      if (aBounds.updatedAt > bBounds.updatedAt) {
        return -1;
      } else if (aBounds.updatedAt > bBounds.updatedAt) {
        return 1;
      }

      return 0;
    });
  };

  getTimelineAfterMerging = ({ blocks, axisStartDate }) => {
    const { tasks } = this.props;
    let blockIdsToDelete = [];
    let newTimelineBlocks = JSON.parse(JSON.stringify(blocks));
    let atLeastOneMergeIsNeeded = true;
    // while (atLeastOneMergeIsNeeded) {
    //   atLeastOneMergeIsNeeded = false;
    //   for (let i = 0; i < newTimelineBlocks.length - 1; i++) {
    //     let block = newTimelineBlocks[i];
    //     let nextBlock = newTimelineBlocks[i + 1];
    //     const blockBounds = add1DPointsToBlock({ block, axisStartDate });
    //     const nextBlockBounds = add1DPointsToBlock({
    //       block: nextBlock,
    //       axisStartDate,
    //     });
    //     if (
    //       blockBounds.end1D === nextBlockBounds.start1D &&
    //       block.taskId === nextBlock.taskId &&
    //       block.taskRevisionId === nextBlock.taskRevisionId
    //     ) {
    //       let taskA = tasks.find((x) => x.id === block.taskId);
    //       let taskB = tasks.find((x) => x.id === nextBlock.taskId);

    //       if (!taskA || !taskB) {
    //         continue;
    //       }

    //       blockIdsToDelete.push(nextBlock.id);

    //       newTimelineBlocks[i].durationHours += nextBlock.durationHours;
    //       newTimelineBlocks.splice(i + 1, 1);
    //       atLeastOneMergeIsNeeded = true;
    //       break;
    //     }
    //   }
    // }

    return { newTimelineBlocks, blockIdsToDelete };
  };

  pushBlockToDesiredPosition = ({ block, desiredPosition1D, axisStartDate, restrictedIntervals }) => {
    let remainingHoursToAllocate = block.durationHours;
    let blocksToReturn = [];

    let currentDesiredPosition1D = desiredPosition1D;

    while (remainingHoursToAllocate > 0) {
      let nextAvailableInterval = this.getNextAvailableInterval({
        desiredPosition1D: currentDesiredPosition1D,
        desiredDurationHours: remainingHoursToAllocate,
        restrictedIntervals: restrictedIntervals.filter(
          (interval) => !interval.blockId || interval.blockId !== block.id
        ),
      });

      const nextAvailableIntervalStartDateAndHours = value1DToDateAndHours({
        value1D: nextAvailableInterval.start1D,
        axisStartDate,
      });

      let newBlockDurationHours = Math.min(remainingHoursToAllocate, nextAvailableInterval.durationHours);

      blocksToReturn.push({
        ...block,
        id: blocksToReturn.length === 0 ? block.id : String(Date.now()) + String(Math.floor(Math.random() * 10000)),
        startDate: nextAvailableIntervalStartDateAndHours.date,
        startHours: nextAvailableIntervalStartDateAndHours.hours,
        durationHours: newBlockDurationHours,
      });
      currentDesiredPosition1D = nextAvailableInterval.start1D + nextAvailableInterval.durationHours;
      remainingHoursToAllocate -= newBlockDurationHours;
    }

    return blocksToReturn;
  };

  getNextAvailableInterval = ({ desiredPosition1D, desiredDurationHours, restrictedIntervals }) => {
    let remainingRestrictedIntervals = [...restrictedIntervals];
    let nextAvailableInterval = {
      start1D: desiredPosition1D,
      durationHours: desiredDurationHours,
    };
    for (let i = 0; i < remainingRestrictedIntervals.length; i++) {
      const restrictedInterval = remainingRestrictedIntervals[i];
      if (nextAvailableInterval.start1D >= restrictedInterval.end1D) {
        continue;
      }

      if (nextAvailableInterval.start1D >= restrictedInterval.start1D) {
        nextAvailableInterval.start1D = restrictedInterval.end1D;
        continue;
      }

      if (nextAvailableInterval.start1D + nextAvailableInterval.durationHours > restrictedInterval.start1D) {
        nextAvailableInterval.durationHours = restrictedInterval.start1D - nextAvailableInterval.start1D;
        break;
      }
    }

    return nextAvailableInterval;
  };

  splitTargetBlockAfterInsertion = ({ block, splitDate, splitHours }) => {
    let axisStartDate = block.startDate;
    const blockBounds = add1DPointsToBlock({ block, axisStartDate });
    const splitPoint1D = dateAndHoursToValue1D({
      date: splitDate,
      hours: splitHours,
      axisStartDate,
    });
    const blockANewDurationHours = splitPoint1D - blockBounds.start1D;
    const blockBNewStartDateAndHours = value1DToDateAndHours({
      value1D: splitPoint1D,
      axisStartDate,
    });
    const blockBNewDurationHours = block.durationHours - blockANewDurationHours;

    return {
      newBlock: {
        ...block,
        id: String(Date.now()) + String(Math.floor(Math.random() * 10000)),
        durationHours: blockBNewDurationHours,
        startDate: blockBNewStartDateAndHours.date,
        startHours: blockBNewStartDateAndHours.hours,
        createdAt: new Date().toISOString(),
        updatedAt: undefined,
      },
      updatedBlock: {
        ...block,
        durationHours: blockANewDurationHours,
      },
    };
  };

  getBlockToSplitAfterDrop = ({ blocks, startingBlock, axisStartDate }) => {
    const startingBlockBounds = add1DPointsToBlock({
      block: startingBlock,
      axisStartDate,
    });
    return blocks.find((block) => {
      if (block.id === startingBlock.id) {
        return false;
      }

      if (block.isPseudoTask || block.isFixed) {
        return false;
      }

      const blockBounds = add1DPointsToBlock({ block, axisStartDate });
      let blockIsOverlappingSplitPoint =
        blockBounds.start1D < startingBlockBounds.start1D && blockBounds.end1D > startingBlockBounds.start1D;

      return blockIsOverlappingSplitPoint;
    });
  };

  getLastBlockEnd1D = ({ blocks, axisStartDate }) => {
    let last1D = 0;
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      const blockBounds = add1DPointsToBlock({ block, axisStartDate });
      if (blockBounds.end1D > last1D) {
        last1D = blockBounds.end1D;
      }
    }
    return last1D;
  };

  getRestrictedIntervals = ({ userId, axisStartDate, axisLength1D, startingBlock }) => {
    const { holidays, users, timelineBlocks, tasks } = this.props;

    const user = users.find((x) => x.id === userId);
    const { date: axisEndDateStr } = value1DToDateAndHours({
      value1D: axisLength1D,
      axisStartDate,
    });

    let pseudoTaskBlocksToUse = [];
    let holidayDaysToUse = [];

    const pseudoTaskBlocksForUser = timelineBlocks.filter((block) => {
      if (block.userId !== userId) {
        return false;
      }

      if (block.isFixed) {
        return true;
      }

      let task = tasks.find((task) => task.id === block.taskId);
      return !task;
    });

    pseudoTaskBlocksForUser.forEach((timelineBlock) => {
      if (moment(timelineBlock.startDate).isSameOrAfter(axisStartDate, "day")) {
        const start1D = dateAndHoursToValue1D({
          date: timelineBlock.startDate,
          hours: timelineBlock.startHours,
          axisStartDate,
        });
        pseudoTaskBlocksToUse.push({
          start1D,
          end1D: start1D + timelineBlock.durationHours,
          fromPseudoTasks: true,
          blockId: timelineBlock.id,
        });
      }
    });

    const nonWorkingDays = this.getNonWorkingDaysForUser({
      user,
      axisStartDate,
      axisEndDate: axisEndDateStr,
    });

    const holidaysForUser = holidays.filter((holiday) => holiday.userId === userId && holiday.status !== "REJECTED");

    holidaysForUser.forEach((holiday) => {
      holiday.days.forEach((holidayDay) => {
        if (moment(holidayDay.day).isSameOrAfter(axisStartDate, "day")) {
          const startHours = holidayDay.startHours || 0;
          const endHours = holidayDay.endHours || 0;

          holidayDaysToUse.push({
            start1D: dateAndHoursToValue1D({
              date: holidayDay.day,
              hours: startHours,
              axisStartDate,
            }),
            end1D: dateAndHoursToValue1D({
              date: holidayDay.day,
              hours: endHours,
              axisStartDate,
            }),
            fromTimeOff: true,
          });
        }
      });
    });

    let consolidatedIntervals = [...holidayDaysToUse, ...pseudoTaskBlocksToUse];

    nonWorkingDays.forEach((day) => {
      let dayInterval = {
        start1D: dateAndHoursToValue1D({
          date: day,
          hours: 0,
          axisStartDate,
        }),
        end1D: dateAndHoursToValue1D({
          date: day,
          hours: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
          axisStartDate,
        }),
        fromWorkingHours: true,
      };
      let dayIsAlreadyRestricted = consolidatedIntervals.some(
        (currentInterval) => currentInterval.start1D === dayInterval.start1D
      );
      if (!dayIsAlreadyRestricted) {
        consolidatedIntervals.push(dayInterval);
      }
    });

    consolidatedIntervals.sort((a, b) => a.start1D - b.start1D);

    return consolidatedIntervals;
  };

  getTimelineBlocksForUserFromStartingPoint = async ({ axisStartDate, startingBlock, userId }) => {
    const { organisationDetails } = this.props;
    return await fetchCollection({
      query: "listTimelineBlocksByUserAndDate",
      collectionName: "blocks for user",
      variables: {
        organisation: organisationDetails.id,
        limit: 1000,
        startDate: {
          ge: axisStartDate,
        },
        userId: userId || startingBlock.userId,
      },
    });
  };

  splitTargetBlockAfterInsertionIfNeeded = ({ axisStartDate, startingBlock, blocks }) => {
    const blockToSplit = this.getBlockToSplitAfterDrop({
      blocks,
      axisStartDate,
      startingBlock,
    });
    if (blockToSplit) {
      return this.splitTargetBlockAfterInsertion({
        block: blockToSplit,
        splitDate: startingBlock.startDate,
        splitHours: startingBlock.startHours,
      });
    }
  };

  getFirstBlockForUser = ({ userId, blocks }) => {
    const blocksForUser = blocks
      .filter((block) => block.userId === userId)
      .sort((a, b) => {
        if (a.startDate < b.startDate) {
          return -1;
        } else if (a.startDate > b.startDate) {
          return 1;
        }

        if (a.startHours < b.startHours) {
          return -1;
        } else if (a.startHours > b.startHours) {
          return 1;
        }

        return 0;
      });

    return blocksForUser[0];
  };

  enhanceTimelineBlocksWithPseudoTaskFlag = ({ blocks }) => {
    const { tasks } = this.props;
    return blocks.map((block) => {
      let task = tasks.find((task) => task.id === block.taskId);
      return {
        ...block,
        isPseudoTask: !task,
      };
    });
  };

  reflowTimeline = async (params) => {
    let { userId, startingBlock, sortedBlocks, restrictedIntervals, blockIdsToDelete = [] } = params;
    const { organisationDetails } = this.props;
    const { planningStartDate } = this.state;

    if (startingBlock && !userId) {
      userId = startingBlock.userId;
    }

    let validAndFilteredUsers = this.getValidAndFilteredUsers();
    let userDetails = validAndFilteredUsers.find((user) => user.id === userId);

    if (
      (!userId && !startingBlock) ||
      !planningStartDate ||
      !organisationDetails.settings?.timeline?.usesPhysicalBlockInteraction
    ) {
      return;
    }

    if (!startingBlock) {
      startingBlock = this.getFirstBlockForUser({
        userId,
        blocks: this.props.timelineBlocks,
      });
      if (!startingBlock) {
        return;
      }
    }

    const axisStartDate = moment(planningStartDate); //.subtract(2, "weeks"); //moment(startingBlock.startDate).subtract(1, "week").format("YYYY-MM-DD");
    let timelineBlocksForUserBeforeProcessing = sortedBlocks;
    if (!timelineBlocksForUserBeforeProcessing) {
      timelineBlocksForUserBeforeProcessing = await this.getTimelineBlocksForUserFromStartingPoint({
        axisStartDate: moment(axisStartDate),
        startingBlock,
      });
    }

    const timelineBlocksWithPseudoTaskFlag = this.enhanceTimelineBlocksWithPseudoTaskFlag({
      blocks: timelineBlocksForUserBeforeProcessing,
    });

    const timelineBlocksForUser = timelineBlocksWithPseudoTaskFlag.map((block) => {
      // we need to do this in order to ensure that we have the latest version of the starting block (i.e. the block we've just edited),
      // rather than the one that was retrieved from the API
      if (block.id === startingBlock.id) {
        return startingBlock;
      }

      return block;
    });

    const lastBlockEnd1D = this.getLastBlockEnd1D({
      blocks: timelineBlocksForUser,
      axisStartDate: moment(axisStartDate),
    });

    if (userDetails?.isStockItem) {
      restrictedIntervals = [];
    } else {
      restrictedIntervals =
        restrictedIntervals ||
        this.getRestrictedIntervals({
          userId: startingBlock.userId,
          axisStartDate: moment(axisStartDate),
          axisLength1D: lastBlockEnd1D + TIMELINE_DEFAULT_HOURS_IN_A_DAY * 10,
          startingBlock,
        });
    }

    if (timelineBlocksForUser.length === 0) {
      return;
    }

    const splitResult = this.splitTargetBlockAfterInsertionIfNeeded({
      blocks: timelineBlocksForUser,
      axisStartDate: moment(axisStartDate),
      startingBlock,
    });

    if (splitResult) {
      const { updatedBlock, newBlock } = splitResult;
      timelineBlocksForUser.forEach((block) => {
        if (block.id === updatedBlock.id) {
          block.durationHours = updatedBlock.durationHours;
        }
      });
      timelineBlocksForUser.push(newBlock);
    }

    let sortedTimelineBlocksForUser = this.getSortedTimelineBlocksForUser({
      blocks: timelineBlocksForUser,
      startingBlock,
      axisStartDate: moment(axisStartDate),
    });

    let { newTimelineBlocks: blocksAfterMergeA, blockIdsToDelete: blockIdsToDeleteA } = this.getTimelineAfterMerging({
      blocks: sortedTimelineBlocksForUser,
      startingBlock,
      axisStartDate: moment(axisStartDate),
    });
    sortedTimelineBlocksForUser = blocksAfterMergeA;

    sortedTimelineBlocksForUser = sortedTimelineBlocksForUser.map((block) => ({
      ...block,
      originalStart1D: dateAndHoursToValue1D({
        date: block.startDate,
        hours: block.startHours,
        axisStartDate: moment(axisStartDate),
      }),
    }));

    sortedTimelineBlocksForUser = this.moveBlocksOutOfTheWay({
      blocks: sortedTimelineBlocksForUser,
      startingBlock,
      axisStartDate: moment(axisStartDate),
      restrictedIntervals,
    });

    // we need to call this again because after moving things around, we may have blocks which need to be merged together
    let { newTimelineBlocks: blocksAfterMergeB, blockIdsToDelete: blockIdsToDeleteB } = this.getTimelineAfterMerging({
      blocks: sortedTimelineBlocksForUser,
      axisStartDate: moment(axisStartDate),
    });

    sortedTimelineBlocksForUser = blocksAfterMergeB;

    await this.fillGaps({
      blocks: sortedTimelineBlocksForUser,
      restrictedIntervals,
      axisStartDate,
      blockIdsToDelete: [...blockIdsToDelete, ...blockIdsToDeleteB, ...blockIdsToDeleteA],
      userId: startingBlock.userId,
    });
  };

  fillGaps = async ({ blocks, restrictedIntervals, axisStartDate, blockIdsToDelete, userId }) => {
    const { organisationDetails } = this.props;

    const blocksWithPseudoTaskFlag = this.enhanceTimelineBlocksWithPseudoTaskFlag({
      blocks: blocks,
    });
    let blocksForRealTasks = blocksWithPseudoTaskFlag.filter((block) => !block.isPseudoTask && !block.isFixed);

    if (!organisationDetails.settings?.timeline?.usesGapFilling) {
      await this.setStateAndMakeApiChangesAfterReflow({
        updatedBlocks: blocksForRealTasks,
        blockIdsToDelete,
        axisStartDate,
        userId,
      });
      return;
    }

    let blocksForUnusedHours = blocksForRealTasks.map((block) => {
      return add1DPointsToBlock({
        block,
        axisStartDate: moment(axisStartDate),
      });
    });
    const startOfToday = moment();
    const startOfToday1D = dateAndHoursToValue1D({
      date: startOfToday,
      hours: 0,
      axisStartDate,
    });
    let unusedHours = this.getUnusedHours({
      blocks: blocksForUnusedHours,
      axisStartDate,
      restrictedIntervals,
    }).filter((hour) => hour >= startOfToday1D);

    if (unusedHours.length > 0) {
      let firstBlockAfterUnusedFirstHour = this.getFirstBlockAfterUnusedHour({
        unusedHour: unusedHours[0],
        blocks: blocksForUnusedHours,
      });
      let desiredStartDateAndHours = value1DToDateAndHours({
        value1D: unusedHours[0],
        axisStartDate,
      });

      this.reflowTimeline({
        startingBlock: {
          ...firstBlockAfterUnusedFirstHour,
          startDate: desiredStartDateAndHours.date,
          startHours: desiredStartDateAndHours.hours,
        },
        restrictedIntervals,
        sortedBlocks: blocksForRealTasks,
        blockIdsToDelete,
      });
    } else {
      let firstBlockThatOverlapsRestrictedIntervals = this.getFirstBlockThatOverlapsRestrictedIntervals({
        blocks: blocksForRealTasks,
        axisStartDate,
        restrictedIntervals,
      });

      let startingBlockForReflow = firstBlockThatOverlapsRestrictedIntervals;

      if (!startingBlockForReflow) {
        let firstBlockThatOverlapsAnotherBlock = this.getFirstBlockThatOverlapsAnotherBlock({
          blocks: blocksForRealTasks,
          axisStartDate,
        });
        startingBlockForReflow = firstBlockThatOverlapsAnotherBlock;
      }

      if (startingBlockForReflow) {
        this.reflowTimeline({
          startingBlock: startingBlockForReflow,
          restrictedIntervals,
          sortedBlocks: blocksForRealTasks,
          blockIdsToDelete,
        });
      } else {
        await this.setStateAndMakeApiChangesAfterReflow({
          updatedBlocks: blocksForRealTasks,
          blockIdsToDelete,
          axisStartDate,
          userId,
        });
      }
    }
  };

  getFirstBlockThatOverlapsRestrictedIntervals = ({ blocks, axisStartDate, restrictedIntervals }) => {
    for (let i = 0; i < blocks.length; i++) {
      const blockBounds = add1DPointsToBlock({
        block: blocks[i],
        axisStartDate,
      });

      let blockOverlapsAnInterval = restrictedIntervals.some((interval) => {
        let overlap = blockBounds.start1D < interval.end1D && blockBounds.end1D > interval.start1D;
        return overlap;
      });
      if (blockOverlapsAnInterval) {
        return blocks[i];
      }
    }
  };

  getFirstBlockThatOverlapsAnotherBlock = ({ blocks, axisStartDate }) => {
    let sortedBlocks = [...blocks].map((block) => {
      const blockBounds = add1DPointsToBlock({
        block,
        axisStartDate,
      });
      return {
        ...block,
        start1D: blockBounds.start1D,
        end1D: blockBounds.end1D,
      };
    });

    let result = sortedBlocks.find((currentBlock, index) => {
      let nextBlock = sortedBlocks[index + 1];
      if (nextBlock) {
        return currentBlock.end1D > nextBlock.start1D && nextBlock.end1D > currentBlock.start1D;
      }
      return false;
    });
    return result;
  };

  setStateAndMakeApiChangesAfterReflow = async ({ updatedBlocks, blockIdsToDelete, axisStartDate, userId }) => {
    const { context, setProps, timelineBlocks: stateTimelineBlocks } = this.props;

    let newBlocks = updatedBlocks.filter((block) => {
      return !stateTimelineBlocks.some((x) => x.id === block.id);
    });

    let stateTimelineBlocksUpdated = stateTimelineBlocks
      .filter((block) => !blockIdsToDelete.includes(block.id))
      .map((block) => {
        let blockInUpdatedBlocks = updatedBlocks.find((x) => x.id === block.id);

        return blockInUpdatedBlocks || block;
      });

    setProps({
      context: {
        ...context,
        timelineBlocks: [...stateTimelineBlocksUpdated, ...newBlocks],
      },
    });

    const timelineBlocksForUser = await this.getTimelineBlocksForUserFromStartingPoint({
      axisStartDate: moment(axisStartDate),
      userId,
    });
    let apiRequestPromises = blockIdsToDelete.map((blockId) => {
      return callGraphQLSimple({
        mutation: "deleteTimelineBlock",
        variables: {
          input: {
            id: blockId,
          },
        },
        displayError: false,
      });
    });
    for (let i = 0; i < updatedBlocks.length; i++) {
      const updatedBlock = updatedBlocks[i];
      let existingBlock = timelineBlocksForUser.find((block) => block.id === updatedBlock.id);
      if (!existingBlock) {
        await new Promise((resolve) => setTimeout(resolve, 70));
        apiRequestPromises.push(
          callGraphQLSimple({
            message: "Failed to create time block",
            mutation: "createTimelineBlock",
            variables: {
              input: this.getPropertiesFromBlock({
                block: updatedBlock,
                properties: DEFAULT_TIMELINE_BLOCK_PROPERTIES,
              }),
            },
            // displayError: false,
          })
        );
      } else {
        if (
          existingBlock.startDate !== updatedBlock.startDate ||
          existingBlock.startHours !== updatedBlock.startHours ||
          existingBlock.durationHours !== updatedBlock.durationHours
        ) {
          await new Promise((resolve) => setTimeout(resolve, 70));
          apiRequestPromises.push(
            callGraphQLSimple({
              message: "Failed to update time block",
              mutation: "updateTimelineBlock",
              variables: {
                input: this.getPropertiesFromBlock({
                  block: updatedBlock,
                  properties: DEFAULT_TIMELINE_BLOCK_PROPERTIES,
                }),
              },
              // displayError: false,
            })
          );
        }
      }
    }

    await Promise.all(apiRequestPromises);
  };

  getPropertiesFromBlock = ({ block, properties }) => {
    let result = {};
    properties.forEach((property) => {
      result[property] = block[property];
    });
    return result;
  };

  getFirstBlockAfterUnusedHour = ({ unusedHour, blocks }) => {
    let targetBlock;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].start1D >= unusedHour) {
        targetBlock = blocks[i];
        break;
      }
    }
    return targetBlock;
  };

  getUnusedHours = ({ blocks, restrictedIntervals }) => {
    let usedHours = [];
    let lastUsedNotRestrictedHour;
    restrictedIntervals.forEach((interval) => {
      for (let i = interval.start1D; i < interval.end1D; i++) {
        let hourAlreadyExists = usedHours.includes(i);
        if (!hourAlreadyExists) {
          usedHours.push(i);
        }
      }
    });
    blocks.forEach((block) => {
      for (let i = block.start1D; i < block.end1D; i++) {
        let hourAlreadyExists = usedHours.includes(i);
        if (!hourAlreadyExists) {
          usedHours.push(i);
        }
        if (i > (lastUsedNotRestrictedHour || 0)) {
          lastUsedNotRestrictedHour = i;
        }
      }
    });
    usedHours.sort((a, b) => (a < b ? -1 : 1));
    let unusedHours = [];

    for (let i = 0; i < lastUsedNotRestrictedHour; i++) {
      if (!usedHours.includes(i)) {
        unusedHours.push(i);
      }
    }
    return unusedHours;
  };

  /**
   Updates the blocks array in place with new coordinates
   This function also triggers the splitting of blocks based on restricted intervals, thus distributing bits of them wherever they can fit
   */
  moveBlocksOutOfTheWay = ({ blocks, startingBlock, axisStartDate, restrictedIntervals }) => {
    let blockIdsToUpdate = [];

    let startingBlockIndexInBlocks = blocks.findIndex((block) => block.id === startingBlock.id);

    if (startingBlockIndexInBlocks !== -1) {
      let startingBlockMatchInBlocks = blocks[startingBlockIndexInBlocks];
      let startingBlockStart1D = dateAndHoursToValue1D({
        date: startingBlockMatchInBlocks.startDate,
        hours: startingBlockMatchInBlocks.startHours,
        axisStartDate,
      });

      const [firstBlockNewDetails, ...newBlocksSplitFromNextBlock] = this.pushBlockToDesiredPosition({
        block: startingBlockMatchInBlocks,
        desiredPosition1D: startingBlockStart1D,
        axisStartDate,
        restrictedIntervals,
      });

      startingBlockMatchInBlocks.startDate = firstBlockNewDetails.startDate;
      startingBlockMatchInBlocks.startHours = firstBlockNewDetails.startHours;
      startingBlockMatchInBlocks.durationHours = firstBlockNewDetails.durationHours;
      blockIdsToUpdate.push(startingBlockMatchInBlocks.id);

      if (newBlocksSplitFromNextBlock.length > 0) {
        for (let j = 0; j < newBlocksSplitFromNextBlock.length; j++) {
          blocks.splice(startingBlockIndexInBlocks + 1 + j, 0, newBlocksSplitFromNextBlock[j]);
        }
      }
    }

    for (let i = 0; i < blocks.length - 1; i++) {
      const currentBlock = blocks[i];
      const nextBlock = blocks[i + 1];

      // for blocks associated with pseudo-tasks, we don't want to move them out of the way
      if (nextBlock.isPseudoTask || nextBlock.isFixed) {
        continue;
      }

      const blockBounds = add1DPointsToBlock({
        block: currentBlock,
        axisStartDate,
      });
      const nextBlockBounds = add1DPointsToBlock({
        block: nextBlock,
        axisStartDate,
      });

      const aStart = blockBounds.originalStart1D;
      const aEnd = blockBounds.end1D;

      const bStart = nextBlockBounds.originalStart1D;
      const bEnd = nextBlockBounds.end1D;

      let nextBlockNeedsUpdating = aEnd > bStart && bEnd > aStart;

      if (nextBlockNeedsUpdating) {
        const [nextBlockNewDetails, ...newBlocksSplitFromNextBlock] = this.pushBlockToDesiredPosition({
          block: nextBlock,
          desiredPosition1D: aEnd,
          axisStartDate,
          restrictedIntervals,
        });

        nextBlock.startDate = nextBlockNewDetails.startDate;
        nextBlock.startHours = nextBlockNewDetails.startHours;
        nextBlock.durationHours = nextBlockNewDetails.durationHours;
        blockIdsToUpdate.push(nextBlock.id);
        if (newBlocksSplitFromNextBlock.length > 0) {
          for (let j = 0; j < newBlocksSplitFromNextBlock.length; j++) {
            blocks.splice(i + 2 + j, 0, newBlocksSplitFromNextBlock[j]);
          }
        }
      }
    }

    return blocks;
  };

  displayControls = () => {
    const { planningStartDate, planningEndDate, zoomLevel } = this.state;
    const { organisationDetails } = this.props;
    const filteredPseudoTasks = TIMELINE_PSEUDO_TASKS.filter((pseudoTask) => {
      if ((pseudoTask === "HOLIDAY" || pseudoTask === "SICK") && organisationDetails?.settings?.general?.usesTimeOff) {
        return false;
      }

      return true;
    });

    return (
      <div className="controls">
        <Dropdown
          trigger={["click"]}
          overlayClassName="timeline-pseudo-tasks-dropdown-overlay"
          placement="bottomCenter"
          overlay={
            <Menu data-cy="menu-pseudo-tasks">
              {filteredPseudoTasks.map((pseudoTaskName, index) => {
                return (
                  <React.Fragment key={index}>
                    <Menu.Item
                      key={index}
                      data-cy="pseudo-task-menu-item"
                      data-task-id={pseudoTaskName}
                      draggable
                      className="special-task-menu-item"
                      onDragStart={(e) => {
                        e.dataTransfer.setDragImage(e.currentTarget, 0, 0);
                        e.dataTransfer.setData("task-id", pseudoTaskName);
                        e.dataTransfer.setData("draggable-type", "pseudo-task");
                      }}
                    >
                      <div>
                        <Typography.Text>
                          <b>{pseudoTaskName}</b>
                        </Typography.Text>
                      </div>
                    </Menu.Item>
                    {index < TIMELINE_PSEUDO_TASKS.length - 1 ? <Menu.Divider /> : null}
                  </React.Fragment>
                );
              })}
            </Menu>
          }
        >
          <Button className="pseudo-tasks-button" icon={<DownOutlined />} data-cy="pseudo-tasks-button">
            Special {getSimpleLabel("tasks")}
          </Button>
        </Dropdown>

        <div className="setting">
          <DatePicker.RangePicker
            format="DD-MM-YYYY"
            dropdownClassName="timeline-window-date-range-picker"
            onChange={this.changePlanningDateRange}
            allowClear={false}
            value={[planningStartDate && moment(planningStartDate), planningEndDate && moment(planningEndDate)]}
            ranges={{
              "Last 4 weeks": [moment().startOf("week").subtract(4, "weeks"), moment()],
              "This week": [moment().startOf("week"), moment().startOf("week").endOf("week")],
              "Following week": [
                moment().startOf("week").add(1, "weeks"),
                moment().startOf("week").add(1, "weeks").endOf("week"),
              ],
              "Next 2 weeks": [moment().startOf("week"), moment().startOf("week").add(1, "weeks").endOf("week")],
              "Next 4 weeks": [moment().startOf("week"), moment().startOf("week").add(4, "weeks").endOf("week")],
              "This month": [moment().startOf("month"), moment().startOf("month").endOf("month")],
              "This month and next": [
                moment().startOf("month"),
                moment().startOf("month").add(1, "month").endOf("month"),
              ],
            }}
          />
        </div>
        <div className="setting">
          <div className="zoom-controls">
            <Button icon={<PlusOutlined />} onClick={this.zoomIn} disabled={zoomLevel >= ZOOM_LEVELS.length - 1} />
            <Button icon={<MinusOutlined />} onClick={this.zoomOut} disabled={zoomLevel <= 0} />
          </div>
        </div>

        <Button
          className="settings-button"
          onClick={() => this.setState({ areSettingsVisible: true })}
          icon={<DownOutlined />}
        >
          {getSimpleLabel("Timeline")} filters
        </Button>
      </div>
    );
  };

  displayUserList = ({ validUsers }) => {
    const { zoomLevel } = this.state;
    const { organisationDetails } = this.props;

    let shouldDisplayCompactUserList = zoomLevel < 3 && !organisationDetails.settings?.stock?.usesStock;

    return (
      <div className={cx("user-list", { small: shouldDisplayCompactUserList })}>
        <Typography.Text className="title">Users</Typography.Text>
        {validUsers.map((user) => {
          let avatarElement = null;
          if (zoomLevel >= 3) {
            avatarElement = <Avatar user={user} showLabel />;
          } else {
            if (organisationDetails.settings?.stock?.usesStock) {
              avatarElement = <Avatar user={user} showImage={false} showLabel />;
            } else {
              avatarElement = <Avatar user={user} />;
            }
          }

          return (
            <div
              className={cx("user-item", {
                tiny: zoomLevel < 2,
                "is-stock-item": user.isStockItem,
              })}
              key={user.id}
              onClick={() => {
                if (user.isStockItem) {
                  // go to stock item page in a new tab
                  window.open(`/stock-items/${user.id}`, "_blank");
                }
              }}
            >
              {user.isStockItem ? <div className="stock-item-name">{user.name}</div> : avatarElement}
            </div>
          );
        })}
      </div>
    );
  };

  isRectangleInViewport({ top, left, width, height }) {
    const { timelineScrollLeft, timelineScrollTop, timelineContainerWidth, timelineContainerHeight } = this.state;

    if (left !== undefined && width !== undefined) {
      if (
        left + width + WINDOWING_MARGIN_HORIZONTAL < timelineScrollLeft ||
        left - WINDOWING_MARGIN_HORIZONTAL > timelineScrollLeft + timelineContainerWidth
      ) {
        return false;
      }
    }

    if (top !== undefined && height !== undefined) {
      if (
        top + height + WINDOWING_MARGIN_VERTICAL < timelineScrollTop ||
        top - WINDOWING_MARGIN_VERTICAL > timelineScrollTop + timelineContainerHeight
      ) {
        return false;
      }
    }
    return true;
  }

  displayTimeline = ({ validUsers }) => {
    const {
      dates,
      dayCellHeight,
      dayCellWidth,
      planningStartDate,
      planningEndDate,
      dayStartHours,
      hoursInADay,
      zoomLevel,
    } = this.state;

    const now = moment();
    const daysSincePlanningStart = now.diff(moment(planningStartDate), "days");
    const nowHours = now.hours();
    const todayPercentage = Math.max(Math.min((nowHours - dayStartHours) / hoursInADay, 0.99), 0);

    const nowPosition = dayCellWidth * daysSincePlanningStart + todayPercentage * dayCellWidth;
    let shouldDisplayNowMarker = now.isBetween(moment(planningStartDate), moment(planningEndDate), "day", "[]");

    let rightNowMarker = null;

    if (shouldDisplayNowMarker) {
      rightNowMarker = (
        <div
          className="right-now-marker"
          style={{
            height: validUsers.length * dayCellHeight,
            left: nowPosition || 0,
          }}
        />
      );
    }

    return (
      <div className="timeline" style={{ height: validUsers.length * dayCellHeight }}>
        <div
          className="date-headers"
          style={{ width: dayCellWidth * dates.length }}
          onMouseDown={(e) => {
            this.dragState.lastX = e.pageX;
            this.setState({
              startTimelineScrollLeft: this.timelineRef.current ? this.timelineRef.current.scrollLeft : null,
              dragStartCoordinates: { x: e.pageX, y: e.pageY },
            });
          }}
        >
          {dates.map((day, index) => {
            let style = {
              left: dayCellWidth * index,
            };

            return (
              <Typography.Text className="day" key={day.date} style={style}>
                <span className="label-month">{zoomLevel === 0 ? day.labelMonthShort : day.labelMonth}</span>
                <span className="label-day">{day.labelDay}</span>
              </Typography.Text>
            );
          })}
        </div>

        {validUsers.map((user, index) => {
          const { userDragHighlight, planningStartDate, planningEndDate, isDraggingBlock } = this.state;
          const { timelineBlocks, projects } = this.props;

          let rowStyle = {
            top: dayCellHeight * index + dateHeadersHeight,
            width: dayCellWidth * dates.length,
            height: dayCellHeight,
            left: 0,
          };

          let isVisible = this.isRectangleInViewport({
            top: rowStyle.top,
            left: rowStyle.left,
            width: rowStyle.width,
            height: rowStyle.height,
          });

          if (!isVisible) {
            return null;
          }

          return (
            <TimelineRow
              key={user.id}
              user={user}
              style={rowStyle}
              tasks={this.props.tasks}
              planningStartDate={planningStartDate}
              planningEndDate={planningEndDate}
              isDraggingBlock={isDraggingBlock}
              timelineBlocks={timelineBlocks}
              projects={projects}
              dragState={this.dragState}
              setTimelineState={(params, cb) => this.setState(params, cb)}
              pasteBlock={this.pasteBlock}
              blockInClipboard={this.state.blockInClipboard}
              onRowDrop={this.onRowDrop}
              onRowDragOver={this.onRowDragOver}
              timelineRef={this.timelineRef}
              eventForLastClick={this.state.eventForLastClick}
              showLockedModal={this.showLockedModal}
              removeBlock={this.removeBlock}
              renameBlock={this.renameBlock}
              getPropertiesFromBlock={this.getPropertiesFromBlock}
              organisationDetails={this.props.organisationDetails}
              reflowTimeline={this.reflowTimeline}
              holidays={this.props.holidays}
              getNonWorkingDaysForUser={this.getNonWorkingDaysForUser}
              addBlockToUser={this.addBlockToUser}
              validUsers={validUsers}
              rowIndex={index}
              propsForTimelineBlock={{
                userDragHighlight,
                tasksWithRevisions: this.state.tasksWithRevisions,
                setProps: this.props.setProps,
                context: this.props.context,
                hoursInADay: this.state.hoursInADay,
                snapCoefficientDays: this.state.snapCoefficientDays,
                isTaskDetailsModalVisible: this.state.isTaskDetailsModalVisible,
                isLocked: this.state.isLocked,
                hourBlockWidth: this.state.hourBlockWidth,
                filterClientId: this.state.filterClientId,
                filterProjectId: this.state.filterProjectId,
                filterTaskId: this.state.filterTaskId,
                showPseudoTasks: this.state.showPseudoTasks,
                zoomLevel: this.state.zoomLevel,
                showTaskIDs: this.state.showTaskIDs,
                showTaskTitles: this.state.showTaskTitles,
                showProjectTitles: this.state.showProjectTitles,
                dayCellWidth: this.state.dayCellWidth,
                windowWidth: this.props.windowWidth,
                selectedBlock: this.state.selectedBlock,
                selectedBlockForTooltip: this.state.selectedBlockForTooltip,
                planningStartDate,
                planningEndDate,
              }}
              isRectangleInViewport={(params) => this.isRectangleInViewport(params)}
            />
          );
        })}

        {rightNowMarker}
      </div>
    );
  };

  getValidUsers = () => {
    const { organisationDetails, orderedActiveUsers, stockItems } = this.props;
    let validUsers = orderedActiveUsers.filter((x) => !x.isHidden);

    let hasStockItems = false;

    if (organisationDetails.settings?.stock?.usesStock) {
      let stockItemsToDisplay = stockItems
        .filter((stockItem) => stockItem.displayOnTimeline)
        .map((stockItem) => {
          return {
            ...stockItem,
            isStockItem: true,
          };
        });
      if (stockItemsToDisplay.length > 0) {
        hasStockItems = true;
      }
      validUsers = [...validUsers, ...stockItemsToDisplay];
    }
    return { validUsers, hasStockItems };
  };

  getValidAndFilteredUsers = () => {
    const { validUsers } = this.getValidUsers();
    const { userRows } = this.state;

    return validUsers.filter((user) => {
      if (user.isStockItem) {
        return true;
      }
      return userRows?.some((userId) => userId === user.id);
    });
  };

  render() {
    const { organisationDetails, timelineBlocks, windowWidth, clients } = this.props;
    const {
      dates,
      cellType,
      isResizingBlock,
      isDraggingBlock,
      planningStartDate,
      planningEndDate,
      isTaskDetailsModalVisible,
      selectedBlock,
      selectedTaskId,
      snapCoefficientDays,
      hoursInADay,
      defaultTaskLengthHours,
      areSettingsVisible,
      dayCellWidth,
      filterClientId,
      filterProjectId,
      filterTaskId,
      showPseudoTasks,
      showTaskIDs,
      showTaskTitles,
      showProjectTitles,
      zoomLevel,
      filteredTasks,
      userRows,
    } = this.state;

    if (!dates) {
      return null;
    }

    let isLocked = !isAuthorised(["TIMELINE.EDIT"]);

    const { validUsers, hasStockItems } = this.getValidUsers();

    let validAndFilteredUsers = validUsers;
    if (userRows && userRows.length > 0) {
      validAndFilteredUsers = this.getValidAndFilteredUsers();
    }

    return (
      <div className={cx("timeline-page", `zoom-level-${zoomLevel}`, { "has-stock-items": hasStockItems })}>
        <TaskFilters
          onChange={(filter) => this.setState({ filter })}
          windowWidth={windowWidth}
          includeCreateTask={false}
          previousFilter={this.state.filter}
          cookieName="task-filters-timeline-page"
          avatarListWidthToSubtract={160 + 100 + 660 + 130}
          label={`${getSimpleLabel("Task")} filters`}
        />
        <div
          className={cx("timeline-view", `cell-type-${cellType}`, {
            "is-resizing-block": isResizingBlock,
            "is-dragging-block": isDraggingBlock,
            "is-modal-visible": this.state.isModalVisible,
            "is-locked": isLocked,
          })}
        >
          {windowWidth > 850 && (
            <UnplannedTaskList
              tasks={filteredTasks}
              organisationDetails={organisationDetails}
              planningStartDate={planningStartDate}
              planningEndDate={planningEndDate}
              timelineBlocks={timelineBlocks}
              onOpenTask={(selectedTaskId) => this.setState({ selectedTaskId, isTaskDetailsModalVisible: true })}
            />
          )}

          <div className="main-view-and-controls">
            {this.displayControls()}

            <div className="main-view" ref={this.timelineRef} onScroll={this.onDebouncedAndThrottledTimelineScroll}>
              {/* <div className="main-view" ref={this.timelineRef}> */}
              {this.displayUserList({ validUsers: validAndFilteredUsers })}
              {this.displayTimeline({ validUsers: validAndFilteredUsers })}
            </div>
            {organisationDetails.settings?.timeline?.usesColoredBlocks && <TimelineLegend windowWidth={windowWidth} />}
          </div>
        </div>
        {isTaskDetailsModalVisible && (selectedBlock || selectedTaskId) && (
          <TaskDetailsModal
            taskId={selectedTaskId || selectedBlock.taskId}
            onClose={() => {
              this.setState({
                isTaskDetailsModalVisible: false,
                selectedBlock: null,
                selectedTaskId: null,
              });
            }}
          />
        )}
        <TimelineSettings
          visible={this.state.areSettingsVisible}
          onClose={() => this.setState({ areSettingsVisible: false })}
          snapCoefficientDays={snapCoefficientDays}
          hoursInADay={hoursInADay}
          defaultTaskLengthHours={defaultTaskLengthHours}
          areSettingsVisible={areSettingsVisible}
          dayCellWidth={dayCellWidth}
          filterClientId={filterClientId}
          filterProjectId={filterProjectId}
          filterTaskId={filterTaskId}
          showPseudoTasks={showPseudoTasks}
          showTaskIDs={showTaskIDs}
          showTaskTitles={showTaskTitles}
          showProjectTitles={showProjectTitles}
          clients={clients}
          defaultDateRange={this.state.defaultDateRange}
          setState={(state) => this.setState(state)}
          changePlanningDateRange={this.changePlanningDateRange}
          userRows={userRows}
          windowWidth={windowWidth}
          validUsers={validUsers}
          organisationDetails={organisationDetails}
        />
        {this.state.isNewTimelineBlockModalVisible && (
          <NewTimelineBlockModal
            onSubmit={this.onNewTimelineBlockModalSubmit}
            apiUser={this.props.apiUser}
            onClose={() => {
              this.setState({
                isNewTimelineBlockModalVisible: false,
                selectedUserForLastClick: undefined,
                eventForLastClick: undefined,
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default withSubscriptions({
  Component: withRouter(TimelinePage),
  subscriptions: [
    "clients",
    "projects",
    "apiUser",
    "users",
    "organisationDetails",
    "timelineBlocks",
    "orderedActiveUsers",
    "holidays",
    "stockItems",
  ],
});
