import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";
import { deleteRequestAndRemoveFromTasks } from "common/requestHelpers";

import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

type Props = {
  request: any;
  organisationDetails: any;
  apiUser: any;
  activityItems: any[] | undefined;
};

export default function DeleteRequestButton({ request, organisationDetails, apiUser, activityItems }: Props) {
  const history = useHistory();
  async function onDeleteClick() {
    if (request.projectId) {
      Modal.error({
        title: `Cannot delete ${getSimpleLabel("request")}`,
        content: `This request is associated with at least 1 ${getSimpleLabel(
          "task"
        )}, therefore it cannot be deleted.`,
        okText: "OK",
      });
      return;
    }

    Modal.confirm({
      title: `Are you sure you want to delete this ${getSimpleLabel("request")} ?`,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "delete-request-modal",
      onOk: async () => {
        deleteRequestAndRemoveFromTasks({
          apiUser,
          organisationDetails,
          request,
          activityItems,
          history,
        });
      },
    });
  }

  return (
    <ButtonWithPermissions
      permissions={["REQUESTS.DELETE"]}
      className="delete-request-button"
      icon={<DeleteOutlined />}
      onClick={onDeleteClick}
      type="primary"
    >
      Delete {getSimpleLabel("request")}
    </ButtonWithPermissions>
  );
}
