import { Tag } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { processIdForDisplay } from "common/shared";
import { isAuthorised } from "common/permissions";
import { sendRequestAssignedNotification } from "common/notificationHelpers";

import InfoItem from "InfoItem/InfoItem";
import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import ClientLogo from "ClientLogo/ClientLogo";
import UsersFilter from "UsersFilter/UsersFilter";
import DeleteRequestButton from "pages/RequestDetailsPage/DeleteRequestButton/DeleteRequestButton";
import RequestStatusPicker from "pages/RequestDetailsPage/RequestStatusPicker/RequestStatusPicker";

// import ShareRequestButton from "../ShareRequestButton/ShareRequestButton";
// import SendToEngineerButton from "../SendToEngineerButton/SendToEngineerButton";

import "./RequestSidebar.scss";

type Props = {
  request: any;
  users: any;
  organisationDetails: any;
  apiUser: any;
  activityItems: any[] | undefined;
  projects: any[];
  clients: any[];
};

export default function RequestSidebar({
  request,
  organisationDetails,
  apiUser,
  activityItems,
  users,
  projects,
  clients,
}: Props) {
  // const urls = getUrlsForRequest({ activityItems });

  let projectDetails = projects.find((x) => x.id === request.projectId);
  let clientDetails = clients.find((x) => x.id === request.clientId);

  async function onAssigneeChange(assignedTo: any) {
    const newAssigneeDetails = users.find((x) => x.id === assignedTo);

    await callGraphQLSimple({
      mutation: "updateRequest",
      message: `Failed to update assignee`,
      variables: {
        input: {
          id: request.id,
          assignedTo: assignedTo || null,
        },
      },
    });

    await sendRequestAssignedNotification({
      users,
      request,
      apiUser,
      newAssigneeDetails,
    });

    await callGraphQLSimple({
      mutation: "createActivityItem",
      message: "Failed to record activity item",
      variables: {
        input: {
          parentId: request.id,
          author: apiUser.id,
          content: JSON.stringify({
            type: "ASSIGNEE_CHANGED",
            newAssigneeId: newAssigneeDetails?.id,
          }),
          organisation: request.organisation,
        },
      },
    });
  }

  return (
    <Card className="request-sidebar">
      <div>
        <InfoItem
          label={`${getSimpleLabel("Request")} ID`}
          value={<Tag className="dark-tag">{processIdForDisplay(request.id)}</Tag>}
        />
        <InfoItem label="Title" value={request.title} />
        {request.projectId && projectDetails && (
          <InfoItem
            label={getSimpleLabel("Project")}
            value={
              isAuthorised(["PROJECT_DETAILS.VIEW"]) ? (
                <Link to={`/projects/${request.projectId}`}>{projectDetails.title}</Link>
              ) : (
                projectDetails.title
              )
            }
          />
        )}
        {request.clientId && (
          <InfoItem
            label={getSimpleLabel("Client")}
            value={<ClientLogo client={clientDetails} includeLink={isAuthorised(["CLIENT_DETAILS.VIEW"])} />}
          />
        )}
        <InfoItem
          label="Requested by"
          value={<Avatar showLabel user={users.find((user) => user.id === request.requestedBy)} />}
        />
        <InfoItem
          label="Requested at"
          value={
            <>
              {moment(request.requestedAt).format("DD-MM-YYYY HH:mm:ss")} <br />({moment(request.requestedAt).fromNow()}
              )
            </>
          }
        />
        <InfoItem
          label={getSimpleLabel("request-desired-completion-date")}
          value={
            <>
              {moment(request.requestedForDate).format("DD-MM-YYYY")} <br />(
              {moment(new Date(request.requestedForDate).setHours(0, 0, 0, 0)).from(new Date().setHours(0, 0, 0, 0))})
            </>
          }
        />
        <RequestStatusPicker request={request} apiUser={apiUser} users={users} activityItems={activityItems} />
        <InfoItem
          label="Assigned to engineer"
          value={
            <UsersFilter
              className="assigned-to-picker"
              activateOnHover={true}
              value={request.assignedTo}
              includeUnassigned
              onChange={onAssigneeChange}
              // excludeList={this.getExcludedAssigneeList()}
              suffixIcon={null}
              maxLabelLength={22}
              data-cy="assigned-to-picker"
              placeholder="Unassigned"
              disabled={!isAuthorised(["REQUESTS.EXECUTE"])}
            />
          }
        />
      </div>
      <div>
        {isAuthorised(["REQUESTS.EXECUTE"]) && (
          <DeleteRequestButton
            request={request}
            activityItems={activityItems}
            organisationDetails={organisationDetails}
            apiUser={apiUser}
          />
        )}
      </div>
    </Card>
  );
}
