import { useCallback, useEffect, useState, memo } from "react";
import { Button, Tooltip, Popover, Select } from "antd";
import isHotkey from "is-hotkey";
import cx from "classnames";
import { Editable, withReact, Slate, useSelected, ReactEditor } from "slate-react";
import { Editor, Transforms, createEditor, Element as SlateElement } from "slate";
import { getPastedFiles } from "ReportPage/Report/reportHelpers";
import { readAndCompressImage } from "browser-image-resizer";
import { useGetSetState } from "react-use";
import { Modal } from "antd";

import moment from "moment";
import _ from "lodash";

import {
  LoadingOutlined,
  DeleteOutlined,
  EyeOutlined,
  BoldOutlined,
  // ItalicOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  CaretDownOutlined,
  // ClearOutlined,
} from "@ant-design/icons";
import { getElementFontSize, getElementColor } from "common/documentRenderHelpers";
import {
  THRESHOLD_FOR_FULL_SIZE_VERSION,
  ATTACHMENTS_MAX_IMAGE_WIDTH,
  ATTACHMENTS_MAX_IMAGE_HEIGHT,
} from "common/constants";
import { getAttachmentTypeFromKey } from "common/shared";
import getS3File from "common/getS3File";
import { checkForLargeAttachments } from "ReportPage/Report/reportHelpers";

import { PaperclipIcon, PageBreakIcon /*, SubscriptIcon, SuperscriptIcon*/ } from "common/icons";
import InsertAttachmentModal from "Modals/InsertAttachmentModal/InsertAttachmentModal";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";
import FilePreview from "FilePreview/FilePreview";

import "./Textarea.scss";

// import { Button, Icon, Toolbar } from '../components'

const HOTKEYS = {
  "mod+b": { format: "bold" },
  "mod+i": { format: "italic" },
  "mod+u": { format: "underline" },
  "mod+`": { format: "code" },
  "mod+s": { format: "subscript" },
  "mod+p": { format: "superscript" },
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const VOID_NODE_TYPES = ["page-break", "attachment"];

const EMPTY_VALUE = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export function Textarea({
  onChange = undefined,
  debouncedOnChange = undefined,
  placeholder = undefined,
  renderPlaceholder = null,
  defaultValue = undefined,
  extraToolbarButtons = [],
  documentProps = {},
  disabled = false,
  basicFormattingOnly = false,
}) {
  const organisationDetails = window.organisationDetails;
  const numberVariables = organisationDetails.variables?.items?.filter((x) => x.type === "NUMBER");
  const colorVariables = organisationDetails.variables?.items?.filter((x) => x.type === "COLOR");

  let initialValue = defaultValue;

  if (!initialValue || (Array.isArray(initialValue) && initialValue.length === 0)) {
    initialValue = EMPTY_VALUE;
  }

  const [getState, setState] = useGetSetState({
    selectedElement: undefined,
  });

  let defaultDebouncedOnChange = () => {};
  let actualDebouncedOnChange = useCallback(_.debounce(debouncedOnChange || defaultDebouncedOnChange, 500), []);

  const [isFocused, setIsFocused] = useState(true);
  const { fieldName, projectFolder } = documentProps;
  const { apiUser, task, quote, invoice, request } = documentProps;
  const [currentEditorValue, setCurrentEditorValue] = useState(initialValue);

  const [publicImageUrls, setPublicImageUrls] = useState({});
  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        disabled={disabled}
        onClick={onElementClick}
        editor={editor}
        publicImageUrls={publicImageUrls}
        numberVariables={numberVariables}
        colorVariables={colorVariables}
      />
    ),
    []
  );
  const renderLeaf = useCallback(
    (props) => <Leaf {...props} numberVariables={numberVariables} colorVariables={colorVariables} />,
    [] // eslint-disable-line
  );
  const [editor] = useState(() => withReact(createEditor()));
  // const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(false);

  const { isVoid } = editor;

  useEffect(() => {
    extractImages(currentEditorValue);
  }, [currentEditorValue]);

  editor.isVoid = (element) => {
    return VOID_NODE_TYPES.includes(element.type) ? true : isVoid(element);
  };

  const extraPossibleToolbarBlockElementButtons = [
    {
      name: "page-break",
      icon: <PageBreakIcon />,
      tooltip: "Insert page break",
    },
    {
      name: "attachment",
      icon: <PaperclipIcon />,
      tooltip: "Insert attachment",
    },
  ];

  function onElementClick(e, element) {
    setState({ selectedElement: element });
    e.stopPropagation();
  }

  function onKeyDown(event) {
    const { selectedElement } = getState();
    event.stopPropagation();

    if (event.key === "Delete" || event.key === "Backspace") {
      if (selectedElement) {
        const path = ReactEditor.findPath(editor, selectedElement);

        Transforms.removeNodes(editor, { at: path });

        setState({ selectedElement: undefined });
        return;
      }
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        toggleMark({
          editor,
          format: HOTKEYS[hotkey].format,
          formatPrefix: HOTKEYS[hotkey].formatPrefix || HOTKEYS[hotkey].format,
        });
      }
    }
  }

  async function extractImages(editorValue) {
    if (!editorValue || !Array.isArray(editorValue)) {
      return;
    }

    let imageNodes = editorValue.filter((node) => node.type === "attachment" && node.attachment.type === "IMAGE");
    const publicUrlsArr = await Promise.all(imageNodes.map((imageNode) => getS3File(imageNode.attachment.key)));
    const publicUrlsDict = {};
    publicUrlsArr.forEach((publicUrl, i) => {
      publicUrlsDict[imageNodes[i].attachment.key] = publicUrl;
    });
    setPublicImageUrls(publicUrlsDict);
  }

  async function onPaste(e) {
    const { apiUser, task, quote, invoice, request, projectFolder } = documentProps;

    const files = getPastedFiles(e.clipboardData);

    if (!files || files.length === 0) {
      return;
    }

    const loadingModal = Modal.info({
      icon: <LoadingOutlined />,
      footer: null,
      closable: false,
      className: "modal-no-buttons",
      title: <>Uploading files...</>,
    });

    e.preventDefault();

    let keys = [];
    let uploadPromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      let extraSuffix = "";
      if (files.length > 1) {
        extraSuffix = `_${i + 1}`;
      }

      let fileName;

      if (file.name && file.name !== "image.png" && file.name !== "image.jpg") {
        fileName = file.name;
      } else {
        fileName = `${moment().format("DD-MM-YYYY_HH-mm-ss")}${extraSuffix}_${apiUser?.firstName || ""}_${
          apiUser?.lastName || ""
        }.${file.type.split("/")[1]}`;
      }

      const parentId = `${task?.id || quote?.id || invoice?.id || request?.id}`;
      const localKey = `${parentId}/pasted/${fileName}`;
      const key = `${projectFolder}/attachments/${localKey}`.replace("public/", "");
      const keyFullSize = `${projectFolder}/attachments/${parentId}/pasted/full_size_versions/${fileName}`.replace(
        "public/",
        ""
      );

      keys.push(localKey);

      if (file.type?.includes("image") && file.size >= THRESHOLD_FOR_FULL_SIZE_VERSION) {
        let currentSize = file.size;
        let resizeFactorComparedToMax = 1;
        let resizedFile = await readAndCompressImage(file, {
          quality: 0.75,
          maxWidth: ATTACHMENTS_MAX_IMAGE_WIDTH / resizeFactorComparedToMax,
          maxHeight: ATTACHMENTS_MAX_IMAGE_HEIGHT / resizeFactorComparedToMax,
          mimeType: file.type,
        });
        while (currentSize > THRESHOLD_FOR_FULL_SIZE_VERSION) {
          resizedFile = await readAndCompressImage(file, {
            quality: 0.75,
            maxWidth: ATTACHMENTS_MAX_IMAGE_WIDTH / resizeFactorComparedToMax,
            maxHeight: ATTACHMENTS_MAX_IMAGE_HEIGHT / resizeFactorComparedToMax,
            mimeType: file.type,
          });
          currentSize = resizedFile.size;
          resizeFactorComparedToMax += 0.1;
        }

        uploadPromises.push(
          Storage.put(keyFullSize, file, {
            contentType: file.type,
          })
        );

        uploadPromises.push(
          Storage.put(key, resizedFile, {
            contentType: file.type,
          })
        );
      } else {
        uploadPromises.push(
          Storage.put(key, file, {
            contentType: file.type,
          })
        );
      }
    }

    if (uploadPromises.length > 0) {
      await Promise.all(uploadPromises);
      onAttachmentSubmit(keys.map((key) => `${projectFolder}/attachments/${key}`));
    }

    loadingModal.destroy();
  }

  function displayAttachmentModal() {
    let project = quote?.project || invoice?.project;

    if (!isAttachmentModalVisible) {
      return null;
    }

    let allowedFileTypes = ["IMAGE", "PDF"];
    if (request) {
      allowedFileTypes = ["IMAGE"];
    }

    return (
      <InsertAttachmentModal
        task={task}
        project={project}
        request={request}
        apiUser={apiUser}
        onSubmit={(attachments, dates, sizes) => onAttachmentSubmit(attachments, dates, sizes)}
        onClose={() => setIsAttachmentModalVisible(false)}
        allowedFileTypes={allowedFileTypes}
      />
    );
  }

  async function onAttachmentSubmit(attachments, dates, sizes) {
    attachments = await checkForLargeAttachments(attachments, sizes);

    for (let i = 0; i < attachments.length; i++) {
      const key = attachments[i];
      const type = getAttachmentTypeFromKey(key);
      const attachmentsFolder = `${projectFolder}/attachments/`;

      const localKey = key.split(attachmentsFolder).join("");
      // let localKey = undefined;
      insertAttachment({
        attachment: {
          key,
          type,
          localKey,
        },
        id: `${Date.now()}-${Math.floor(Math.random() * 1000000)}`,
        align: type === "IMAGE" ? "center" : undefined,
        width: type === "IMAGE" ? "50%" : undefined,
      });
      insertParagraph();
    }
  }

  function BlockButton({ format, icon, tooltip, id }) {
    let isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
    return (
      <Tooltip title={tooltip} trigger={tooltip ? "hover" : []}>
        <Button
          type="clear"
          className={cx(isActive ? "active" : "inactive")}
          onMouseDown={(event) => {
            event.preventDefault();
            toggleBlock(editor, format);
          }}
          icon={icon}
        ></Button>
      </Tooltip>
    );
  }

  function BlockElementButton({ name, icon, tooltip }) {
    let content = (
      <Button
        type="clear"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (name) {
            insertBlockElement(name);
          }
        }}
        icon={icon}
      ></Button>
    );
    if (tooltip) {
      return <Tooltip title={tooltip}>{content}</Tooltip>;
    } else {
      return content;
    }
  }

  function FontSizePicker() {
    const marks = Editor.marks(editor);
    let activeFontSize;
    let fontSizePrefix = "font_size_";

    for (let mark in marks) {
      if (mark.startsWith(fontSizePrefix)) {
        activeFontSize = mark.replace(fontSizePrefix, "");
      }
    }

    let buttonLabel = "Regular text";
    if (activeFontSize) {
      let targetVariable = numberVariables?.find((variable) => variable.id === activeFontSize);
      if (!targetVariable) {
        buttonLabel = "???";
      } else {
        buttonLabel = `${targetVariable.name[0].toUpperCase()}${targetVariable.name.substring(1)}`;
      }
    }

    return (
      <Popover
        trigger="hover"
        content={
          <div className="textarea-font-size-popover">
            {numberVariables?.map((variable) => (
              <Button
                key={variable.id}
                type="clear"
                onClick={() => {
                  toggleMark({
                    editor,
                    formatPrefix: fontSizePrefix,
                    format: `${fontSizePrefix}${variable.id}`,
                  });
                }}
              >
                {variable.name[0].toUpperCase()}
                {variable.name.substring(1)}
              </Button>
            ))}
          </div>
        }
      >
        <Button className="font-size-button">
          {buttonLabel} <CaretDownOutlined />
        </Button>
      </Popover>
    );
  }

  function ColorPicker() {
    const marks = Editor.marks(editor);
    let activeOption;
    let prefix = "color_";

    for (let mark in marks) {
      if (mark.startsWith(prefix)) {
        activeOption = mark.replace(prefix, "");
      }
    }

    let buttonLabel = "Black";
    let buttonColor = "#000000";
    if (activeOption) {
      let targetVariable = colorVariables?.find((variable) => variable.id === activeOption);
      if (!targetVariable) {
        buttonLabel = "???";
      } else {
        buttonLabel = `${targetVariable.name[0].toUpperCase()}${targetVariable.name.substring(1)}`;
        buttonColor = targetVariable.value;
      }
    }

    return (
      <Popover
        trigger="hover"
        content={
          <div className="textarea-color-popover">
            {colorVariables?.map((variable) => (
              <Button
                key={variable.id}
                type="clear"
                onClick={() => {
                  toggleMark({
                    editor,
                    formatPrefix: prefix,
                    format: `${prefix}${variable.id}`,
                  });
                }}
              >
                <div className="color-sample" style={{ backgroundColor: variable.value }} />
                <span className="color-name" style={{ color: variable.value }}>
                  {variable.name[0].toUpperCase()}
                  {variable.name.substring(1)}
                </span>
              </Button>
            ))}
          </div>
        }
      >
        <Button className="color-button">
          <div className="color-sample" style={{ backgroundColor: buttonColor }} />
          {buttonLabel} <CaretDownOutlined />
        </Button>
      </Popover>
    );
  }

  function MarkButton({ format, formatSuffix, formatPrefix = "", icon, tooltip, label }) {
    if (!format) {
      format = `${formatPrefix}${formatSuffix}`;
    }

    if (!formatPrefix) {
      formatPrefix = format;
    }

    let isActive = isMarkActive(editor, format);
    let content = (
      <Button
        type="clear"
        // active={isMarkActive(editor, format)}
        className={cx(isActive ? "active" : "inactive")}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark({ editor, formatPrefix, format });
        }}
        icon={icon}
      >
        {label}
      </Button>
    );
    if (tooltip) {
      return (
        <Tooltip title={tooltip} trigger={tooltip ? "hover" : []}>
          {content}
        </Tooltip>
      );
    } else {
      return content;
    }
  }

  function toggleBlock(editor, format) {
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (node) =>
        !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        LIST_TYPES.includes(node.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  }

  function toggleMark({ editor, format, formatPrefix }) {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      const marks = Editor.marks(editor);
      for (let mark in marks) {
        if (mark.startsWith(formatPrefix)) {
          Editor.removeMark(editor, mark);
        }
      }
      Editor.addMark(editor, format, true);
    }
  }

  function isBlockActive(editor, format, blockType = "type") {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
      })
    );

    return !!match;
  }

  function isMarkActive(editor, format) {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  }

  function insertBlockElement(elementName) {
    switch (elementName) {
      case "page-break":
        insertPageBreak();
        insertParagraph();
        break;
      case "attachment":
        setIsAttachmentModalVisible(true);
        break;

      default:
        break;
    }
  }

  function insertPageBreak() {
    const text = { text: "" };
    const node = { type: "page-break", children: [text] };
    Transforms.insertNodes(editor, node);
  }

  function insertParagraph() {
    const text = { text: "" };
    const node = { type: "paragraph", children: [text] };
    Transforms.insertNodes(editor, node);
  }

  function insertAttachment({ attachment, ...otherParams }) {
    const text = { text: "" };
    const node = {
      type: "attachment",
      attachment,
      ...otherParams,
      children: [text],
    };
    Transforms.insertNodes(editor, node);
  }

  const extraToolbarButtonsToDisplay = extraPossibleToolbarBlockElementButtons.filter((x) =>
    extraToolbarButtons?.includes(x.name)
  );

  return (
    <ErrorBoundary>
      <Slate
        editor={editor}
        value={initialValue}
        onChange={(newValue) => {
          setCurrentEditorValue(newValue);
          if (
            (onChange && typeof onChange === "function") ||
            (debouncedOnChange && typeof debouncedOnChange === "function")
          ) {
            const newValueString = JSON.stringify(newValue || {}, null, 2);
            const oldValueString = JSON.stringify(defaultValue || {}, null, 2);

            if (newValueString !== oldValueString) {
              if (debouncedOnChange) {
                actualDebouncedOnChange(newValueString);
              } else if (onChange) {
                onChange(newValueString);
              }
              // console.log("oldValueString = ", oldValueString);
              // console.log("newValueString = ", newValueString);
            }
          }
        }}
      >
        <div className={cx("textarea", isFocused ? "textarea-has-focus" : "textarea-no-focus")}>
          {!disabled && (
            <div className="textarea-toolbar">
              <div className="textarea-toolbar-inner">
                {!basicFormattingOnly && (
                  <div className="tool-row">
                    <div className="tool-group">
                      <FontSizePicker />
                    </div>
                    <div className="tool-group">
                      <ColorPicker />
                    </div>
                  </div>
                )}

                <div className="tool-row">
                  <div className="tool-group">
                    <MarkButton format="bold" icon={<BoldOutlined />} tooltip="Bold" />
                    {/* <MarkButton format="italic" icon={<ItalicOutlined />} tooltip="Italic" /> */}

                    <MarkButton format="underline" icon={<UnderlineOutlined />} tooltip="Underline" />
                  </div>
                  <div className="tool-group">
                    <BlockButton format="numbered-list" icon={<OrderedListOutlined />} tooltip="Numbered list" />
                    <BlockButton format="bulleted-list" icon={<UnorderedListOutlined />} tooltip="Bullet list" />
                  </div>
                  <div className="tool-group">
                    <BlockButton format="left" icon={<AlignLeftOutlined />} tooltip="Align left" />
                    <BlockButton format="center" icon={<AlignCenterOutlined />} tooltip="Align center" />
                    <BlockButton format="right" icon={<AlignRightOutlined />} tooltip="Align right" />
                  </div>
                  {/* <div className="tool-group">
                    <MarkButton format="superscript" icon={<SuperscriptIcon />} tooltip="Superscript" />
                    <MarkButton format="subscript" icon={<SubscriptIcon />} tooltip="Subscript" />
                  </div> */}
                </div>

                {extraToolbarButtonsToDisplay.length > 0 && (
                  <div className="tool-row">
                    <div className="tool-group">
                      {extraToolbarButtonsToDisplay.map(({ name, icon, tooltip }) => {
                        return <BlockElementButton key={name} name={name} icon={icon} tooltip={tooltip} />;
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <Editable
            className="textarea-content"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            renderPlaceholder={renderPlaceholder}
            spellCheck
            autoFocus
            readOnly={disabled}
            onPaste={onPaste}
            onKeyDown={onKeyDown}
            onClick={() => {
              setState({ selectedElement: undefined });
            }}
          />
        </div>
      </Slate>
      {displayAttachmentModal()}
    </ErrorBoundary>
  );
}

function getElementStyle({ element }) {
  const style = {
    textAlign: element.align,
  };

  return style;
}

function Element({ editor, attributes, children, element, publicImageUrls, numberVariables, onClick, disabled }) {
  onClick = onClick || (() => {});
  const style = getElementStyle({ element });
  const selected = useSelected();

  function displayDeleteButton(element) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.removeNodes(editor, { at: path });
          }}
        >
          Delete
        </Button>
      </div>
    );
  }

  function PreviewButton({ element }) {
    const [attachmentForPreview, setAttachmentForPreview] = useState(null);
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            console.log("element = ", element);
            setAttachmentForPreview(element.attachment);
          }}
        >
          Preview
        </Button>
        {attachmentForPreview && (
          <FilePreview
            attachments={[attachmentForPreview]}
            initiallySelectedAttachment={attachmentForPreview}
            onClose={() => setAttachmentForPreview(null)}
            organisationId={window.organisationDetails.id}
            allowedTypes={["IMAGE", "PDF"]}
          />
        )}
      </div>
    );
  }

  switch (element.type) {
    case "paragraph":
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "page-break":
      return (
        <Popover
          trigger={disabled ? [] : "click"}
          content={<div className="textarea-attachment-popover">{displayDeleteButton(element)}</div>}
        >
          <div
            {...attributes}
            className={cx("node-page-break", { selected })}
            contentEditable={false}
            onClick={(e) => onClick(e, element)}
          >
            <span className="label">Page break</span>
            {children}
          </div>
        </Popover>
      );
    case "attachment":
      let content = null;
      switch (element.attachment.type) {
        case "IMAGE":
          content = (
            <Popover
              trigger="click"
              content={
                <div className="textarea-attachment-popover">
                  <InfoItem
                    label="Width"
                    inline
                    value={
                      <Input
                        defaultValue={element.width}
                        fireOnChangeWithoutBlur
                        disabled={disabled}
                        onChange={(value) => {
                          Transforms.setNodes(
                            editor,
                            { width: value },
                            {
                              // This path references the editor, and is expanded to a range that
                              // will encompass all the content of the editor.
                              at: [],
                              match: (node, path) => {
                                return node.type === "attachment" && node.id === element.id;
                              },
                            }
                          );
                        }}
                        showBorder
                      />
                    }
                  />
                  <InfoItem
                    label="Align"
                    inline
                    value={
                      <Select
                        defaultValue={element.align || "left"}
                        disabled={disabled}
                        onChange={(value) => {
                          Transforms.setNodes(
                            editor,
                            { align: value },
                            {
                              // This path references the editor, and is expanded to a range that
                              // will encompass all the content of the editor.
                              at: [],
                              match: (node, path) => {
                                return node.type === "attachment" && node.id === element.id;
                              },
                            }
                          );
                        }}
                      >
                        <Select.Option value="left">Left</Select.Option>
                        <Select.Option value="center">Center</Select.Option>
                        <Select.Option value="right">Right</Select.Option>
                      </Select>
                    }
                  />
                  <InfoItem
                    label="Caption"
                    inline
                    value={
                      <Input
                        defaultValue={element.caption}
                        fireOnChangeWithoutBlur
                        disabled={disabled}
                        onChange={(value) => {
                          Transforms.setNodes(
                            editor,
                            { caption: value },
                            {
                              // This path references the editor, and is expanded to a range that
                              // will encompass all the content of the editor.
                              at: [],
                              match: (node, path) => {
                                return node.type === "attachment" && node.id === element.id;
                              },
                            }
                          );
                        }}
                        showBorder
                      />
                    }
                  />
                  <InfoItem
                    label="Caption font size"
                    inline
                    value={
                      <Select
                        defaultValue={element.captionFontSize || "Regular text"}
                        disabled={disabled}
                        onChange={(value) => {
                          Transforms.setNodes(
                            editor,
                            { captionFontSize: value },
                            {
                              // This path references the editor, and is expanded to a range that
                              // will encompass all the content of the editor.
                              at: [],
                              match: (node, path) => {
                                return node.type === "attachment" && node.id === element.id;
                              },
                            }
                          );
                        }}
                      >
                        {numberVariables?.map((variable) => (
                          <Select.Option key={variable.id} value={variable.id}>
                            {variable.name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                  {!disabled && displayDeleteButton(element)}
                  <PreviewButton element={element} />
                </div>
              }
            >
              <span className="label">
                <FileImageOutlined /> Image: {element.attachment.localKey || element.attachment.key.split("/").pop()}
              </span>
            </Popover>
          );

          break;
        case "PDF":
          content = (
            <Popover
              trigger={disabled ? [] : "click"}
              content={
                <div className="textarea-attachment-popover">
                  {displayDeleteButton(element)}
                  <PreviewButton element={element} />
                </div>
              }
            >
              <span className="label">
                <FilePdfOutlined /> PDF: {element.attachment.localKey || element.attachment.key.split("/").pop()}
              </span>
            </Popover>
          );

          break;
        default:
          content = "Unknown attachment type";
          break;
      }
      return (
        <div
          {...attributes}
          className={cx("node-attachment", { selected })}
          contentEditable={false}
          onClick={(e) => onClick(e, element)}
        >
          {content}
          {children}
        </div>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

function Leaf({ attributes, numberVariables, colorVariables, children, leaf }) {
  let style = {
    ...(attributes?.style || {}),
  };
  const fontSize = getElementFontSize({ element: leaf, numberVariables });
  const color = getElementColor({ element: leaf, colorVariables });
  if (fontSize !== undefined) {
    style.fontSize = `${fontSize}px`;
  }

  if (color !== undefined) {
    style.color = color;
  }

  if (leaf.bold) {
    children = <strong style={style}>{children}</strong>;
  }

  if (leaf.code) {
    children = <code style={style}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em style={style}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u style={style}>{children}</u>;
  }

  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }

  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
}

export default memo(Textarea, (prevProps, nextProps) => {
  if (prevProps.memoize || nextProps.memoize) {
    const PROPS_TO_MONITOR = ["disabled"];
    for (let propName of PROPS_TO_MONITOR) {
      if (prevProps[propName] !== nextProps[propName]) {
        return false;
      }
    }
    return true;
  }
  return false;
});
