import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Typography } from "antd";
import RichTextEditor from "react-rte";
import _ from "lodash";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { useStateRef } from "common/helpers";

import Card from "Card/Card";
import Input from "Input/Input";

import "./ProjectSummary.scss";

export function ProjectSummary({ organisationDetails, project }) {
  const debouncedChangeDescription = useRef(_.debounce(() => changeDescription(), 500)).current;

  const [description, setDescription, descriptionRef] = useStateRef(
    RichTextEditor.createValueFromString(project.description || "", "html")
  );
  const [isDescriptionOpen, setIsDescriptionOpen] = useStateRef(false);

  useEffect(() => {
    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []); // eslint-disable-line

  function onWindowClick() {
    setIsDescriptionOpen(false);
    changeDescription();
  }

  async function changeTitle(title) {
    await callGraphQLSimple({
      message: "Failed to update title",
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          title,
        },
      },
    });
  }

  async function changeDescription() {
    let newDescription = descriptionRef.current.toString("html");
    if (project.description === newDescription || (project.description === null && newDescription === "<p><br></p>")) {
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update description",
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          description: newDescription,
        },
      },
    });
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "bold" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  function setEditorFocus() {
    let editorElement = document.querySelector(".public-DraftEditor-content");
    if (!editorElement || !editorElement.isContentEditable) {
      setTimeout(setEditorFocus, 100);
      return;
    }

    editorElement.focus();
  }

  async function changeProjectInitials(initials) {
    await callGraphQLSimple({
      message: "Failed to update initials",
      queryCustom: "updateProject",
      variables: {
        input: {
          id: project.id,
          initials,
        },
      },
    });
  }

  return (
    <>
      <Card
        data-cy="project-details-summary"
        className={cx("project-summary")}
        title={
          <>
            <div className="title-and-initials">
              {organisationDetails.usesProjectInitials && (
                <Input
                  className="project-initials"
                  defaultValue={project.initials}
                  allowEnter={false}
                  onChange={changeProjectInitials}
                  showBorder={!project.initials}
                  placeholder={project.initials ? "" : "Initials"}
                />
              )}
              <Input
                data-cy="project-details-title"
                defaultValue={project.title}
                className="project-title"
                onChange={changeTitle}
                fullWidth
                disabled={project.isArchived || project.isFinished}
                multiLine
                allowEnter={false}
              />
            </div>

            <Typography.Paragraph className="project-description-label">Description:</Typography.Paragraph>

            <div
              onClick={(e) => {
                setIsDescriptionOpen(true);
                e.stopPropagation();
                setEditorFocus();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
            >
              <RichTextEditor
                value={description}
                autoFocus={true}
                onChange={(value) => {
                  setDescription(value);
                  debouncedChangeDescription();
                }}
                className="project-description"
                readOnly={project.isArchived || !isDescriptionOpen}
                toolbarConfig={toolbarConfig}
              />
            </div>
          </>
        }
      />
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSummary,
    subscriptions: ["organisationDetails"],
  })
);
