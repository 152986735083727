import moment from "moment";

import { FILE_TYPES_DETAILS } from "common/shared";
import { listHolidays } from "../organisation";
import { isUserAvailableInInterval } from "common/shared";
import { getCustomSheetReferenceNumber } from "common/naming";

function getFieldValue(requestForm, fieldId) {
  if (!requestForm?.fields) {
    return;
  }

  let targetField = requestForm.fields[fieldId];
  if (!targetField) {
    return;
  }

  return targetField.value;
}

function getInternalDesignerDetails({ form, users }) {
  let twDesignCompanyField = form.fields["twdesigncompany-1723123910757"];
  if (!twDesignCompanyField) {
    return;
  }

  let twDesignCompany = twDesignCompanyField.value;
  if (twDesignCompany && twDesignCompany !== "Byrne Bros Formwork Ltd") {
    return;
  }

  let twDesignContactField = form.fields["byrnebrostwdesigncontact-1724332403072"];

  if (!twDesignContactField) {
    return;
  }

  let twDesignContactId = twDesignContactField.value;
  if (!twDesignContactId) {
    return;
  }

  let twDesignContact = users.find((user) => user.id === twDesignContactId);

  if (!twDesignContact) {
    return;
  }

  return twDesignContact;
}

function getFirstPrefixForReference({ task }) {
  if (!task.customFields) {
    return "";
  }

  let byrneGroupCompanyCustomField = task.customFields.find(
    (customField) => customField.id === "COMPANY_1721992782178"
  );
  if (!byrneGroupCompanyCustomField) {
    return "";
  }

  let byrneGroupCompany = byrneGroupCompanyCustomField.value;

  if (byrneGroupCompany === "ELLMER_CONSTRUCTION") {
    return "ELL";
  }

  return "BBF";
}

function getSecondPrefixForReference({ task }) {
  if (!task.customFields) {
    return "";
  }

  let byrneGroupCompanyCustomField = task.customFields.find(
    (customField) => customField.id === "COMPANY_1721992782178"
  );
  if (!byrneGroupCompanyCustomField) {
    return "";
  }

  let byrneGroupCompany = byrneGroupCompanyCustomField.value;

  if (byrneGroupCompany === "ELLMER_CONSTRUCTION") {
    return "TELL";
  }

  return "TBB";
}

function getReferenceAndLabelForSheet({ file, sheet, task, requestForm }) {
  let customReferenceNumber = getCustomSheetReferenceNumber({ sheet, file });

  if (customReferenceNumber) {
    return {
      reference: customReferenceNumber,
      label: customReferenceNumber,
    };
  }

  let projectNumber = task.projectId.split("-")[1];
  let designBriefNumber = getFieldValue(requestForm, "twschemeno3digit-1723123790668");

  let fileNumberFromName;

  let number = file.name.match(/\d+$/);
  if (number) {
    fileNumberFromName = number[0];
  } else {
    fileNumberFromName = "";
  }

  let firstPrefix = getFirstPrefixForReference({ task });
  let secondPrefix = getSecondPrefixForReference({ task });
  let filePrefix = "";
  let label;
  let lowercaseFileName = file.name.toLowerCase();

  if (lowercaseFileName.includes("design risk assessment")) {
    filePrefix = "RSKT";
  } else if (lowercaseFileName.includes("design check certificate")) {
    filePrefix = "DCERT";
  } else if (lowercaseFileName.includes("calculations")) {
    filePrefix = "CALC";
  } else if (file.type === "AUTOCAD") {
    if (lowercaseFileName.includes("byrne")) {
      filePrefix = "DRGT";
      fileNumberFromName = sheet.description.padStart(2, "0");
      label = `AutoCAD - Byrne template - ${fileNumberFromName} (${sheet.name})`;
    }
  }

  let reference = `${firstPrefix}-${projectNumber}-${filePrefix}${secondPrefix}-DB${designBriefNumber}-${fileNumberFromName}`;
  return {
    label,
    reference,
  };
}

const fields = [
  {
    id: "BYRNE_byrne_group_company_from_request_form",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Byrne Group Company from request form",
    repeatForFieldName: "file",
    value: async ({ initialRequestForm }) => {
      let fieldValue = getFieldValue(initialRequestForm, "byrnegroupcompany-1722096529069");
      return `${fieldValue || ""}`;
    },
  },
  {
    id: "BYRNE_tw_scheme_number",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - TW Scheme Number",
    repeatForFieldName: "file",
    value: async ({ initialRequestForm }) => {
      let fieldValue = getFieldValue(initialRequestForm, "twschemeno3digit-1723123790668");
      return `DB ${fieldValue || ""}`;
    },
  },
  {
    id: "BYRNE_design_brief_number",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Design Brief number",
    repeatForFieldName: "file",
    value: async ({ initialRequestForm }) => {
      return getFieldValue(initialRequestForm, "twschemeno3digit-1723123790668");
    },
  },
  {
    id: "BYRNE_section_location",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Section location",
    repeatForFieldName: "file",
    value: async ({ initialRequestForm }) => {
      return getFieldValue(initialRequestForm, "sectionlocation-1723209641866");
    },
  },
  {
    id: "BYRNE_scheme_description",
    label: "BYRNE - Scheme description",
    fieldType: "textarea",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "schemedescription-1723123840348");

      return value;
    },
  },
  {
    id: "BYRNE_tw_design_company",
    label: "BYRNE - TW Design Company",
    fieldType: "textarea",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "twdesigncompany-1723123910757");

      return value;
    },
  },
  {
    id: `sheets_BYRNE_all_printable`,
    fieldTypes: ["repeatFor"],
    label: `BYRNE - Each printable sheet reference in each file in task revision`,
    repeatForFieldName: "sheet",
    value: ({ taskRevision, task, initialRequestForm }) => {
      let items = [];
      if (!taskRevision) {
        return items;
      }

      if (taskRevision?.files?.items) {
        for (let file of taskRevision?.files?.items) {
          if (file.isHidden) {
            continue;
          }
          if (file?.sheets?.items) {
            for (let sheet of file?.sheets?.items) {
              if (sheet.excludeFromRegister) {
                continue;
              }
              let { label, reference } = getReferenceAndLabelForSheet({
                file,
                sheet,
                task,
                requestForm: initialRequestForm,
              });

              items.push({
                ...sheet,
                value: reference,
                file,
                label,
                includeFileTypeInLabel: !["REPORT", "PDF"].includes(file.type),
                includeSheetNameInLabel: FILE_TYPES_DETAILS[file.type].hasSheets,
              });
            }
          }
        }
      }
      return items;
    },
  },
  {
    id: "byrne_check_certificate_reference_for_other_reports",
    label: "BYRNE - Check certificate reference on other reports",
    value: ({ task, taskRevision, initialRequestForm }) => {
      let checkCertificateFile = taskRevision?.files?.items.find((crtFile) =>
        crtFile.name.toLowerCase().includes("design check certificate")
      );

      if (!checkCertificateFile || !checkCertificateFile.sheets?.items[0]) {
        return;
      }

      let { reference } = getReferenceAndLabelForSheet({
        file: checkCertificateFile,
        sheet: checkCertificateFile.sheets.items[0],
        task,
        requestForm: initialRequestForm,
      });

      return reference;
    },
  },
  {
    id: "byrne_sheet_reference",
    label: "BYRNE - Sheet reference",
    value: ({ sheet, file, task, initialRequestForm }) => {
      let { reference } = getReferenceAndLabelForSheet({
        file,
        sheet,
        task,
        requestForm: initialRequestForm,
      });

      return reference;
    },
  },
  {
    id: "BYRNE_available_designers",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Available designers - use within request form",
    repeatForFieldName: "user",

    value: async ({ users, form, request, organisationDetails }) => {
      if (!request) {
        return [];
      }

      if (!request.requestedForDate) {
        return [];
      }

      let ifcDesignRequestedDateField = form.fields["ifcdesignreqdate-1720778797047"];

      if (!ifcDesignRequestedDateField || !ifcDesignRequestedDateField.value) {
        return [];
      }

      let groups = (
        await window.callGraphQLSimple({
          query: "listGroupsByOrganisation",
          variables: {
            organisation: organisationDetails.id,
            limit: 1000,
          },
        })
      ).data.listGroupsByOrganisation.items;

      let ifcDesignRequestedDateMoment = ifcDesignRequestedDateField.value;
      if (typeof ifcDesignRequestedDateMoment === "string") {
        ifcDesignRequestedDateMoment = moment(ifcDesignRequestedDateMoment);
      }

      let holidays = await listHolidays({
        organisation: organisationDetails.id,
        startsAt: moment(request.requestedForDate).subtract(2, "months"),
        endsAt: moment(ifcDesignRequestedDateMoment).add(2, "months"),
      });

      let engineerGroup = groups.find((group) => group.name === "Engineers");
      let engineers = users.filter((user) => engineerGroup.members?.includes(user.id));

      let userOptions = engineers.map((user) => {
        if (user.isDisabled) {
          return {
            name: `${user.firstName} ${user.lastName} (disabled)`,
            value: user.id,
            disabled: true,
          };
        }
        let { reason } = isUserAvailableInInterval({
          userId: user.id,
          holidays,
          startDate: moment(),
          endDate: ifcDesignRequestedDateMoment,
        });

        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}${reason ? ` (${reason})` : ""}`,
        };
      });

      return userOptions;

      // return userOptions.filter((option) => !option.disabled);
    },
  },
  {
    id: "files_byrneCheckCertificateForms",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Design Check Certificate forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      for (let crtTaskRevision of task?.revisions?.items) {
        for (let crtFile of crtTaskRevision?.files?.items) {
          if (crtFile?.name.toLowerCase().startsWith("design check certificate")) {
            return [crtFile];
          }
        }
      }
      return [];
    },
  },
  {
    id: "byrneNameOfTWDesignContact",
    label: "BYRNE - Name of TW Design Contact - use within request form",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      return `${internalDesignerDetails.firstName} ${internalDesignerDetails.lastName}`;
    },
  },
  {
    id: "byrneEmailOfTWDesignContact",
    label: "BYRNE - Email of TW Design Contact - use within request form",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      return `${internalDesignerDetails.id}`;
    },
  },
  {
    id: "byrnePhoneOfTWDesignContact",
    label: "BYRNE - Phone of TW Design Contact - use within request form",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      let phoneNumbers = [internalDesignerDetails.phone1, internalDesignerDetails.phone2].filter(
        (phoneNumber) => phoneNumber
      );

      return phoneNumbers.join(", ");
    },
  },

  {
    id: "byrneNameOfTWDesignContactWithinTask",
    label: "BYRNE - Name of external TW Design Contact - use within task",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "nameoftwdesigncontact-1723124016100");

      return value;
    },
  },
  {
    id: "byrneEmailOfTWDesignContactWithinTask",
    label: "BYRNE - Email of external TW Design Contact - use within task",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "emailoftwdesigncontact-1723124078240");

      return value;
    },
  },
  {
    id: "byrnePhoneOfTWDesignContactWithinTask",
    label: "BYRNE - Phone of external TW Design Contact - use within task",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "telephonenumberoftwdesigncontact-1723124058499");

      return value;
    },
  },
  {
    id: "byrneNameOfOtherTWDesignCompanyWithinTask",
    label: "BYRNE - Name of other TW Design Company - use within task",
    value: ({ initialRequestForm }) => {
      let value = getFieldValue(initialRequestForm, "nameoftwdesigncompany-1723123970700");

      return value;
    },
  },
  {
    id: "byrneProjectTitle",
    label: "BYRNE - Project title - use within request form",
    value: async ({ form }) => {
      let fieldValue = getFieldValue(form, "project-1726402444321");

      if (!fieldValue) {
        return;
      }

      let projectDetails;
      try {
        projectDetails = (
          await global.callGraphQLSimple({
            query: `
              query GetProject($id: ID!) {
                getProject(id: $id) {
                  id
                  title
                }
              }
            `,
            variables: {
              id: fieldValue,
            },
          })
        ).data.getProject;
      } catch (e) {
        return;
      }

      if (!projectDetails) {
        return;
      }

      return projectDetails.title;
    },
  },
];
export function getFields() {
  return fields;
}
